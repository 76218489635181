import ApiClient from "api/apiClient";
import ApiConfig from "api/apiConfig";

class BillingService {
    static plans = [];


    static GetCustomer = async () => {
        const url = ApiConfig.API_URL + '/billing/customer';

        try {
            const response = await ApiClient.sendGet(url, {});
            return response;
        } catch (error) {
            console.error(error);
            return error;
        }
    };


    static GetPlans = async () => {
        const url = ApiConfig.API_URL + '/billing/plans';

        try {
            const response = await ApiClient.sendGet(url, {},);
            return response;
        } catch (error) {
            console.error(error);
            return error;
        }
    };

    static GetSubscription = async () => {
        const url = ApiConfig.API_URL + '/billing/subscription';
        try {
            const response = await ApiClient.sendGet(url, {},);
            return response;
        } catch (error) {
            console.error(error);
            return error;
        }
    }

    static GetInvoices = async () => {
        const url = ApiConfig.API_URL + '/billing/invoices';

        try {
            const response = await ApiClient.sendGet(url, {},);
            return response;
        } catch (error) {
            console.error(error);
            return error;
        }
    }

    static SignUpPlan = async (plan_code: string, email: string) => {
        var url = '';
        if (email == '') {
            url = ApiConfig.API_URL + '/billing/subscription?plan_code=' + plan_code;
        } else {
            url = ApiConfig.API_URL + '/billing/subscription?plan_code=' + plan_code + '&email=' + email;
        }

        try {
            const response = await ApiClient.sendPost(null, url, {},);
            console.log(response)
            return response;
        } catch (error) {
            console.error(error);
            return error;
        }
    }

    static UpdateCustomer = async (
        city: string,
        country: string,
    ) => {
        const url = ApiConfig.API_URL + '/billing/customer?city=' + city + '&country=' + country;

        try {
            const response = await ApiClient.sendGet(url, {},);
            return response;
        } catch (error) {
            console.error(error);
            return error;
        }
    }

    static DelSubscription = async () => {
        const url = ApiConfig.API_URL + '/billing/subscription';

        try {
            const response = await ApiClient.sendDel(url, {}, true,);
            return response;
        } catch (error) {
            console.error(error);
            return error;
        }
    }

}

export default BillingService;
