const ApiConfig = {
  API_URL: process.env.REACT_APP_API_URL || "https://api.reachsnap.com",
  // API_URL: 'https://api-staging.reachsnap.com',
  // API_URL: "http://175.41.172.63:8082",

  REACHSNAP_URL: process.env.REACT_APP_REACHSNAP_URL || "https://api.reachsnap.com",
  MAIN_DOMAIN: process.env.REACT_APP_MAIN_DOMAIN || "castlebird.com",
  STAGING_DOMAIN: process.env.REACT_APP_STAGING_DOMAIN || "castlebird.co",


  STAGING_APP: process.env.REACT_APP_STAGING_APP || "app.castlebird.co",

  MAIN_APP: process.env.REACT_APP_MAIN_APP || "app.castlebird.com",

  BLOG_URL: "/blog",

  USER_OAUTH: "/auth/token_auth",
  USER_INDEX: "/users/",
  USER_FOLLOW: "/user_follows",
  USER_PREVIEW_SIGNUP: "/auth/create_preview_user",

  POST_INDEX: "/posts",
  SERVICE_INDEX: "/services",
  CHAT_ROOM_INDEX: "/chat_rooms",
  MESSAGE_INDEX: "/messages",
  SETTINGS_INDEX: "/settings",
  PAGES_INDEX: "/pages",
  PAGES_CREATE: "/pages",
  SECTIONS_INDEX: "/sections",
  ACCOUNT_INDEX: "/accounts/",
  WEBSITES_INDEX: "/websites/",
  WEBSITES_CREATE: "/websites/",
  WEBSITES_AI: "/websites/get_ai_content",
  WEBSITES_UPDATE: "/websites/",
  NOTIFY_REFERENCE_GET: "/notify_preferences/",
  NOTIFY_REFERENCE_UPDATE: "/notify_preferences/",

  INVITE_CREATE: "/invites",
  PUSH_NOTI_REGISTER: "/pushnoti/register",
  PUSH_NOTI_UNREGISTER: "/pushnoti/unregister",

  GOOGLE_MAP_KEY: "AIzaSyDGVG1W3iZ5Z3Vwzsh2J2YtufIoMQIPBJE",
  IPINFO_TOKEN: "d478d8816d78d4",
  IPLOCATION_KEY: "05f7b1de6fe045e8add4f1eaa6d8fd5e",
};

export default ApiConfig;
