import { JSX, useEffect, useRef, useState } from 'react';
import './style.scss';
import BillingService from 'api/services/BillingService';
import LoadingSuspense from 'components/Suspense';
import CB_Icon from "assets/logo.png"
import ArrowDown from "assets/icons/CaretDown.png"
import AvatarTemp from "assets/PublicProfile/hero/avatar.png"
import ArrowLeft from "assets/icons/arrow-left.png"
import Medal from "assets/icons/medal-star.png"
import CheckBlue from "assets/icons/tick-circle-blue.png"
import { useLocation, useNavigate } from 'react-router-dom';
import UtilityHelper from 'helpers/UtilityHelper';
import NavBarBilling from './components/navbar/navbar';
import ColorTemple from 'styles/ColorTemple';
import AuthService from 'api/services/authService';
import SafeIcon from "assets/icons/shield.png"
import RefundLogo from "assets/icons/money-back-guarantee.png"
import { Elements, PaymentElement, useStripe, useElements } from '@stripe/react-stripe-js';
import { loadStripe, } from '@stripe/stripe-js';
import React, { forwardRef, useImperativeHandle } from 'react';
import LogoBrand from './components/Logo_Brand';
import AddIcon from "assets/icons/add.png"
import DateTimeHelper from 'helpers/DateTimeHelper';
import PaymentHelper from 'helpers/PaymentHelper';
import CustomerService from 'api/services/CustomerService';
import ApiConfig from 'api/apiConfig';

const stripePromise = loadStripe('pk_test_qgwHJ9FIdwVHzkXxkhUwhHve');


interface Plans {
    code: string;
    name: string;
    interval: string;
    description: string;
    amount_cents: number;
    amount_currency: string;
    trial_period: number;
}
interface Subscription {
    plan: {
        name: string;
        amount_cents: number;
    };
    ending_at: string;
}

interface PaymentMethod {
    id: string; // The ID of the existing payment method
    card: {
        brand: string;
        last4: string;
        exp_year: number;
        exp_month: number;
    };
}

type AddEditFormProps = {
    upgrade_plan_code: string;
    ref: any;

};

const AddEditForm: React.FC<AddEditFormProps> = forwardRef((upgrade_plan_code, ref) => {
    const stripe = useStripe();
    const elements = useElements();

    // Function này sẽ được gọi từ bên ngoài
    const handleSubmit = async (event: React.FormEvent) => {
        // console.log(upgrade_plan_code.upgrade_plan_code)

        if (stripe && elements) {
            const result = await stripe.confirmSetup({
                elements,
                confirmParams: {
                    return_url: window.location.origin + `/stripe/intermediate?upgrade_plan=${upgrade_plan_code.upgrade_plan_code}&login=${true}`,
                }
            });
            if (result.error) {
                console.error('Error:', result.error.message);

                // Kiểm tra lỗi "Postal code check fails"
                if (result.error.code === 'postal_code_check_fail') {
                    console.error('Postal code verification failed.');
                }
            } else {
                console.log('Setup successful:', result.error);
            }
        }
    };


    // Dùng useImperativeHandle để expose function ra bên ngoài
    useImperativeHandle(ref, () => ({
        handleSubmit
    }));

    return (
        <PaymentElement />
    );
});


function PlansPage() {

    const [plans, setPlans] = useState<Plans[]>([]);
    const navigate = useNavigate();
    const [loading, setLoading] = useState(false);
    const [billingType, setBillingType] = useState('monthly');
    const [currentPlan, setCurrentPlan] = useState(-1);
    const [subscription, setSubscription] = useState<Subscription | undefined>(undefined);
    const [selectedMethod, setSelectedMethod] = useState('exist');
    const [paymentMethod, setPaymentMethod] = useState<PaymentMethod | null>(null);
    const formRef = useRef<any>(null);
    const [clientSecret, setClientSecret] = useState<string | undefined>(undefined);
    const [selectedPlan, setSelectedPlan] = useState(-1);
    const [isStepOne, setIsStepOne] = useState(true);
    const location = useLocation();
    useEffect(() => {
        setLoading(true);
        BillingService.GetPlans().then(
            (plans) => {
                if (plans.every((plan: Plans) => plan.code !== "free-plan")) {
                    plans.unshift({
                        id: "free-plan",
                        type: "plan",
                        code: "free-plan",
                        name: "Free Plan",
                        interval: "monthly",
                        description: "",
                        amount_cents: 0,
                        amount_currency: "USD",
                        trial_period: 0.0
                    });
                }
                const prioritizedCode = "free-plan";
                // Tìm phần tử có `code` = 'free-plan' và di chuyển lên đầu
                const updatedPlans = plans.sort((a: { code: string }, b: { code: string }) => {
                    return a.code === prioritizedCode ? -1 : b.code === prioritizedCode ? 1 : 0;
                });
                BillingService.GetSubscription().then(
                    (data) => {
                        if (data) {
                            if (data.response && data.response.status == 401) {
                                setLoading(false)
                                AuthService.SaveCurrentUser(null);
                                AuthService.SaveCurrentAccount(null);
                                AuthService.Logout();
                                navigate('/signin', {
                                    state: { from: location.pathname, previousState: location.state, }, replace: true
                                });
                            }
                            if (UtilityHelper.isPresent(data['ending_at']) || data.plan_code === 'free-plan') {
                                setCurrentPlan(0)
                                setLoading(false);
                            } else {
                                updatedPlans.forEach((plan: any, index: any) => {
                                    if (data.plan.name === plan.name) {
                                        setCurrentPlan(index);
                                        return;
                                    }
                                });
                                setLoading(false);
                            }
                        } else {
                            //no data
                            setCurrentPlan(0)
                            setLoading(false);
                        }

                    });
                setPlans(updatedPlans);
            }
        );


        CustomerService.GetSetupIntent(null).then((data) => {
            if (data.response && data.response.status == 401) {
                AuthService.SaveCurrentUser(null);
                AuthService.SaveCurrentAccount(null);
                AuthService.Logout();
                navigate('/signin', {
                    state: { from: location.pathname, previousState: location.state, }, replace: true
                });

            } else {
                setClientSecret(data.client_secret)
            }
        });

        CustomerService.GetPaymentMethods().then((data) => {
            console.log(data)
            if (data.response && data.response.status == 401) {
                setLoading(false)
                AuthService.SaveCurrentUser(null);
                AuthService.SaveCurrentAccount(null);
                AuthService.Logout();
                navigate('/signin', {
                    state: { from: location.pathname, previousState: location.state, }, replace: true
                });
            }
            if (data.length === 0) {
                setSelectedMethod('new')
            } else

                if (data[0]['type'] === 'card') {
                    setPaymentMethod({
                        id: data[0].id,
                        card: {
                            last4: data[0]['card']['last4'],
                            exp_year: data[0]['card']['exp_year'],
                            exp_month: data[0]['card']['exp_month'],
                            brand: data[0]['card']['display_brand']
                        }
                    });
                } else if (data[0]['type'] === 'sepa_debit') {
                    setPaymentMethod({
                        id: data[0].id,
                        card: {
                            last4: data[0]['sepa_debit']['last4'],
                            exp_year: 0,
                            exp_month: 0,
                            brand: data[0]['type']
                        }
                    });
                } else {
                    setPaymentMethod(null);
                }

        });

    }, []);

    const handleAddNew = async () => {

        if (selectedMethod === 'new') {
            setSelectedMethod('exist')
        } else {
            setSelectedMethod('new')

        }
    };

    const goBack = () => {
        if (isStepOne) {
            navigate(`/subscription`, { state: { isCancel: false, subPlan: '' }, replace: true });

        } else {
            setIsStepOne((prev) => !prev);

        }

    };

    const handleSubscribe = (event: React.FormEvent) => {
        if (selectedMethod === 'new') {
            console.log(plans[selectedPlan].code)
            event.preventDefault();
            if (formRef.current) {
                formRef.current.handleSubmit();
            }

        } else {
            BillingService.SignUpPlan(plans[selectedPlan].code, '').then((result) => {
                navigate('/subscription/subscribe-successs/upgrade-success', { state: { statusLogin: true, planName: plans[selectedPlan].name }, replace: true });
            })

        }
    };

    const nextStep = (planIndex: number) => {
        // setLoading(true);
        setSelectedPlan(planIndex);
        setIsStepOne((prev) => !prev);

        // BillingService.SignUpPlan(plans[planIndex].code).then(
        //     () => {

        //         navigate('/subscription/subscribe-success', { state: { statusLogin: true, plan_code: plans[planIndex].code }, },);

        //         // navigate(`/subscription`, { state: { isCancel: false, subPlan: plans[planIndex].attributes.name }, replace: true });
        //     }
        // )
    };

    const buildPlanTab = (isCurrentPlan: boolean, plan_type: string, plan_name: string, plan_index: number, plan_amount: number) => {
        switch (plan_type) {
            case 'free-plan':
                return <div className="tab-card bg-white" style={{ scale: "1", border: "2px solid #e5e5e5" }}>
                    <div className="tab-card-inside">
                        <div className=' flex flex-row justify-between items-center'>
                            <div className="text-2xl font-semibold mb-2 text-black">{plan_name}</div>
                            {isCurrentPlan ? <div className="" style={{ padding: "6px 12px", backgroundColor: ColorTemple.backBlue, borderRadius: '24px', color: ColorTemple.foreBlue, fontSize: '12', fontWeight: '600', border: '1px solid #D3E5FE' }}>
                                Current plan
                            </div> : <></>}
                        </div>
                        <div className="flex flex-row justify-start items-center mb-4">
                            <div className='font-bold text-black text-5xl mr-2'>${plan_amount / 100}</div>
                            <div className='flex flex-col text-sm font-medium' style={{ color: ColorTemple.gray61 }}>
                                <div>per month</div>
                            </div>
                        </div>
                        <p className="font-bold text-black">Launch your business free on the web</p>
                        <hr className="my-8" />
                        <p className="font-bold mb-6 text-black">Features you’ll love:</p>
                        <ul className="space-y-4 mb-8">
                            <li className="flex items-center font-semibold text-black">
                                <img src={CheckBlue} style={{ height: "24px", width: "24px", marginRight: "12px" }} />AI-generated website
                            </li>
                            <li className="flex items-center font-semibold text-black">
                                <img src={CheckBlue} style={{ height: "24px", width: "24px", marginRight: "12px" }} />Smart AI tools
                            </li>
                            <li className="flex items-center font-semibold text-black">
                                <img src={CheckBlue} style={{ height: "24px", width: "24px", marginRight: "12px" }} />Basic website metrics
                            </li>
                            <li className="flex items-center font-semibold text-black">
                                <img src={CheckBlue} style={{ height: "24px", width: "24px", marginRight: "12px" }} />AI Blog Builder
                            </li>
                        </ul>

                        {isCurrentPlan ? <button className="mt-6 w-full py-3 font-semibold rounded-lg" style={{ color: "#616161", border: "1px solid #C4C4C4" }}>Current plan</button>
                            : <button className="mt-6 w-full text-white py-2 font-bold rounded-lg" style={{ backgroundColor: "#0B86E7", }} onClick={() => nextStep(plan_index)}>Downgrade</button>
                        }

                    </div>
                </div>
            case 'recommend':
                return <div className="tab-card bg-white" style={{ scale: "1", }}>
                    <div className="text-white text-center py-2" style={{ backgroundColor: "#0b86e7", border: "2px solid #0b86e7" }}>
                        <div className="text-sm font-semibold white flex flex-row justify-center items-center">
                            Recommended
                            <div className="ml-2">
                                <img src={Medal} style={{ height: "21px", width: "21px", }} />
                            </div>
                        </div>
                    </div>
                    <div className="tab-card-inside">
                        <div className=' flex flex-row justify-between items-center'>
                            <div className="text-2xl font-semibold mb-2 text-black">{plan_name}</div>
                            {isCurrentPlan ? <div className="" style={{ padding: "6px 12px", backgroundColor: ColorTemple.backBlue, borderRadius: '24px', color: ColorTemple.foreBlue, fontSize: '12', fontWeight: '600', border: '1px solid #D3E5FE' }}>
                                Current plan
                            </div> : <></>}

                        </div>
                        <div className="flex flex-row justify-start items-center mb-4">

                            <div className='font-bold text-black text-5xl mr-2'>${plan_amount / 100}</div>
                            <div className='flex flex-col text-sm font-medium' style={{ color: ColorTemple.gray61 }}>
                                <div>per month,</div>
                                <div>permanently</div>
                            </div>
                        </div>
                        <p className="font-bold text-black">Convert your web visitors into customers</p>
                        <hr className="my-8" />
                        <p className="font-bold mb-6 text-black">Everything in Free, plus:</p>
                        <ul className="space-y-4 mb-8">
                            <li className="flex items-center font-semibold text-black">
                                <img src={CheckBlue} style={{ height: "24px", width: "24px", marginRight: "12px" }} />Free Custom Domain
                            </li>
                            <li className="flex items-center font-semibold text-black">
                                <img src={CheckBlue} style={{ height: "24px", width: "24px", marginRight: "12px" }} />SEO Optimization Tools
                            </li>
                            <li className="flex items-center font-semibold text-black">
                                <img src={CheckBlue} style={{ height: "24px", width: "24px", marginRight: "12px" }} />Advanced Website Metrics
                            </li>
                            <li className="flex items-center font-semibold text-black">
                                <img src={CheckBlue} style={{ height: "24px", width: "24px", marginRight: "12px" }} />Early access to all upcoming products
                            </li>
                        </ul>

                        {/* <button className="mt-6 w-full bg-blue-500 text-white py-2 rounded-lg" onClick={() => subPlan(0)}>Upgrade plan</button> */}
                        {isCurrentPlan ? <button className="mt-6 w-full py-3 font-semibold rounded-lg" style={{ color: "#616161", border: "1px solid #C4C4C4" }}>Current plan</button>
                            : <button className="mt-6 w-full py-2 rounded-lg font-bold" style={{ backgroundColor: "#0B86E7", color: 'white' }} onClick={() => nextStep(plan_index)}>Upgrade now</button>
                        }

                    </div>
                </div>
            case 'normal':
                return <div className="tab-card bg-white" style={{ scale: "1", }}>

                    <div className="tab-card-inside">
                        <div className=' flex flex-row justify-between items-center'>
                            <div className="text-2xl font-semibold mb-2 text-black">{plan_name}</div>
                            {isCurrentPlan ? <div className="" style={{ padding: "6px 12px", backgroundColor: ColorTemple.backBlue, borderRadius: '24px', color: ColorTemple.foreBlue, fontSize: '12', fontWeight: '600', border: '1px solid #D3E5FE' }}>
                                Current plan
                            </div> : <></>}

                        </div>
                        <div className="flex flex-row justify-start items-center mb-4">
                            <div className='font-bold text-black text-5xl mr-2'>${plan_amount / 100}</div>
                            <div className='flex flex-col text-sm font-medium' style={{ color: ColorTemple.gray61 }}>
                                <div>per month,</div>
                                <div>permanently</div>
                            </div>
                        </div>
                        <p className="font-bold text-black">Convert your web visitors into customers</p>
                        <hr className="my-8" />
                        <p className="font-bold mb-6 text-black">Everything in Free, plus:</p>
                        <ul className="space-y-4 mb-8">
                            <li className="flex items-center font-semibold text-black">
                                <img src={CheckBlue} style={{ height: "24px", width: "24px", marginRight: "12px" }} />Free Custom Domain
                            </li>
                            <li className="flex items-center font-semibold text-black">
                                <img src={CheckBlue} style={{ height: "24px", width: "24px", marginRight: "12px" }} />SEO Optimization Tools
                            </li>
                            <li className="flex items-center font-semibold text-black">
                                <img src={CheckBlue} style={{ height: "24px", width: "24px", marginRight: "12px" }} />Advanced Website Metrics
                            </li>
                            <li className="flex items-center font-semibold text-black">
                                <img src={CheckBlue} style={{ height: "24px", width: "24px", marginRight: "12px" }} />Early access to all upcoming products
                            </li>
                        </ul>

                        {/* <button className="mt-6 w-full bg-blue-500 text-white py-2 rounded-lg" onClick={() => subPlan(0)}>Upgrade plan</button> */}
                        {isCurrentPlan ? <button className="mt-6 w-full py-3 font-semibold rounded-lg" style={{ color: "#616161", border: "1px solid #C4C4C4" }}>Current plan</button>
                            : <button className="mt-6 w-full py-2 rounded-lg font-bold" style={{ backgroundColor: "#0B86E7", color: 'white' }} onClick={() => nextStep(plan_index)}>Upgrade now</button>
                        }

                    </div>
                </div>
        }
    }

    const buildPaymentNew = () => {
        return <Elements stripe={stripePromise} options={{ clientSecret }}>
            <div className='flex flex-row justify-start items-center' style={{ marginBottom: "16px" }}>
                <div onClick={handleAddNew} style={{ height: "24px", width: "24px", marginRight: "12px", cursor: "pointer", borderRadius: "6px", }} className='hover:bg-gray-200'> <img src={ArrowLeft}></img></div>
                <div style={{ fontWeight: "500", fontSize: "14px", color: "black", }}> Enter payment details
                </div>
            </div>

            <AddEditForm ref={formRef} upgrade_plan_code={plans[selectedPlan].code} />

        </Elements>
    };
    const buildPaymentExist = () => {

        return <div className='flex flex-col'>
            <label htmlFor="email" className="block" style={{ fontWeight: "500", fontSize: "14px", color: "black", marginBottom: '16px' }}>
                Choose your method
            </label>
            {paymentMethod !== null ?
                <div style={{ padding: "16px 20px", borderRadius: "8px", border: "#c4c4c4 1px solid", boxShadow: "0px 4px 8px 0px #00000014", }} className='flex flex-row justify-start items-center'>
                    <div style={{ width: "auto", height: "38px", }}>   <LogoBrand card_brand={paymentMethod?.card.brand ?? ""} /></div>
                    <div className='flex flex-col'>
                        <div style={{ fontWeight: "700", fontSize: "18", color: "#2c2c2c" }}>{PaymentHelper.getBrandName(paymentMethod!.card.brand)} ending in {paymentMethod!.card.last4}</div>
                        <div style={{ color: '#696969', fontSize: '14px', fontWeight: 500 }}>Expiry on {PaymentHelper.formatDateExpiry(paymentMethod!.card.exp_month)}/{paymentMethod!.card.exp_year}</div>

                    </div>
                </div>
                : <></>
            }

            <div onClick={handleAddNew} style={{ padding: "16px 20px", borderRadius: "8px", border: "#D8D8D8 1px dashed", boxShadow: "0px 4px 8px 0px #00000014", marginTop: "16px", userSelect: "none", cursor: "pointer" }} className='flex flex-row justify-center items-center'>
                <div style={{ width: "24px", height: "24px", }}>  <img src={AddIcon}></img></div>
                <div style={{ color: ColorTemple.gray61, fontSize: '15px', fontWeight: 500 }}>Add new</div>

            </div>
        </div>
    };

    const setHome: () => JSX.Element = () => {
        return (
            <div style={{ backgroundColor: '#F9FAFC' }}>
                <NavBarBilling />
                <div className='mt-8' style={{ height: "auto" }}>

                    <div className='plan-page-main-content '>
                        <div className='hover-container mb-4' onClick={goBack}>
                            <div> <img
                                src={ArrowLeft}
                                loading="lazy"
                                style={{
                                    marginRight: '8px', height: '24px', width: '24px', display: 'block'
                                }}
                            /></div>
                            <h5>{isStepOne ? 'Back to Plan & Billings' : "Back to Plan Page"}</h5>
                        </div>
                        {isStepOne
                            ?
                            <div >
                                <div style={{ justifyContent: "center", display: "flex", fontSize: "48px", fontWeight: "700", color: "black", marginBottom: "60px", lineHeight: "58.08px", textAlign: 'center' }}>Upgrade your account</div>
                                <div className="flex space-x-2 justify-center">
                                    <div
                                        style={{
                                            display: 'flex',
                                            alignItems: 'center',
                                            gap: '8px',
                                            padding: '8px 16px',
                                            borderWidth: '6px',
                                            borderRadius: '8px',
                                            boxShadow: billingType === 'monthly' ? ' 0px 4px 10px 0px #0B86E733' : ' 0px 4px 10px 0px #ffffff',
                                            cursor: 'pointer',
                                            borderColor: billingType === 'monthly' ? '#0B86E7' : '#D1D5DB',
                                            backgroundColor: billingType === 'monthly' ? 'white' : '#E5E7EB'
                                        }}
                                        onClick={() => setBillingType('monthly')}
                                    >
                                        <span className={`font-medium ${billingType === 'monthly' ? 'text-black' : 'text-gray-500'}`}>Monthly billing</span>
                                    </div>
                                </div>
                                <div className='' style={{
                                    display: "flex",
                                    flexWrap: "wrap",
                                    gap: "16px",
                                    alignItems: "center",
                                    justifyContent: 'center'
                                }}>
                                    {plans.map((plan, index) => (
                                        buildPlanTab(currentPlan == index ? true : false, index == 0 ? 'free-plan' : index == 1 ? 'recommend' : 'normal', plan.name, index, plan.amount_cents)
                                    ))}
                                </div>

                            </div>

                            : <div className='container-main-payment pt-1'>
                                <div className='title  mt-1'>Choose your payment method</div>
                                <div className='main-content'>
                                    <div style={{ backgroundColor: "white", borderRadius: "16px", boxShadow: "0px 4px 50px 0px #0000001A", padding: "32px", width: "581px", height: 'fit-content' }}>

                                        {selectedMethod === 'new' ? buildPaymentNew() : buildPaymentExist()
                                        }


                                    </div>
                                    <div className='order-summary'>
                                        <div className='order-title'>ORDER SUMMARY</div>
                                        <div className='order-item' >
                                            <div className='order-feature-title '>{plans[selectedPlan].name.toUpperCase()} FEATURES:</div>
                                            <div className='order-feature-item'><span style={{ fontSize: "20px" }}>• </span> Free Custom Domain</div>
                                            <div className='order-feature-item'><span style={{ fontSize: "20px" }}>• </span> SEO Optimization Tools</div>
                                            <div className='order-feature-item'><span style={{ fontSize: "20px" }}>• </span> Advanced Website Metrics</div>
                                            <div className='order-feature-item'><span style={{ fontSize: "20px" }}>• </span> Early access to all upcoming products</div>
                                        </div>
                                        <div className='order-price-summary'>
                                            <div>  <div style={{ fontWeight: "700", fontSize: "18px", lineHeight: "21.78px" }}>{plans[selectedPlan].name}</div><div>${plans[selectedPlan].amount_cents / 100} / every month</div></div>
                                            <div>${plans[selectedPlan].amount_cents / 100}</div>
                                        </div>
                                        <hr style={{ color: ColorTemple.grayE5, margin: "8px 0" }}></hr>

                                        <div className='order-total'>
                                            <div>Total due today</div>
                                            <div style={{ fontWeight: "700" }}>${plans[selectedPlan].amount_cents / 100}</div>
                                        </div>
                                        <div className='payment-button' onClick={handleSubscribe} style={{ color: 'white', backgroundColor: ColorTemple.foreBlue }}>Confirm payment</div>

                                        <div className='policy-text'>By purchasing, you agree to our  <a href="https://castlebird.com/terms-of-service" className="text-blue-500 underline">
                                            Terms of Service
                                        </a>{" "}
                                            and{" "}
                                            <a href="https://castlebird.com/privacy-policy" className="text-blue-500 underline">
                                                Privacy Policy
                                            </a>.</div>
                                        <div className='policy-footer'>
                                            You also agree to the auto-renewal of ${plans[selectedPlan].amount_cents / 100} per month starting on {DateTimeHelper.getToday()}.<br></br> You can cancel anytime before then.
                                        </div>
                                        <div className='security-section'>
                                            <div className='security-item'>
                                                <div className='security-icon'><img src={SafeIcon} /></div>
                                                <div className='security-text'>SAFE & SECURE<br></br>PAYMENT</div>
                                            </div>
                                            <div className='flex flex-row '>
                                                <div className='security-icon' ><img src={RefundLogo} /></div>
                                                <div className='security-text'>30 MONEY-BACK<br></br>GUARANTEE</div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                            </div>

                        }
                    </div>

                </div>

            </div>
        );
    };

    return <>{loading ? <LoadingSuspense /> : setHome()}</>;
}

export default PlansPage;
