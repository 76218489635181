import React, { useState } from 'react'
import './styles/index.scss'
import { Card } from 'primereact/card'
import Step1 from './Step1'
import Step2 from './Step2'
import { Button } from 'primereact/button';

const BlogOnboarding = () => {
  const [currentStep, setCurrentStep] = useState<number>(1);
  const [blogTopic, setBlogTopic] =  useState<string | null>(null);

  const nextStep = () => {
    setCurrentStep((prev) => prev + 1);
  };

  return (
    <>
        <div className="onboarding-container blog-onboarding flex items-center justify-center">
          <a href="https://castlebird.com">
            <img src={require('assets/castlebird_white 3.png')} className="logo pt-2 ml-3" alt="Logo" />
          </a>
      {currentStep < 2 && (
          <Card className="w-full max-w-3xl p-8 onboarding-card">
            {/* {currentStep === 1 && <InitStep onComplete={nextStep} />} */}
            {currentStep === 1 && <Step1 setBlogTopic={setBlogTopic} />}
            {currentStep === 1 && (
              <Button
                label="Create my blog post"
                className="p-button-primary"
                style={{
                  float: 'right',
                  backgroundColor: '#2318DD',
                  padding: '18px 40px',
                  fontSize: '18px',
                  fontWeight: '600',
                  borderRadius: '4px',
                  color: '#fafafa',
                  marginTop: '16px',
                }}
                onClick={nextStep}
                disabled={!blogTopic} 
              />
            )}
          </Card>
      )}
        {currentStep === 2 && <Step2 blogTopic={blogTopic} />}
        </div>
    </>
  );
};

export default BlogOnboarding;
