import React, { useEffect } from 'react';
import { Button } from 'primereact/button';
import { Card } from 'primereact/card';
import 'primereact/resources/themes/saga-blue/theme.css'; // or any other theme
import 'primereact/resources/primereact.min.css';
import 'primeicons/primeicons.css';
import './styles/signin.css'
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { googleLogout, GoogleOAuthProvider, useGoogleLogin } from "@react-oauth/google";
import AuthService from 'api/services/authService';
import { useLocation, useNavigate } from 'react-router-dom';
import GoogleLogo from "assets/flat-color-icons_google.png"
import UtilityHelper from 'helpers/UtilityHelper';
import MessNav from 'components/MessNav';
import CastleBirdLogo from "assets/logo.png"
import ApiConfig from 'api/apiConfig';
import posthog from 'posthog-js';
import TrackerHelper from 'helpers/TrackerHelper';
const SignInGraphic = "../../assets/Sign"

const SignIn = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const queryParams = new URLSearchParams(location.search);
  const domain = queryParams.get('domain');
  const typePage = queryParams.get('type');

  const login = useGoogleLogin({
    onSuccess: async tokenResponse => {
      await AuthService.loginGoogle(tokenResponse).then((e) => {
        const from = location.state?.from || '/subscription';
        const previousState = location.state?.previousState || {};
        navigate(from, { replace: true, state: previousState, });

      });

    },
    scope: 'https://www.googleapis.com/auth/userinfo.profile https://www.googleapis.com/auth/userinfo.email',
    flow: 'implicit'
  })


  const handleClaimSite = useGoogleLogin({
    onSuccess: (codeResponse) => LoginGoogleSuccess(codeResponse),
    onError: (error) => console.log("Login Failed:", error),
  });

  const LoginGoogleSuccess = async (res: any) => {
    try {
      const data = await AuthService.loginGoogle(res, domain);
      const saved = await AuthService.SaveAuth(data);
  
      const currentUser = await AuthService.GetCurrentUser();  
      posthog.alias(currentUser.id);
      posthog.identify(currentUser.id, {
        email: currentUser.email,
        source: typePage, 
      });
  
      if (typePage === 'blog') {
        navigate('/download?type=blog', { replace: true });
      }
      if (typePage === 'website') {
        navigate('/download?type=website', { replace: true });
      }
  
      toast.success(`The ${typePage} has been saved to ${currentUser.email} account`, {
        position: "top-right",
      });
  
    } catch (error) {
      console.error('error', error);
      toast.error('Login failed, please try again!', { position: "top-right" });
    }
  };

  useEffect(() => {
    if (typePage === 'website')  TrackerHelper.virtualPage("/signin/type_website", "Signin - Website Onboading");
    if (typePage === 'blog') TrackerHelper.virtualPage("/signin/type_blog", "Signin - Blog Onboarding"); 
  },[typePage])


  if (typePage === 'website') {
    return (
      <div className="min-h-screen flex flex-col md:flex-row">
        <div className="flex-1 bg-white flex items-center justify-center p-6">
          <div className="max-w-lg flex flex-col items-center">
            <a href="https://castlebird.com">
              <img src={require('assets/logo.webp')} className="pt-2 ml-3 logo-img" alt="Logo" />
            </a>
            <h1 className="text-4xl font-semibold mb-3 text-black text-center">Your website is ready! <br></br>
              First, create a free account</h1>
            <p className="mb-3" style={{ textAlign: 'center', fontSize: '16px', color: '#02062c' }}>Create your free account to view and customize your website. No credit card required.</p>
            <button className="text-black w-full mt-3 py-4 rounded-md flex items-center justify-center" onClick={() => handleClaimSite()} style={{ border: "1px solid hsla(0, 0%, 90%, 1)", boxShadow: "0px 4px 5px 0px rgba(0, 0, 0, 0.1)" }}>
              <img
                src="https://www.gstatic.com/firebasejs/ui/2.0.0/images/auth/google.svg"
                alt="Google"
                className="w-6 h-6 mr-3"
              />
              Sign in with Google
            </button>
            <p className=" text-lg mt-6 text-center md:text-left">
              By clicking " Sign in with Google", you agree to our{" "}
              <a href="https://castlebird.com/terms-of-service" className="text-blue-500 underline">
                Terms of Service
              </a>{" "}
              and{" "}
              <a href="https://castlebird.com/privacy" className="text-blue-500 underline">
                Privacy Policy
              </a>.
            </p>
          </div>
        </div>

        <div className="flex-1 flex items-center justify-center p-6" style={{ background: 'linear-gradient(30.47deg, rgba(0, 117, 211, 0.5) 18.52%, #0075D3 81.03%)' }}>
          <img
            src={require('assets/Sign Up graphic.png')}
            alt="Preview"
            className="max-w-full h-auto"
          />
        </div>
      </div>
    )
  }

  if (typePage === 'blog') {
    return (
      <div className="min-h-screen flex flex-col md:flex-row">
        <div className="flex-1 bg-white flex items-center justify-center p-6">
          <div className="max-w-lg flex flex-col items-center">
            <a href="https://castlebird.com">
              <img src={require('assets/logo.webp')} className="pt-2 ml-3 logo-img" alt="Logo" />
            </a>
            <h1 className="text-4xl font-semibold mb-3 text-black text-center">Your blog post is ready! <br></br>
              First, create a free account</h1>
            <p className="mb-3" style={{ textAlign: 'center', fontSize: '16px', color: '#02062c' }}>Create your free account to edit,publish your blog post. No credit card required.</p>
            <button className="text-black w-full mt-3 py-4 rounded-md flex items-center justify-center" onClick={() => handleClaimSite()} style={{ border: "1px solid hsla(0, 0%, 90%, 1)", boxShadow: "0px 4px 5px 0px rgba(0, 0, 0, 0.1)" }}>
              <img
                src="https://www.gstatic.com/firebasejs/ui/2.0.0/images/auth/google.svg"
                alt="Google"
                className="w-6 h-6 mr-3"
              />
              Sign in with Google
            </button>
            <p className=" text-lg mt-6 text-center md:text-left">
              By clicking " Sign in with Google", you agree to our{" "}
              <a href="https://castlebird.com/terms-of-service" className="text-blue-500 underline">
                Terms of Service
              </a>{" "}
              and{" "}
              <a href="https://castlebird.com/privacy" className="text-blue-500 underline">
                Privacy Policy
              </a>.
            </p>
          </div>
        </div>

        <div className="flex-1 flex items-center justify-center p-6" style={{ background: 'linear-gradient(30.47deg, rgba(0, 117, 211, 0.5) 18.52%, #0075D3 81.03%)' }}>
          <img
            src={require('assets/Blog sign up graphic.png')}
            alt="Preview"
            className="max-w-full h-auto"
          />
        </div>
      </div>
    )
  }


  return (
    <div className='container-main'>
      <div className='main-content-login-page ' style={{ justifyContent: "center", }}>

        <div className="max-w-lg flex flex-col items-center">
          <div className='logo-container'>  <a href={"https://" + ApiConfig.MAIN_DOMAIN}>
            <img src={CastleBirdLogo} className="pt-2 ml-3 logo-img" alt="Logo" />
          </a></div>
          <h1 className="text-4xl font-semibold mb-3 text-black">Welcome to CastleBird</h1>
          <p className="text-gray-600 text-lg mb-6 text-center md:text-left" >
            Business management designed for anyone, from anywhere.
          </p>
          <GoogleOAuthProvider clientId="821735858312-8ggqo42192gf2udhueoc7s2ufduiokhs.apps.googleusercontent.com">
            <button className="text-black w-full py-4 rounded-md flex items-center justify-center" onClick={() => login()} style={{ border: "1px solid hsla(0, 0%, 90%, 1)", boxShadow: "0px 4px 5px 0px rgba(0, 0, 0, 0.1)", marginTop: "0" }}>
              <img
                src={GoogleLogo}
                alt="Google"
                className="w-6 h-6 mr-3"
              />
              Continue with Google
            </button>
          </GoogleOAuthProvider>

          <p className=" text-lg mt-6 text-center md:text-left">
            By clicking “Continue with Google”, <br></br>you agree to our{" "}
            <a href={"https://" + ApiConfig.MAIN_DOMAIN + "/terms-of-service"} className="text-blue-500 underline">
              Terms of Service
            </a>{" "}
            and{" "}
            <a href={"https://" + ApiConfig.MAIN_DOMAIN + "/privacy"} className="text-blue-500 underline">
              Privacy Policy
            </a>.
          </p>
        </div>
      </div>
    </div>
  );
}

export default SignIn;