import ApiClient from "api/apiClient";
import ApiConfig from "api/apiConfig";

class CustomerService {
    static plans = [];


    static GetPaymentMethods = async () => {
        // return [];
        const url = ApiConfig.API_URL + '/billing/customer/paymethods';

        try {
            const response = await ApiClient.sendGet(url, {}, true, true);
            return response;
        } catch (error) {
            console.log(error);
            return error;
        }
    };

    static GetSetupIntent = async (payment_method_id: any, user_email: any = null) => {
        const url = ApiConfig.API_URL + '/billing/customer/intent';

        try {
            let filter: { email?: any; payment_method_id?: any } = {};

            if (user_email) {
                filter.email = user_email;
            }

            if (payment_method_id) {
                filter.payment_method_id = payment_method_id;
            }

            console.log(filter);

            const response = await ApiClient.sendGet(url, filter, true, true);
            console.log(response);

            return response;
        } catch (error) {
            console.error("Error in GetSetupIntent:", error);
            return error;
        }
    };


}

export default CustomerService;
