import "App.scss";
import "./styles/style.scss";
import { JSX, useContext, useEffect, useRef, useState } from "react";
import UserService from "api/services/UserService";
import {
  UserInterface,
} from "types/ApiInterface";
import LoadingSuspense from "components/Suspense";
import "aos/dist/aos.css";
import PagesService from "api/services/PagesService";
import { Theme } from "pages/Home";
import { StateContext } from "store/Provider";
import { Helmet } from "react-helmet";
import Favicon from "react-favicon";
import CB_logo from "assets/icon.png";
import WebsiteService from "api/services/SiteService";
import AccountService from "api/services/AccountService";
import { ActionKind } from "store/Reducer";
import UtilityHelper from "helpers/UtilityHelper";
import Footer from "components/Footer";
import { Outlet, useLocation, useNavigate, useParams } from "react-router-dom";
import ProfileNavbar from "components/NavbarProfile";
import SectionsService from "api/services/SectionsService";
import ApiConfig from "api/apiConfig";
<Helmet>
  <script src="../../js/jquery-3.1.1.min.js" type="text/javascript" />
  <script src="../../js/flickity.min.js" type="text/javascript" />
  <script src="../../js/easypiechart.min.js" type="text/javascript" />
  <script src="../../js/parallax.js" type="text/javascript" />
  <script src="../../js/typed.min.js" type="text/javascript" />
  <script src="../../js/datepicker.js" type="text/javascript" />
  <script src="../../js/isotope.min.js" type="text/javascript" />
  <script src="../../js/ytplayer.min.js" type="text/javascript" />
  <script src="../../js/lightbox.min.js" type="text/javascript" />
  <script src="../../js/granim.min.js" type="text/javascript" />
  <script src="../../js/jquery.steps.min.js" type="text/javascript" />
  <script src="../../js/countdown.min.js" type="text/javascript" />
  <script src="../../js/twitterfetcher.min.js" type="text/javascript" />
  <script src="../../js/spectragram.min.js" type="text/javascript" />
  <script src="../../js/smooth-scroll.min.js" type="text/javascript" />
  <script src="../../js/scripts.js" type="text/javascript" />
</Helmet>

function BlogLayout() {
  const [isHome, setIsHome] = useState(true);
  const [site, setSite] = useState({
    logo_data: {
      image_s: ''
    },
    name: ''
  });
  let params = useParams();
  const navigate = useNavigate();
  const [theme, setTheme] = useState<String | Theme>("default");
  const [loading, setLoading] = useState(true);
  const formRef = useRef<HTMLInputElement>(null);
  const { state, dispatch } = useContext(StateContext);
  const [pages, setPages] = useState<Array<any>>([]);
  const footerRef = useRef<HTMLDivElement>(null);
  const [currentPage, setCurrentPage] = useState<any>();
  const [showHeadband, setShowHeadband] = useState<boolean>(false);


  const getPages = async (website_id: string) => {
    console.log("Get pages");
    const data = await PagesService.GetPages({ website_id: website_id, });
    setPages(data);

    const blogPage = data.find((dataPage: { page_type: string; }) => dataPage.page_type === 'blog');

    setCurrentPage(blogPage);
  };

  useEffect(() => {
    setLoading(true);
    setTheme(UtilityHelper.getTheme());
    UtilityHelper.initApp(state).then((val) => {
      console.log(val);
      setSite(val.website);
      getPages(val.website.id)
      dispatch({ type: ActionKind.SET_CURRENT_WEBSITE, payload: val.website });
      dispatch({ type: ActionKind.SET_CURRENT_USER, payload: val.user });
      setLoading(false);

      if (val.website.is_temporary === true || val.website.is_temporary === "true") {
        setShowHeadband(true);
      }
    });
  }, []);

  const currentDomain = window.location.hostname;
  const handleCustomizeClick = () => {
    window.location.href = `https://${ApiConfig.MAIN_APP}/signin?type=blog&domain=${currentDomain}`;
    // window.location.href = `http://localhost/signin?type=blog&domain=${currentDomain}`;
  };

  const Headband: React.FC = () => {

    return (
      <div className="headband">
        <img onClick={() => window.location.href = 'https://castlebird.com/ai-content-marketing'} src="https://castlebird.com/img/castlebird-logo.webp" alt="Logo" style={{ cursor: 'pointer' }} />
        <h5 className="">You're in preview mode  </h5>
        <button onClick={handleCustomizeClick} className="customize-button" style={{ backgroundColor: '#2318DD ' }}>Make it yours</button>
      </div>
    );
  };

  const setHome: () => JSX.Element = () => {
    return (
      <>
        <Favicon
          // url={!!site.logo_data ? site.logo_data.image_s : CB_logo}
          url={CB_logo}
          iconSize={36}
        />
        {site ? (
          <Helmet>
            <title>{site ? site['name'] : ""}</title>
            <meta
              name="keywords"
              content={`CastleBird, castlebird, CB, building website, building website for free, website building`}
            />
          </Helmet>
        ) : null}

        <div className={`profile ${showHeadband ? 'hasHeadband' : ''} flex-vertical ${theme}`} >
          {showHeadband && <Headband />}
          {site ? (
            <ProfileNavbar
              current_page={currentPage}
              site={site}
              pages={pages}
              onChangePage={(page) => {
                if (page.page_type !== 'blog') {
                  setCurrentPage(page);
                  navigate(`/${page.permaid}`);
                }
                else {
                  // blog
                  navigate(ApiConfig.BLOG_URL);
                }
              }}
            />
          ) : null}
        </div>
        <Outlet />
        {site ? (
          <Footer
            ref={footerRef}
            site={site}
            pages={null}
            theme={theme}
            onChangePage={(page: any) => {
            }}
          />
        ) : null}

        {showHeadband && (
          <div className="bottom-bar" style={{
            margin: '0 auto',
            background: "#fafafa",
            position: 'fixed',
            bottom: '16px',
            zIndex: '99999',
            padding: '8px 16px',
            borderRadius: '4px',
            boxShadow: 'rgba(100, 100, 111, 0.2) 0px 7px 29px 0px',
            left: '50%',
            transform: 'translateX(-50%)'
          }}
          >
            <p style={{ color: '#02062c', fontWeight: '400', fontSize: '16px' }}> <span style={{ color: 'rgb(35, 24, 221)', textDecoration: 'underline', cursor: 'pointer', fontWeight: '500' }} onClick={handleCustomizeClick}>Create a free account</span> to edit, preview and publish your blog.</p>
          </div>
        )}
      </>
    );
  };

  return <>{loading ? <LoadingSuspense /> : setHome()}</>;
}

export default BlogLayout;