import React, { useEffect, useState, useCallback, useContext } from 'react';
import './Unsubscribe.scss';
import Logo from '../../assets/logo.webp';
import { Checkbox } from 'primereact/checkbox';
import { Button } from 'primereact/button';
import { Dialog } from 'primereact/dialog';
import 'primereact/resources/themes/saga-blue/theme.css';  // PrimeReact theme
import 'primereact/resources/primereact.min.css';
import 'primeicons/primeicons.css';
import NotifyPreferencesService from 'api/services/NotiReferencesService';
import UserReachSnapService from 'api/services/UserReachSnapService';
import { useParams, useNavigate } from 'react-router-dom';
import { toast} from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';


// const defaultPreferences = {
//   featureUpdates: false,
//   weeklyBlog: false,
//   productNews: false,
//   surveysFeedback: false,
//   betaTesting: false,
//   billing: false,
// };

// Các nhóm thông báo cần thiết
const preferenceGroups = [
  { id: 'account', label: 'Account Related', description: 'Get notified for changes that have been made to your account in real time.' },
  { id: 'billing', label: 'Billing', description: 'Receive notifications for every transaction' },
  // { id: 'featureUpdates', label: 'Feature Updates', description: 'Get notified when new features or improvements are launched on our platform.' },
  // { id: 'productNews', label: 'Product News & Offers', description: 'Stay up to date with the latest product updates, exclusive offers, and special promotions.' },
  // { id: 'betaTesting', label: 'Beta Testing Opportunities', description: 'Get early access to beta features and participate in testing new functionalities.' },
  // { id: 'billing', label: 'Billing', description: 'Receive billing notifications.' }
];

const UnsubscribePage = () => {
  const [preferences, setPreferences] = useState({});
  const { user_key } = useParams();
  const [userEmail,setUserEmail] = useState('')
  const navigate=useNavigate(); 
  const [showUnsubscribeModal, setShowUnsubscribeModal] = useState(false);
  const isUncheckedAll = Object.values(preferences).every(value => value === false);


  const fetchPreferences = useCallback(async () => {
    try {
      const result = await NotifyPreferencesService.Get(user_key);
      console.log(result)
      const updatedPreferences = preferenceGroups.reduce((newPreferences, group) => {
        newPreferences[group.id] = result.find(pref => pref.id === group.id)?.enabled || false;
        return newPreferences;
      }, {});
      setPreferences(updatedPreferences);
    } catch (error) {
      console.log("Error fetching data:", error);
    }
  }, [user_key]);

  useEffect(() => {
    fetchPreferences();
  }, [fetchPreferences]);

  // get user's email 
  useEffect(()=> {
    const getUser = async ()=> {
      const user= await UserReachSnapService.GetUser({key:user_key});
      setUserEmail(user[0].email);
      console.log(user)
    }
    getUser()
  },[])

  const handleCheckboxChange = (e) => {
    setPreferences(prevPreferences => ({
      ...prevPreferences,
      [e.target.name]: e.checked
    }));
  };

  const savePreferences = useCallback(async () => {
    const updates = preferenceGroups.map(group => ({
      group: group.id,
      enabled: preferences[group.id],
    }));

    try {
      await Promise.all(updates.map(update =>
        NotifyPreferencesService.Update(user_key, { group: update.group, enabled: update.enabled })
      ));
      toast.success('Your preferences have been saved', {position: "top-right"});
      // Nếu tất cả đều là false, set isUnsubscribeFromAll thành true
     
      navigate(`/unsubscribe/${user_key}/successful`)
      console.log('Preferences saved successfully:', preferences);
    } catch (error) {
      console.error('Failed to save preferences:', error);
    }
  }, [preferences, user_key]);

  const unsubscribeAll = useCallback(async () => {
    const resetPreferences = preferenceGroups.reduce((acc, group) => {
      acc[group.id] = false;
      return acc;
    }, {});

    setPreferences(resetPreferences);

    const updates = preferenceGroups.map(group => ({
      group: group.id,
      enabled: false, 
    }));

    try {
      const results = await Promise.all(updates.map(update =>
        NotifyPreferencesService.Update(user_key, {
          group: update.group,
          enabled: update.enabled
        })
      ));
      toast.success('Unsubscription from all emails successful!', {position: "top-right"});
      navigate(`/unsubscribe/${user_key}/successful`)
      setShowUnsubscribeModal(false);
      // Kiểm tra kết quả từ API
      console.log('API response for unsubscribeAll:', results);
      console.log('All preferences unsubscribed successfully.');
    } catch (error) {
      console.log('Failed to unsubscribe all:', error);
    }
}, [user_key]);

  return (
     
        <div className="unsubscribe__wrapper" style={{paddingBottom:'40px',paddingTop:'80px'}}>
            <div className='logo md:m-6 '>
              <a href="/">
                <img src={Logo} alt="CastleBird Logo" className="mx-auto mb-4" />
              </a>
            </div>
          <div className="flex items-center justify-center">
            <div className="bg-white p-6 w-full max-w-2xl flex flex-col items-center">
              <div className="text-center mb-6">
                <h2 className=" text-2xl md:text-4xl font-semibold">
                  Update email preferences for: 
                  <span className="md:text-3xl" style={{fontWeight:'semibold'}}> {userEmail} </span>
                </h2>
              </div>

              <div className="space-y-4 flex flex-col" style={{gap: '32px'}}>
                {preferenceGroups.map(group => (
                  <div key={group.id} className="flex items-start reference-container">
                    <Checkbox
                      inputId={group.id}
                      name={group.id}
                      checked={preferences[group.id]}
                      onChange={handleCheckboxChange}
                      className="mr-2 mt-3"
                    />
                    <label className="ml-2">
                      <h3 className="font-medium mb-1" style={{color:'#02062c'}}>{group.label}</h3>
                      <p className="text-xl" style={{color:'#02062c'}}>{group.description}</p>
                    </label>
                  </div>
                ))}
                
                <div className="cta mt-6 flex-col md:flex-row flex items-start gap-8 ">
                  <Button
                    label="Save preferences"
                    onClick={()=> {
                      if (isUncheckedAll) {
                        setShowUnsubscribeModal(true)
                      } else {
                        savePreferences()
                      }
                    }}
                    className={`modal-trigger p-button-primary ${isUncheckedAll && 'warning'} mt-0 `}
                    style={{padding:'12px 24px', backgroundColor:'#2275D7',borderRadius:'4px', fontWeight:'bold'}}
                  />
                  <Button
                    label=" Unsubscribe from all"
                    onClick={() => setShowUnsubscribeModal(true)}
                    className="modal-trigger p-button-secondary ml-0 mt-0"
                    style={{padding:'12px 24px', color:'black', backgroundColor:'#f2f2f2',borderRadius:'4px', fontWeight:'bold'}}
                  />
                </div>
              </div>
            </div>
          </div>

           {/* Unsubscribe All Confirmation Modal */}
      <Dialog
        header={<h4 style={{fontSize:'20px', fontWeight:'bold'}}> Are you sure? </h4>}
        visible={showUnsubscribeModal}
        style={{ borderRadius: '8px'  }}
        className='w-full max-w-2xl'
        onHide={() => setShowUnsubscribeModal(false)}
        footer={
          <div className="cta" style={{display:'flex',justifyContent: 'space-between'}}>
            <Button label="Cancel"  onClick={() => setShowUnsubscribeModal(false)} className=" button cancel-btn "  />
            <Button label="Unsubscribe" onClick={unsubscribeAll} className=" button warning" autoFocus />
          </div>
        }
      >
        <p style={{fontSize: '16px'}}>We’re sorry to see you go! <br/>
        By unsubscribing, we can't notify you about our upcoming free AI-driven features, including updates about CRM, Chat, and SEO, designed to greatly enhance your business operations. <br/>
        We’d love for you to stay subscribed so you don’t miss these valuable tools!
        </p>
      </Dialog>

        </div>  

  );
};

export default UnsubscribePage;
