import "./App.scss";
import {
  BrowserRouter,
  Navigate,
  Route,
  RouterProvider,
  Routes,
} from "react-router-dom";
import { StateContext, StateProvider } from "store/Provider";
import reducer, { initialState } from "store/Reducer";
import MPService from "api/services/MixpanelService";
import "broadcastchannel-polyfill";
import PolicyPage from "pages/Policy";
import TermsPage from "pages/Terms";
import Home from "pages/Home";
import Page404 from "pages/404";
import { ParallaxProvider } from 'react-scroll-parallax';
import Blog from "pages/Blog";
import Unsubscribe from "pages/unsubscribe/Unsubscribe";
import Successful from "pages/unsubscribe/Successful"
import PublicProfile from "pages/PublicProfile";
import BlogLayout from "pages/Blog/layout";
import BlogPost from "pages/Blog/blog_post";
import Onboarding from "pages/Onboarding";
import SignIn from "pages/Auth/signin";
import Download from "pages/Auth/download";
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Restricted from "routes/restricted";
import BlogOnboarding from "pages/BlogOnboarding";
import PlansAndBilling from "pages/subscription/plans_billing";
import CancelSuccess from "pages/subscription/components/CancelSuccess";
import LoginPage from "pages/login/login_page";
import StripeFormPage from "pages/subscription/stripe_form";
import PaymentMethods from "pages/subscription/manage_card";
import WelcomePage from "pages/subscription/welcome_page";
import PlansPage from "pages/subscription/plans_page";
import Intermediate from "pages/subscription/components/intermediate";
import SubscribeSuccess from "pages/subscription/components/subscribe_success";
import ChoosePaymentMethod from "pages/subscription/components/choose_payment_method";
import GuestForm from "pages/subscription/form_payment_method/form_payment_guest";
import SubscribeSuccessful from "pages/subscription/sub_success/buying_domain";
import ExistingDomain from "pages/subscription/sub_success/existing_domain";
import SeoOptimization from "pages/subscription/sub_success/seo_optimization";
import GuestSuccess from "pages/subscription/sub_success/guest_success/guest_success";
import LoggedinForm from "pages/subscription/form_payment_method/form_payment_loggin";
import UpgradePlan from "pages/subscription/sub_success/guest_success/upgrade_plan";

import posthog from "posthog-js";

function App() {
  // //Mixpanel initialize
  // MPService.Init(`${process.env.REACT_APP_MIXPANEL_API_KEY}`, {
  //   debug: true,
  //   ignore_dnt: true,
  //   loaded: () => {
  //     MPService.Register({
  //       name: "visitor",
  //     });
  //   },
  // });
  posthog.init('phc_MbAX1cwamIWJwJcyWEiXcs5ZwydtbpBvhSFvvELOa56', {
    api_host: 'https://us.i.posthog.com', person_profiles: 'always' // or 'always' to create profiles for anonymous users as well
  })
  return (
    <StateProvider initialState={initialState} reducer={reducer}>
      <BrowserRouter>
        <ParallaxProvider>
          <Routes>
            {/* Home Route */}
            <Route path="/" element={<Home />} />
            <Route path="/onboarding" element={<Restricted><Onboarding /></Restricted>} />
            <Route path="/signin" element={<Restricted><SignIn /></Restricted>} />
            <Route path="/download" element={<Restricted><Download /></Restricted>} />

            {/* Unsubscribe Route */}
            <Route path="/unsubscribe" element={<Unsubscribe />} />
            <Route path="/unsubscribe/:user_key" element={<Unsubscribe />} />
            <Route path="/unsubscribe/:user_key/successful" element={<Successful />} />

            {/* Blog Routes */}
            <Route path="/blog" element={<BlogLayout />}>
              <Route index element={<Blog />} />
              <Route path=":slug" element={<BlogPost />} />
            </Route>
            <Route path="/ai-blog-onboarding" element={<BlogOnboarding />} />

            {/* Public Profile (Wildcard Route) */}
            <Route path="/:pagePermaid" element={<PublicProfile />} />

            {/* Other Static Routes */}
            <Route path="/privacy-policy" element={<Restricted><PolicyPage /></Restricted>} />
            <Route path="/terms-of-service" element={<Restricted><TermsPage /></Restricted>} />

            {/* Billing page */}
            <Route path="/subscription" element={<PlansAndBilling />} />
            <Route path="/subscription/payment-methods" element={<PaymentMethods />} />
            <Route path="/subscription/form" element={<StripeFormPage />} />
            <Route path="/stripe/intermediate" element={<Intermediate />} />
            <Route path="/subscription/plans" element={<PlansPage />} />
            <Route path="/welcome-page" element={<WelcomePage />} />
            <Route path="/subscription/form-payment-guest" element={<GuestForm />} />
            <Route path="/subscription/form-google" element={<LoggedinForm />} />


            {/* Notification */}
            <Route path="/subscription/cancel-success" element={<CancelSuccess />} />
            <Route path="/subscription/subscribe-success" element={<SubscribeSuccess />} />

            <Route path="/subscription/choose-paymentmethod" element={<ChoosePaymentMethod />} />
            {/* <Route path="/subscription/subscribe-successs/newDomain" element={<SubscribeSuccessful />} />
            <Route path="/subscription/subscribe-successs/existDomain" element={<ExistingDomain />} /> */}
            <Route path="/subscription/subscribe-successs/SEO-Optimization" element={<SeoOptimization />} />
            <Route path="/subscription/subscribe-successs/subscribeSuccess" element={<GuestSuccess />} />
            <Route path="/subscription/subscribe-successs/upgrade-success" element={<UpgradePlan />} />




            {/* Login page */}
            <Route path="/login" element={<LoginPage />} />

            {/* Catch-All 404 Route */}
            <Route path="*" element={<Page404 />} />
          </Routes>
          <ToastContainer position="top-right" />
        </ParallaxProvider>
      </BrowserRouter>
    </StateProvider>
  );
}

export default App;
