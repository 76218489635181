import ApiConfig from "api/apiConfig";
import AuthService from "api/services/authService";
import PaymentHelper from "helpers/PaymentHelper";
import { CheckCircle, Home, LogIn } from "lucide-react"
import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";

export default function SubscribeSuccess() {

    const navigate = useNavigate();
    const location = useLocation();
    const { statusLogin, plan_code, feature_selected } = location.state || { statusLogin: false, plan_code: '', feature_selected: '' };

    const handleAction1 = () => {
        if (statusLogin) {
            navigate('/subscription',);
        } else {
            navigate('/login',);
        }
    };
    const handleAction2 = () => {
        console.log(statusLogin)
        window.location.href = "https://" + ApiConfig.MAIN_DOMAIN
        // navigate('/welcome-page',);

        //Navigate to home
    };
    useEffect(() => {
        const userInfo = AuthService.GetCurrentUser();
        if (userInfo === undefined && statusLogin) {
            window.location.href = "https://" + ApiConfig.MAIN_DOMAIN

        }
        console.log(location.state)
        console.log(statusLogin)

    }, []);


    return (
        <div className="min-h-screen bg-gray-100 flex items-center justify-center p-4">

            <div className="w-full max-w-md bg-white rounded-lg shadow-md overflow-hidden">
                <div className="text-center p-6">
                    <div className="mx-auto mb-4 flex h-16 w-16 items-center justify-center rounded-full bg-green-100">
                        <CheckCircle className="h-10 w-10 text-green-600" />
                    </div>
                    <h2 className="text-3xl font-bold text-gray-900 mb-4">Subscribe Successful!</h2>
                    <p className="text-gray-600" style={{ fontSize: '14px' }}>

                        You have successfully subscribed to {feature_selected} in plan {PaymentHelper.getPlanName(plan_code)}.
                        <br /> Please return to the app to use advanced features.
                    </p>
                </div>
                <div className="px-6 pb-6 flex flex-col items-center space-y-4">
                    <button className="w-full flex items-center font-medium justify-center bg-blue-600 text-white px-4 py-2 rounded-md hover:bg-blue-700 transition-colors" onClick={handleAction1}>
                        {statusLogin ? 'Go to Billing page' : 'Go to Login page'}
                    </button>
                    <button className="w-full flex items-center font-medium justify-center bg-white text-blue-600 px-4 py-2 rounded-md border border-blue-600 hover:bg-blue-50 transition-colors" onClick={handleAction2}>
                        <Home className="mr-2 h-4 w-4" />
                        Back to Home page
                    </button>
                </div>
            </div>

        </div>
    )
}