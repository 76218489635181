import FullScreenImage from "components/FullScreenImage";
import { Swiper, SwiperSlide } from "swiper/react";
import "./styles.scss";
import "swiper/css";
import { Autoplay } from "swiper";
import { HeroDefaultProps } from "../HeroDefault";
import UtilityHelper from "helpers/UtilityHelper";

function HeroStandard({ site, section, imageList }: HeroDefaultProps) {
  return (
    <section
      className={`hero-container standard flex-vertical ${UtilityHelper.isPresent(imageList) ? "" : "without-img"
        } ${!section.data[0].title && !section.data[0].description
          ? "without-text"
          : ""
        }`}
    >
      {!!section.data[0].title || !!section.data[0].description ? (
        <div className={`hero-item hero-text`}>
          {!section.data[0].image ? (
            <p className="title">{site?.business_name}.</p>
          ) : null}
          <div className="content flex-vertical">
            {!!section.data[0].title && (
              <p className="header">{section?.data[0]?.title}</p>
            )}
            {!!section.data[0].description ? (
              <p className="description">{section.data[0].description}</p>
            ) : null}
          </div>
        </div>
      ) : null}
      {UtilityHelper.isPresent(imageList) ? (
        <Swiper
          autoplay={{
            delay: 2500,
            disableOnInteraction: false,
          }}
          spaceBetween={0}
          slidesPerView={1.5}
          centeredSlides={true}
          modules={[Autoplay]}
          loop={true}
          breakpoints={{
            1440: {
              slidesPerView: 2,
              spaceBetween: 0,
            },
          }}
          className="hero-swiper"
        >
          {imageList.map((image: any, index: number) => (
            <SwiperSlide key={index}>
              <div className="hero-swiper-item" key={index}>
                <img src={image.image_file_data?.image_s} />
              </div>
            </SwiperSlide>
          ))}
        </Swiper>
      ) : null}
    </section>
  );
}

export default HeroStandard;
