
class PaymentHelper {
    static getBrandName = (str: string) => {
        switch (str) {
            case 'visa':
                return 'Visa';
            case 'mastercard':
                return 'MasterCard';
            case "american_express":
                return 'American Express';
            case "diners_club":
                return 'Diner Club';
            case "discover":
                return 'Discover';
            case "jcb":
                return 'JCB';
            case "union_pay":
                return 'UnionPay';

            default:
                return 'Card';

        }
    }
    static formatDateExpiry(dateIn: number) {

        if (dateIn < 10) {
            return '0' + dateIn;

        } else {
            return dateIn;
        }
    }
    static formatDate(dateString: string) {

        const date = new Date(dateString);

        const day = String(date.getDate()).padStart(2, '0');
        const month = String(date.getMonth() + 1).padStart(2, '0');
        const year = date.getFullYear();

        return `${day}/${month}/${year}`;
    }
    static getPlanName(plan_code: string) {

        switch (plan_code) {
            case 'test-plan':
                return 'Test Plan';
            case 'free-plan':
                return 'Free Plan';
            case "B1DGS":
                return 'Beta Testers';
            default:
                return 'Test Plan';

        }
    }
}

export default PaymentHelper;