import { useEffect, useRef, useState } from 'react';
import LifeBuoyIcon from "assets/icons/lifebuoy.png";
import MastercardIcon from "assets/icons/Card logo.png";
import CB_Icon from "assets/logo.png"
import ArrowDown from "assets/icons/CaretDown.png"
import AvatarTemp from "assets/PublicProfile/hero/avatar.png"
import SuccessIcon from "assets/icons/tick-circle.png"
import UpgradePlan from "assets/icons/Arrow 77.png"
import './style.scss';
import BillingService from 'api/services/BillingService';
import LoadingSuspense from 'components/Suspense';
import { useLocation, useNavigate } from 'react-router-dom';
import UtilityHelper from 'helpers/UtilityHelper';
import AuthService from 'api/services/authService';
import CustomerService from 'api/services/CustomerService';
import { loadStripe, Stripe } from '@stripe/stripe-js';
import { Elements, PaymentElement, useStripe, useElements } from '@stripe/react-stripe-js';
import NavBarBilling from '../components/navbar/navbar';
import ColorTemple from 'styles/ColorTemple';
import React, { forwardRef, useImperativeHandle } from 'react';
import UserService from 'api/services/UserService';
import TickLogo from "assets//icons/tick-circle.png"
import ErrorLogo from "assets//icons/error-circle.png"
import SafeIcon from "assets//icons/shield.png"
import RefundLogo from "assets//icons/money-back-guarantee.png"
import NarBarWelcomeFlow from '../components/navbar/navbarSignIn';
import DateTimeHelper from 'helpers/DateTimeHelper';
import LoadingSpinner from 'components/LoadingSpinner';
import BillingHelper from 'helpers/BillingHelper';
import TrackerHelper from 'helpers/TrackerHelper';


const stripePromise = loadStripe('pk_test_qgwHJ9FIdwVHzkXxkhUwhHve');

interface PaymentMethod {
    id: string; // The ID of the existing payment method
    card: {
        brand: string;
        last4: string;
    };
}

interface Plan {
    code: string;
    name: string;
    interval: string;
    description: string;
    amount_cents: number;
    amount_currency: string;
    trial_period: number;
}

interface LocationState {
    existingPaymentMethod?: PaymentMethod;
}

type AddEditFormProps = {
    ref: any;
    email: string;
};
const AddEditForm: React.FC<AddEditFormProps> = forwardRef(({ email, }, ref,) => {
    const stripe = useStripe();
    const elements = useElements();
    const location = useLocation();
    const { planName, featureSelected, } = location.state || { planName: '', isAdding: false, featureSelected: '' };


    const handleSubmit = async (event: React.FormEvent) => {


        if (stripe && elements) {

            const result = await stripe.confirmSetup({
                elements,
                confirmParams: {
                    return_url: window.location.origin + `/stripe/intermediate?plan=${planName}&login=${false}&featureSelected=${featureSelected}&emailGuest=${email}`,
                }
            });
            if (result.error) {
                console.error('Error:', result.error.message);

                // Kiểm tra lỗi "Postal code check fails"
                if (result.error.code === 'postal_code_check_fail') {
                    console.error('Postal code verification failed.');
                }
            } else {
                console.log('Setup successful:', result.error);
            }
        }
    };


    // Dùng useImperativeHandle để expose function ra bên ngoài
    useImperativeHandle(ref, () => ({
        handleSubmit
    }));

    return (
        <PaymentElement />
    );
});

// // 

const GuestForm: React.FC = () => {
    const location = useLocation(); // Get location without type argument
    const [clientSecret, setClientSecret] = useState<string | null>(null);
    const [email, setEmail] = useState<string>('');
    const { planName, featureSelected, userEmail } = location.state || { planName: '', featureSelected: 'Custom Domain', userEmail: "" };
    const [plan, setPlan] = useState<Plan>({
        code: '',
        name: '',
        interval: '',
        description: '',
        amount_cents: 0,
        amount_currency: '',
        trial_period: 0,
    });

    const [loading, setLoading] = useState(true);
    const [loadingStripe, setLoadingStripe] = useState(false);

    const formRef = useRef<any>(null);
    const [users, setUsers] = useState<any>(null);
    const emailStatus = useState<string>('');
    const [emailExists, setEmailExists] = useState<string>('');

    const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setEmail(event.target.value);
    };

    const handleSubscribe = (event: React.FormEvent) => {
        event.preventDefault();
        if (email && emailExists !== 'not-exists' && emailExists !== '') {
            if (formRef.current) {
                formRef.current.handleSubmit();
            }
        }
    };



    const handleBlur = () => {
        setEmailExists('not-exists');
        const exists = users.some((user: { email: string }) => user.email === email.trim());
        console.log("Email tồn tại:", exists);
        if (exists) {
            setEmailExists(email.trim());
        } else {
            setEmailExists('not-exists');
        }
    };

    useEffect(() => {
        setLoadingStripe(true);
        if (emailExists !== '' && emailExists !== 'not-exists') {
            setClientSecret('');
            CustomerService.GetSetupIntent(null, emailExists).then((data) => {
                setClientSecret(data.client_secret);
                setLoadingStripe(false);
            });
        } else {
            setClientSecret('');
            setLoadingStripe(false);
        }
    }, [emailExists]);


    const fetchUsers = async () => {
        try {
            const result = await UserService.GetUsers({ id: "" });
            setUsers(result);
            console.log(result)
        } catch (error) {
            console.error("Error fetching users:", error);
        }
    };

    useEffect(() => {
        setLoading(true)
        if (userEmail) {
            setEmail(userEmail)
            const inputElement = document.getElementById("email") as HTMLInputElement;
            if (inputElement) {
                inputElement.value = userEmail;
            }
            setEmailExists(userEmail);
            CustomerService.GetSetupIntent(null, userEmail).then((data) => {
                setClientSecret(data.client_secret)

            });
        }
        fetchUsers();

        BillingService.GetPlans().then(
            (plans) => {
                console.log(plans)
                const defaultPlan = BillingHelper.getFirstPlanNotFree(plans);
                if (defaultPlan) {
                    setPlan(defaultPlan);
                } else {
                    setPlan(plans[0])
                }
                plans.forEach((e: Plan) => {
                    if (e.name === planName) {
                        setPlan(e);
                        return;
                    }
                });
                setLoading(false)
            }
        );

    }, []);

    useEffect(() => {
        TrackerHelper.virtualPage("/subscription/form-payment-guest", "Payment to upgrade plan")
    }, []);


    if (loading) {
        return <LoadingSuspense />;
    }

    return (
        <><NarBarWelcomeFlow selected_feature={featureSelected}></NarBarWelcomeFlow>

            <div className='container-main-payment'>
                <div className='title' >Finalize your order for the {plan.name}</div>
                <div className='main-content' >
                    <div className='form-container'>
                        <div className='email-section'>
                            <div className="" style={{ width: '100%' }}>
                                <label htmlFor="email" className="email-label">
                                    Enter email address
                                </label>
                                <input
                                    id="email"
                                    type="email"
                                    placeholder="your.email@example.com"
                                    required
                                    className="email-input"
                                    onChange={handleInputChange}
                                    onBlur={handleBlur}
                                    value={email}
                                    onKeyDown={(e) => {
                                        if (e.key === 'Enter') {
                                            handleBlur();
                                        }
                                    }}
                                />
                                {emailExists === '' ? <div className='email-status'> To ensure your payment is correctly linked to your account, please enter the same email address you used to sign in to the CastleBird app.
                                </div> :
                                    emailExists !== 'not-exists' ? <div className='flex flex-row justify-start items-start'>
                                        <div className='email-connected-icon'><img src={TickLogo} /></div>
                                        <div className='email-connected-text'> Email connected to User Business Name</div>
                                    </div> :
                                        <div className='flex flex-row justify-start items-start'>
                                            <div className='email-error-icon'><img src={ErrorLogo} /></div>
                                            <div className='email-error-text'>
                                                <span style={{ color: '#FF3A44' }}>Email is not connected to any CastleBird account.</span> To ensure your payment is correctly linked to your account, please enter the same email address you used to sign in to the CastleBird app. </div>
                                        </div>

                                }

                            </div>
                        </div>
                        {loadingStripe ?
                            <div className='w-full flex- ju'>
                                <div className="w-full flex justify-center items-center">
                                    <div className="animate-spin rounded-full h-10 w-10 border-4" style={{
                                        width: '24px',
                                        height: '24px',
                                        border: '2px solid #f3f3f3',
                                        borderTop: '2px solid #3498db',
                                        borderRadius: '50%',
                                        animation: 'spin 1s linear infinite'
                                    }}></div>
                                </div>
                            </div>
                            : <div></div>}
                        {emailExists && clientSecret && emailExists !== 'not-exists' ? (
                            <Elements stripe={stripePromise} options={{ clientSecret }}>
                                <AddEditForm ref={formRef} email={email} />
                            </Elements>
                        ) : <div></div>}


                    </div>
                    <div className='order-summary'>
                        <div className='order-title'>ORDER SUMMARY</div>
                        <div className='order-item' >
                            <div className='order-feature-title '>{plan.name.toUpperCase()} FEATURES:</div>
                            <div className='order-feature-item'><span style={{ fontSize: "20px" }}>• </span> Free Custom Domain</div>
                            <div className='order-feature-item'><span style={{ fontSize: "20px" }}>• </span> SEO Optimization Tools</div>
                            <div className='order-feature-item'><span style={{ fontSize: "20px" }}>• </span> Advanced Website Metrics</div>
                            <div className='order-feature-item'><span style={{ fontSize: "20px" }}>• </span> Early access to all upcoming products</div>
                        </div>

                        <div className='order-price-summary'>
                            <div>  <div style={{ fontWeight: "700", fontSize: "18px", lineHeight: "21.78px" }}>{plan.name}</div><div>${plan.amount_cents / 100} / every month</div></div>
                            <div>${plan.amount_cents / 100}</div>
                        </div>
                        <hr style={{ color: ColorTemple.grayE5, margin: "8px 0" }}></hr>

                        <div className='order-total'>
                            <div>Total due today</div>
                            <div style={{ fontWeight: "700" }}>${plan.amount_cents / 100}</div>
                        </div>
                        <div onClick={handleSubscribe} className='payment-button' style={{ color: email && emailExists !== 'not-exists' && emailExists !== '' ? 'white' : ColorTemple.gray61, backgroundColor: email && emailExists !== 'not-exists' && emailExists !== '' ? ColorTemple.foreBlue : ColorTemple.grayE5, }}>Confirm payment</div>


                        <div className='policy-text'>By purchasing, you agree to our  <a href="https://castlebird.com/terms-of-service" className="text-blue-500 underline">
                            Terms of Service
                        </a>{" "}
                            and{" "}
                            <a href="https://castlebird.com/privacy-policy" className="text-blue-500 underline">
                                Privacy Policy
                            </a>.</div>
                        <div className='policy-footer'>
                            You also agree to the auto-renewal of ${plan.amount_cents / 100} per month starting on {DateTimeHelper.getToday()}.<br></br> You can cancel anytime before then.
                        </div>
                        <div className='security-section'>
                            <div className='security-item'>
                                <div className='security-icon'><img src={SafeIcon} /></div>
                                <div className='security-text'>SAFE & SECURE<br></br>PAYMENT</div>
                            </div>
                            <div className='flex flex-row '>
                                <div className='security-icon' ><img src={RefundLogo} /></div>
                                <div className='security-text'>30 MONEY-BACK<br></br>GUARANTEE</div>
                            </div>
                        </div>
                    </div>
                </div>

            </div>

        </>
    );
};



export default GuestForm;
