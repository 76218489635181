
class DateTimeHelper {
    static formatDateString(dateString: string): string {
        const date = new Date(dateString);
        const months = [
            "January", "February", "March", "April", "May", "June",
            "July", "August", "September", "October", "November", "December"
        ];
        const day = date.getUTCDate();
        const month = months[date.getUTCMonth()];
        const year = date.getUTCFullYear();

        let daySuffix;
        if (day % 10 === 1 && day !== 11) {
            daySuffix = 'st';
        } else if (day % 10 === 2 && day !== 12) {
            daySuffix = 'nd';
        } else if (day % 10 === 3 && day !== 13) {
            daySuffix = 'rd';
        } else {
            daySuffix = 'th';
        }

        return `${month} ${day}${daySuffix} ${year}`;
    }; // Output: "February 2nd 2024"

    static convertDate(isoString: string): string {
        const date = new Date(isoString);
        const day = String(date.getUTCDate()).padStart(2, '0'); // Lấy ngày (dd)
        const month = String(date.getUTCMonth() + 1).padStart(2, '0'); // Lấy tháng (mm)
        const year = date.getUTCFullYear(); // Lấy năm (yyyy)

        return `${day}/${month}/${year}`;
    } // Output: "29/12/2024"


    static convertDateWMonth = (dateString: string) => {
        const date = new Date(dateString);
        return new Intl.DateTimeFormat('en-US', { month: 'short', day: 'numeric', year: 'numeric' }).format(date);
    };// Output: "Dec 1, 2023"


    static getToday = () => {

        const date = new Date();
        return new Intl.DateTimeFormat('en-US', { month: 'short', day: 'numeric', year: 'numeric' }).format(date);
    };// Output: "Dec 1, 2023"

}

export default DateTimeHelper;