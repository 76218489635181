import React from 'react';
import CloseIconRed from "assets/icons/close-circle-red.png";
import CloseIcon from "assets/icons/close-circle.png";
import Verify from "assets/icons/verify.png";
import { useLocation, useNavigate } from 'react-router-dom';
import BillingService from 'api/services/BillingService';
import AuthService from 'api/services/authService';

interface DialogProps {
    isOpen: boolean;
    planName: string;
    onClose: () => void;
}

const Dialog: React.FC<DialogProps> = ({ isOpen, onClose, planName, }) => {
    const navigate = useNavigate();
    const location = useLocation(); // Get location without type argument

    const clickTCancelSuccess = async () => {
        await BillingService.DelSubscription().then((data) => {
            if (data.response && data.response.status == 401) {
                AuthService.SaveCurrentUser(null);
                AuthService.SaveCurrentAccount(null);
                AuthService.Logout();
                navigate('/signin', {
                    state: { from: location.pathname, previousState: location.state, }, replace: true
                });

            } else {
                navigate(`/subscription/cancel-success`, { replace: true });

            }
        });
    };
    // const handleSwitchPlan = () => {
    //     navigate(`/subscription/plans`);
    // };


    if (!isOpen) return null;
    return (
        <div className="fixed inset-0 bg-gray-600 bg-opacity-50 flex justify-center items-center z-50">
            <div className="bg-white p-8 rounded-lg shadow-lg" style={{ width: '628px' }}>
                <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', marginBottom: '24px' }}>
                    <div style={{ display: 'flex', flexDirection: 'column' }}>
                        <h2 className="font-bold mb-2" style={{ fontSize: '24px', lineHeight: '29.04px' }}>Confirm cancellation?</h2>
                        <p style={{ fontSize: '14px', fontWeight: 500, lineHeight: '16.94px', color: '#616161' }}>We’ll be sad to see you go. Before you cancel , here's a reminder of what you'll be missing:</p>
                    </div>
                    <div style={{ cursor: 'pointer' }}>
                        <img src={CloseIcon} style={{ height: '28px', width: '28px' }} onClick={onClose} />
                    </div>
                </div>

                <div className="bg-white p-6 rounded-lg mb-6 border border-[#c4c4c4] border-solid border-1">
                    <div className='flex flex-row justify-between'>
                        <div className='flex flex-col'>
                            <div style={{ fontSize: '24px', fontWeight: 600, lineHeight: '28.8px', color: 'black', marginBottom: '4px' }}>Switch to Free plan</div>
                            <div style={{ fontSize: '14px', fontWeight: 500, lineHeight: '21px', color: 'black' }}>Launch your business on the web</div>
                        </div>
                        <div className='flex flex-row align-middle'>
                            <div style={{ fontSize: '48px', fontWeight: 600, lineHeight: '48px', color: 'black' }}>
                                $0
                            </div>
                            <div style={{ fontSize: '14px', fontWeight: 500, lineHeight: '19.6px', color: '#616161', alignContent: 'center', marginLeft: '8px' }}>per month,<br />permantly</div>
                        </div>
                    </div>
                    <hr className='px-6'></hr>

                    <ul className="list-none">
                        <li className="flex items-center pb-3">
                            <div><img src={CloseIconRed} alt="icon" style={{ height: '24px', width: '24px', }} className="mr-3" /></div>
                            <div style={{ fontSize: '14px', fontWeight: 500, lineHeight: '21px', color: 'black', alignContent: 'center', }}>
                                Free Custom Domain
                            </div>
                        </li>
                        <li className="flex items-center pb-3">
                            <div><img src={CloseIconRed} alt="icon" style={{ height: '24px', width: '24px', }} className="mr-3" /></div>
                            <div style={{ fontSize: '14px', fontWeight: 500, lineHeight: '21px', color: 'black', alignContent: 'center', }}>
                                SEO Optimization Tools
                            </div>
                        </li>

                        <li className="flex items-center pb-3">
                            <div><img src={CloseIconRed} alt="icon" style={{ height: '24px', width: '24px', }} className="mr-3" /></div>
                            <div style={{ fontSize: '14px', fontWeight: 500, lineHeight: '21px', color: 'black', alignContent: 'center', }}>
                                Advanced Website Metrics
                            </div>
                        </li>
                        <li className="flex items-center">
                            <div><img src={CloseIconRed} alt="icon" style={{ height: '24px', width: '24px', }} className="mr-3" /></div>
                            <div style={{ fontSize: '14px', fontWeight: 500, lineHeight: '21px', color: 'black', alignContent: 'center', }}>
                                Early access to all upcoming products
                            </div>
                        </li>
                    </ul>
                </div>

                <div className="flex justify-end">
                    <button className="bg-white rounded-lg border border-[#c4c4c4] border-solid border-1 px-4 py-2 mr-4 text-black font-medium" onClick={onClose}>
                        Stay on {planName}
                    </button>
                    <button className="bg-red-500 text-white px-4 py-2 font-semibold rounded-lg" onClick={clickTCancelSuccess}>
                        Confirm cancellation
                    </button>
                </div>
            </div>
        </div>
    );
};

export default Dialog;
