
interface Plan {
    code: string;
    name: string;
    interval: string;
    description: string;
    amount_cents: number;
    amount_currency: string;
    trial_period: number;
}

class BillingHelper {
    static getPlanName(planCode: string): string {
        return planCode
            .split('-')
            .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
            .join(' ');
    }; //Input:"test-plan", Output:"Test Plan"

    static getFirstPlanNotFree(plans: Plan[]): Plan | undefined {
        return plans.find(plan => plan.code !== 'free-plan');
    }
}

export default BillingHelper;