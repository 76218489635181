import React, { useEffect, useRef, useState } from 'react';
import { Card } from 'primereact/card';
import { IconField } from 'primereact/iconfield';
import { InputTextarea } from 'primereact/inputtextarea';
import { Button } from 'primereact/button';
import WebsiteService from 'api/services/SiteService';
import LocationHelper from 'helpers/LocationHelper';
import TrackerHelper from 'helpers/TrackerHelper';

interface Step1Props {
  setBlogTopic : (topic: string | null) => void;
}

const Step1: React.FC<Step1Props> = ({setBlogTopic}) => {
  const [search, setSearch] = useState<string>('');
  const [loading,setLoading] = useState<boolean>(true);
  const [location, setLocation] = useState<string>('');
  const [showTagContainer, setShowTagContainer] = useState<boolean>(false);
  const [tags, setTags] = useState<string[]>([]);
  const tagContainerRef = useRef<HTMLDivElement>(null);
  const [blurOutside, setBlurOutside] = useState<boolean>(false);
  const [debouncedSearch, setDebouncedSearch] = useState<string>(search);

  const handleSearchChange = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
    setShowTagContainer(true);
    setSearch(event.target.value);
    if(event.target.value.trim()) {
      setBlogTopic(event.target.value);
    } else {
      setBlogTopic(null);
    }
  };

  useEffect(() => {
    const handler = setTimeout(() => {
      setDebouncedSearch(search);
    }, 300); 

    return () => {
      clearTimeout(handler);
    };
  }, [search]); 

  useEffect(() => {
    const fetchSuggestions = async () => {
      if (debouncedSearch || location) {
        const response = await WebsiteService.getAiContent({
          ai_action: 'suggested_blog_title_guest',
          hint: debouncedSearch,
          location: location,
        });
        if(response) {
          setLoading(false);
          setTags(response.tags);
        }
      }
    };
  
    fetchSuggestions();
  }, [debouncedSearch, location]);
  

  useEffect(() => {
    const fetchLocation = async () => {
      const locationData = await LocationHelper.getLocation();
      if (locationData) {
        setLocation(locationData.cityCountry);
      }
    };

    fetchLocation();

    TrackerHelper.virtualPage("/ai-blog-onboarding/step_blog_topic", "Blog-onboarding - Blog Topic");
  }, []);

  const handleTagClick = (tag: string) => {
    setSearch(tag);
    setBlogTopic(tag)
    setShowTagContainer(false);
  };

  const handleClickOutside = (event: MouseEvent) => {
    if (
      tagContainerRef.current &&
      !tagContainerRef.current.contains(event.target as Node) &&
      !document.activeElement?.matches('.input-text-full') 
    ) {
      setShowTagContainer(false);
      document.removeEventListener('click', handleClickOutside);
    }
  };

  useEffect(() => {
    document.addEventListener('click', handleClickOutside);

    return () => document.removeEventListener('click', handleClickOutside);
  }, [blurOutside]);

  console.log(tags)
  return (
    <>
        <div className="header">
          <h3 className='title' style={{marginBottom:'18px'}}>What topic are you going to write about?</h3>
        </div>

        <IconField iconPosition="left" className="full-width-input mb-3">
          <InputTextarea
            placeholder="Enter any topic"
            value={search}
            onChange={handleSearchChange}
            onFocus={() => setShowTagContainer(true)}
            onBlur={() => setBlurOutside(!blurOutside)}
            className="input-text-full"
            style={{ color: 'black', fontWeight: '600' }}
          />
        </IconField>

        {showTagContainer && (
          <div className="tag-container" ref={tagContainerRef} style={{minHeight:"200px"}}>
            {loading && <p className="p-6">Getting suggestion...</p>}
            {tags?.map((tag, index) => (
              <p
              key={index}
              className="p-button-text md:text-lg"
              onClick={() => handleTagClick(tag)}
              > {tag}
              </p>
            ))}
          </div>
        )}
       
      </>
   
  );
};

export default Step1;
