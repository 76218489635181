import { useState } from "react";
import { Theme } from "pages/Home";
import "./styles.scss";
import { InfoProps } from "../BioLeft";

function BioStandard({ section, website }: Partial<InfoProps>) {
  return (
    <div className="bio-container standard flex-vertical">
      <section className="bio-content flex-vertical">
        <p className="title">About {website.business_name}</p>
        <div className="content flex-vertical">
          {!!section.data[0].header ? (
            <div className="header-item">
              <p className="header">{section.data[0].header}</p>
            </div>
          ) : null}

          {!!section.data[0].description ? (
            <div
              className={`des-items ${!section.data[0].header ? "without-header" : ""
                } flex-vertical`}
            >
              <p className="description">{section.data[0].description}</p>
            </div>
          ) : null}
        </div>
      </section>
    </div>
  );
}

export default BioStandard;
