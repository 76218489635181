const ColorTemple = {
    backBlue: '#E0EFFF',
    foreBlue: '#0B86E7',
    foreRed: '#B82C2C',
    backGreen: '#F0FDF6',
    foreGreen: '#32A071',
    backYellow: '#FFF0C9',
    foreYellow: '#FF8A00',
    grayE5: '#E5E5E5',
    gray61: '#616161',
    grayF2: '#F2F2F2',
};

export default ColorTemple;
