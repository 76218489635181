import { useEffect, useState } from 'react';
import LifeBuoyIcon from "assets/icons/lifebuoy.png";
import MastercardIcon from "assets/icons/Card logo.png";
import CB_Icon from "assets/logo.png"
import ArrowDown from "assets/icons/CaretDown.png"
import AvatarTemp from "assets/PublicProfile/hero/avatar.png"
import SuccessIcon from "assets/icons/tick-circle.png"
import UpgradePlan from "assets/icons/Arrow 77.png"
import './style.scss';
import BillingService from 'api/services/BillingService';
import LoadingSuspense from 'components/Suspense';
import DialogCancellation from './components/DialogConfirmCancel';
import { useLocation, useNavigate } from 'react-router-dom';
import UtilityHelper from 'helpers/UtilityHelper';
import AuthService from 'api/services/authService';
import CustomerService from 'api/services/CustomerService';
import { loadStripe, Stripe } from '@stripe/stripe-js';
import { Elements, PaymentElement, useStripe, useElements } from '@stripe/react-stripe-js';
import NavBarBilling from './components/navbar/navbar';

const stripePromise = loadStripe('pk_test_qgwHJ9FIdwVHzkXxkhUwhHve');

interface PaymentMethod {
    id: string; // The ID of the existing payment method
    card: {
        brand: string;
        last4: string;
    };
}


interface LocationState {
    existingPaymentMethod?: PaymentMethod;
}

const AddEditForm: React.FC<{ existingPaymentMethod?: PaymentMethod }> = ({ existingPaymentMethod }) => {
    const stripe = useStripe();
    const elements = useElements();
    const location = useLocation();

    const { statusLogin, plan_code, isAdding, feature_selected } = location.state || { statusLogin: false, plan_code: 'test-plan', isAdding: false, feature_selected: '' };

    const handleSubmit = async (event: React.FormEvent) => {
        event.preventDefault();
        if (stripe && elements) {
            const result = await stripe.confirmSetup({
                elements,
                confirmParams: {
                    return_url: window.location.origin + `/stripe/intermediate?isUpdated=${true}`
                }
            });
            // console.log(result)
            // if (result.error) {
            //     console.error(result.error.message);
            // } else {
            //     BillingService.SignUpPlan(plan_code)
            // }
            if (result.error) {
                console.error('Error:', result.error.message);

                // Kiểm tra lỗi "Postal code check fails"
                if (result.error.code === 'postal_code_check_fail') {
                    console.error('Postal code verification failed.');
                }
            } else {
                console.log('Setup successful:', result.error);
            }
        }
    };
    useEffect(() => {
        // const userInfo = AuthService.GetCurrentUser();
        // if (userInfo === undefined && statusLogin === false) {
        //     window.location.href = 'https://castlebird.com/'

        // }
    }, []);

    return (
        <form onSubmit={handleSubmit} style={{
            display: 'flex',
            flexDirection: 'column',
            gap: '1em',
            width: '581px',
            backgroundColor: 'white',
            borderRadius: "16px",
            boxShadow: "0px 4px 50px 0px #0000001A",
            padding: "32px",
        }}>
            <PaymentElement />
            <button
                type="submit"
                style={{
                    marginTop: '1em',
                    padding: '0.5em 1em',
                    fontSize: '1rem',
                    backgroundColor: '#0070f3',
                    color: '#fff',
                    border: 'none',
                    borderRadius: '4px',
                    cursor: 'pointer',
                }}
            >
                {existingPaymentMethod ? 'Update Payment Method' : !statusLogin ? "Pay & Upgrade My Account" : 'Save Payment Method'}
            </button>
        </form >
    );
};

const StripeFormPage: React.FC = () => {
    const location = useLocation(); // Get location without type argument
    const state = location.state as LocationState; // Cast state to your interface
    const existingPaymentMethod = state?.existingPaymentMethod;

    const [clientSecret, setClientSecret] = useState<string | null>(null);

    const navigate = useNavigate();
    useEffect(() => {
        CustomerService.GetSetupIntent(existingPaymentMethod ? existingPaymentMethod?.id : null).then((data) => {
            if (data.response && (data.response.status == 401 || data.response.status == 500)) {
                AuthService.SaveCurrentUser(null);
                AuthService.SaveCurrentAccount(null);
                AuthService.Logout();
                navigate('/signin', {
                    state: { from: location.pathname, previousState: location.state, }, replace: true
                });
            } else {
                setClientSecret(data.client_secret)
            }
        });
    }, []);


    if (!clientSecret) {
        return <LoadingSuspense />;
    }

    return (
        <>
            <NavBarBilling />
            <Elements stripe={stripePromise} options={{ clientSecret }}>
                <div className='items-center flex flex-col' style={{ height: "100vh" }}>
                    <h2 className='mt-5'>{existingPaymentMethod ? 'Update Payment Method' : 'Add New Payment Method'}</h2>
                    <AddEditForm existingPaymentMethod={existingPaymentMethod} />
                </div>
            </Elements>

        </>
    );
};

export default StripeFormPage;