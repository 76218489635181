import { useEffect, useRef, useState } from 'react';
import BillingService from 'api/services/BillingService';
import LoadingSuspense from 'components/Suspense';
import { useLocation, useNavigate } from 'react-router-dom';
import ColorTemple from 'styles/ColorTemple';
import React, { forwardRef, useImperativeHandle } from 'react';
import CastleBirdLogo from "assets/logo.png"
import TickLogo from "assets//icons/tick-circle.png"
import TickBlueLogo from "assets//icons/tick-circle-blue.png"
import NarBarWelcomeFlow from '../../components/navbar/navbarSignIn';
import "./style.scss"

const UpgradePlan: React.FC = () => {
    const location = useLocation();
    const { statusLogin, planName, } = location.state || { statusLogin: false, planName: 'Starter Plan', };

    const navigate = useNavigate();

    const handleGotoMyAccount = () => {
        if (statusLogin) { navigate('/subscription',); } else {
            navigate('/signin',);
        }

    }
    const isMobile = /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent);


    const handleOpenStore = (isAndroid: boolean) => {

        if (isAndroid) {
            // Điều hướng đến Play Store nếu là Android
            window.location.href = 'https://play.google.com/store/apps/details?id=com.castlebird.castleapp';
        } else {
            window.open("https://apps.apple.com/us/app/castlebird/id6449547159")
        }
    };

    const handleInstallApp = async () => {
        const userAgent = navigator.userAgent || navigator.vendor;

        if (/android/i.test(userAgent)) {
            window.location.href = 'https://play.google.com/store/apps/details?id=com.castlebird.castleapp';
        } else if (/iPad|iPhone/.test(userAgent)) {
            console.log('iOS');
            window.open("https://apps.apple.com/us/app/castlebird/id6449547159")
        } else {
            navigate('/download', {state: {from: 'subscription'}});
        }
    };

    useEffect(() => {
        console.log(statusLogin)
        if (isMobile) {
            console.log("Người dùng đang sử dụng điện thoại di động.");
        } else {
            console.log("Người dùng đang sử dụng PC hoặc laptop.");
        }

    }, []);

    return (
        <div className='container-guest-success'>
            <NarBarWelcomeFlow></NarBarWelcomeFlow>
            <div className='content-section-guest-success'>
                < div className='welcome-content-guest-success' style={{ height: '100vh' }}>
                    <div style={{ width: '72px', height: '72px', marginBottom: "8px", }}><img src={TickLogo} /></div>
                    <div style={{ fontWeight: "700", fontSize: '32px', lineHeight: '44.8px', textAlign: 'center', color: 'black' }}>Welcome to {planName}</div>
                    <div style={{ fontWeight: "600", fontSize: '16px', lineHeight: '24px', textAlign: 'center', color: ColorTemple.gray61, }}>Check your email for the invoice and start exploring the app.</div>
                    <button onClick={handleInstallApp} className="open-app-button" style={{ padding: "12px 16px", background: "#2318DD", fontSize: '16px', fontWeight: '500', borderRadius: '4px', marginTop: '16px', marginBottom: '20px' }}>View in app</button>
                    <div className='whats-next-card-guest-success'>
                        <div style={{ fontWeight: "700", fontSize: "20px", lineHeight: "26px", color: 'black', marginBottom: "8px" }}>Experience more of our premium features designed for your business!</div>
                        <div style={{ fontWeight: "500", fontSize: "14px", lineHeight: "21px", color: ColorTemple.gray61, marginBottom: "32px" }}>Make sure to download the latest version of the CastleBird app on <span onClick={() => { handleOpenStore(false) }} style={{ color: "black", cursor: "pointer" }} >App store</span> or <span style={{ color: "black", cursor: "pointer" }} onClick={() => { handleOpenStore(true) }}>Play store</span> to get all your new benefits</div>
                        <div className='flex flex-row' style={{ marginBottom: "16px", }}>

                            <div><img src={TickBlueLogo} style={{ height: '24px', width: "24px", }}></img></div>
                            <div style={{ fontWeight: "600", fontSize: "14px", lineHeight: "25.2px", color: "black", marginLeft: "16px", }}>Free Custom Domain</div>


                        </div>
                        <div className='flex flex-row' style={{ marginBottom: "16px", }}>
                            <div> <img src={TickBlueLogo} style={{ height: '24px', width: "24px" }}></img></div>
                            <div style={{ fontWeight: "600", fontSize: "14px", lineHeight: "25.2px", color: "black", marginLeft: "16px" }}>SEO Optimization Tools</div>


                        </div>
                        <div className='flex flex-row' style={{ marginBottom: "16px" }}>
                            <div> <img src={TickBlueLogo} style={{ height: '24px', width: "24px" }}></img></div>

                            <div style={{ fontWeight: "600", fontSize: "14px", lineHeight: "25.2px", color: "black", marginLeft: "16px" }}>Advanced Website Metrics</div>


                        </div>
                        <div className='flex flex-row'>
                            <div> <img src={TickBlueLogo} style={{ height: '24px', width: "24px" }}></img></div>


                            <div style={{ fontWeight: "600", fontSize: "14px", lineHeight: "25.2px", color: "black", marginLeft: "16px" }}>Early access to all upcoming products</div>


                        </div>
                    </div>
                    <div className='font-medium text-center text-sm' style={{ lineHeight: '19.6px', margin: "0 16px", marginTop: "32px" }}>View your plan info, receipts or cancel anytime
                        on your CastleBird Account Page. <br />
                        <a onClick={() => { handleGotoMyAccount() }} style={{ textDecoration: "underline", cursor: 'pointer', userSelect: "none" }}>{statusLogin ? 'Go to my Account ' : 'Back to Login'}</a></div>


                </div >
            </div >

        </div >

    );
};



export default UpgradePlan;

