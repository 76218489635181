import React, { useEffect, useState } from 'react';
import CloseIcon from 'assets/Navbar/close.png'
import NavBarBilling from './components/navbar/navbar';
import CustomerService from 'api/services/CustomerService';
interface PaymentMethodProps {
    id: number;
    cardType: string;
    cardNumber: string;
    expiryDate: string;
    isDefault: boolean;
}

interface PaymentMethod {
    id: string;
    card: {
        last4: string;
        exp_month: number;
        exp_year: number;
    };
}

const PaymentMethod: React.FC<PaymentMethodProps & { onMakeDefault: () => void; onDelete: () => void; isMenuOpen: boolean; onToggleMenu: () => void }> = ({
    cardType,
    cardNumber,
    expiryDate,
    isDefault,
    onMakeDefault,
    onDelete,
    isMenuOpen,
    onToggleMenu,
}) => {
    return (
        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', padding: '10px 0', borderBottom: '1px solid #eaeaea', position: 'relative' }}>
            <div style={{ display: 'flex', alignItems: 'center' }}>
                <div style={{ backgroundColor: '#00509e', color: 'white', padding: '5px 8px', borderRadius: '4px', fontSize: '12px', marginRight: '10px' }}>
                    {cardType}
                </div>
                <span>•••• {cardNumber}</span>
                {isDefault && (
                    <span style={{ backgroundColor: '#e0e0e0', color: '#555', padding: '0px 6px', borderRadius: '4px', fontSize: '12px', fontWeight: '600', marginLeft: '10px' }}>
                        Default
                    </span>
                )}
            </div>
            <div style={{ display: 'flex', alignItems: 'center', position: 'relative' }}>
                <span style={{ marginRight: '20px' }}>Expiry on {expiryDate}</span>
                {isDefault ? (
                    <button
                        onClick={onToggleMenu}
                        className="delete-button"
                    >
                        <img src={CloseIcon} style={{ height: '15px', width: '15px' }} className='icon-delete' />
                    </button>
                ) : (<button
                    onClick={onToggleMenu}
                    className="more-button"

                >
                    <div >•••</div>
                </button>)}
                {isMenuOpen && (
                    <div
                        style={{
                            position: 'absolute',
                            top: '100%',
                            right: '12px',
                            backgroundColor: 'white',
                            border: '1px solid #eaeaea',
                            borderRadius: '8px',
                            boxShadow: '0px 4px 8px rgba(0,0,0,0.1)',
                            padding: '10px',
                            zIndex: 1,
                            minWidth: '120px',
                        }}
                    >
                        <div
                            style={{ cursor: 'pointer', padding: '5px 10px', fontSize: '14px', fontWeight: '600' }}
                            onClick={() => {
                                onMakeDefault();
                            }}
                        >
                            Make as default
                        </div>
                        <div
                            style={{ cursor: 'pointer', padding: '5px 10px', fontSize: '14px', color: 'red', fontWeight: '600' }}
                            onClick={() => {
                                onDelete();
                            }}
                        >
                            Delete
                        </div>
                    </div>
                )}
            </div>
        </div>
    );
};

const DeleteConfirmationDialog: React.FC<{ onClose: () => void; onConfirm: () => void; cardType: string; cardNumber: string; expiryDate: string }> = ({
    onClose,
    onConfirm,
    cardType,
    cardNumber,
    expiryDate,
}) => {
    return (
        <div style={{
            position: 'fixed',
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            backgroundColor: 'rgba(0,0,0,0.5)',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            zIndex: 1000
        }}>
            <div style={{
                backgroundColor: 'white',
                padding: '20px',
                borderRadius: '8px',
                width: '400px',
                boxShadow: '0px 4px 8px rgba(0,0,0,0.2)',
            }}>
                <h3 style={{ fontSize: '18px', fontWeight: 'bold', marginBottom: '10px' }}>Confirm delete payment method</h3>
                <p>This will permanently delete your payment method.</p>
                <div style={{ display: 'flex', alignItems: 'center', margin: '20px 0' }}>
                    <div style={{ backgroundColor: '#00509e', color: 'white', padding: '5px 8px', borderRadius: '4px', fontSize: '12px', marginRight: '10px' }}>
                        {cardType}
                    </div>
                    <span>•••• {cardNumber}</span>
                    <span style={{ marginLeft: 'auto' }}>Expiry on {expiryDate}</span>
                </div>
                <div style={{ display: 'flex', justifyContent: 'flex-end', gap: '10px' }}>
                    <button onClick={onClose} style={{ padding: '8px 12px', backgroundColor: '#e0e0e0', borderRadius: '4px', cursor: 'pointer' }}>Cancel</button>
                    <button onClick={onConfirm} style={{ padding: '8px 12px', backgroundColor: '#e53935', color: 'white', borderRadius: '4px', cursor: 'pointer' }}>Delete payment method</button>
                </div>
            </div>
        </div>
    );
};

const PaymentMethods = () => {
    const [paymentMethods, setPaymentMethods] = useState<PaymentMethodProps[]>([]);

    const [selectedCard, setSelectedCard] = useState<null | PaymentMethodProps>(null);
    const [openMenuId, setOpenMenuId] = useState<number | null>(null);
    const [paymentMethod, setPaymentMethod] = useState<PaymentMethod | null>(null);

    const makeDefault = (id: number) => {
        setPaymentMethods((prev) =>
            prev.map((method) => ({ ...method, isDefault: method.id === id }))
        );
        setOpenMenuId(null)
    };

    const openDeleteDialog = (method: PaymentMethodProps) => {
        setSelectedCard(method);
    };

    const confirmDelete = () => {
        setPaymentMethods((prev) => prev.filter((method) => method.id !== selectedCard?.id));
        setSelectedCard(null);
    };

    const closeDialog = () => {
        setSelectedCard(null);
    };

    useEffect(() => {

        CustomerService.GetPaymentMethods().then((data) => {
            console.log(data)

            const newPaymentMethods = [...paymentMethods];

            data.forEach((item: any, index: number) => {
                const isDefault = newPaymentMethods.length === 0 && index === 0;
                if (item['type'] === 'sepa_debit') {
                    console.log({ id: item['id'], cardType: item['type'], cardNumber: item['sepa_debit']['last4'], expiryDate: "", isDefault: false });

                    newPaymentMethods.push({
                        id: item['id'],
                        cardType: item['type'],
                        cardNumber: item['sepa_debit']['last4'],
                        expiryDate: "",
                        isDefault: isDefault
                    });
                } else {
                    console.log({ id: item['id'], cardType: item['type'], cardNumber: item['card']['last4'], expiryDate: item['card']['exp_month'] + "/" + item['card']['exp_year'], isDefault: false });

                    newPaymentMethods.push({
                        id: item['id'],
                        cardType: item['card']['brand'],
                        cardNumber: item['card']['last4'],
                        expiryDate: item['card']['exp_month'] + "/" + item['card']['exp_year'],
                        isDefault: isDefault
                    });
                }
            });
            setPaymentMethods(newPaymentMethods);
            console.log(paymentMethods)

            setPaymentMethod(data[1] || null);
        });
    }, []);

    return (
        <div style={{ backgroundColor: '#F9FAFC', height: "100%" }}>
            <NavBarBilling></NavBarBilling>

            <div style={{ width: '400px', margin: '20px auto', fontFamily: 'Arial, sans-serif' }}>
                <h3 style={{ fontSize: '16px', fontWeight: 'bold', marginBottom: '20px' }}>PAYMENT METHOD</h3>
                {paymentMethods.map((method) => (
                    <PaymentMethod
                        key={method.id}
                        {...method}
                        isMenuOpen={openMenuId === method.id}
                        onToggleMenu={() => setOpenMenuId(openMenuId === method.id ? null : method.id)}
                        onMakeDefault={() => makeDefault(method.id)}
                        onDelete={() => openDeleteDialog(method)}
                    />
                ))}
                {selectedCard && (
                    <DeleteConfirmationDialog
                        onClose={closeDialog}
                        onConfirm={confirmDelete}
                        cardType={selectedCard.cardType}
                        cardNumber={selectedCard.cardNumber}
                        expiryDate={selectedCard.expiryDate}
                    />
                )}
                {/* <div style={{ color: '#007bff', cursor: 'pointer', paddingTop: '24px' }}>
                    + Thêm phương thức thanh toán
                </div> */}
                <div style={{ height: '700px' }}></div>
            </div>
        </div>
    );
};

export default PaymentMethods;
