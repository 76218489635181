import CB_Icon from "assets/logo.png"
import ArrowDown from "assets/icons/CaretDown.png"
import DownloadIcon from "assets/icons/download.png"
import './style.scss';
import AvatarTemp from "assets/PublicProfile/hero/avatar.png"
import { useContext, useEffect, useRef, useState } from 'react';
import BillingService from "api/services/BillingService";
import AuthService from "api/services/authService";
import ColorTemple from "styles/ColorTemple";
import CardIcon from "assets/icons/cards.png";
import KeyBoardOpen from "assets/icons/keyboard-open.png";
import { useNavigate } from "react-router-dom";
import AccountService from "api/services/AccountService";
import UtilityHelper from "helpers/UtilityHelper";
import ApiConfig from "api/apiConfig";
import { StateContext } from "store/Provider";
import WebsiteService from "api/services/SiteService";
import ImageWithLoader from 'components/LoadingSpinner';


interface Subscription {
    plan: {
        name: string;
        amount_cents: number;
    };
    ending_at: string;
}
function NavBarBilling() {
    // const [subscription, setSubscription] = useState<Subscription | undefined>(undefined);
    const [isOpenMenuDesktop, setOpenMenuDesktop] = useState(false);
    const [isOpenMenuMobile, setOpenMenuMobile] = useState(false);
    const [linkWebsiteDomain, setLinkWebsiteDomain] = useState('');

    // const [isPresentWeb, setPresentWeb] = useState(false);
    const navigate = useNavigate();
    const [isFreePlan, setIsFreePlan] = useState(false);
    const menuRef = useRef<HTMLDivElement>(null);
    const userInfo = AuthService.GetCurrentUser();
    const { state, dispatch } = useContext(StateContext);

    const [isImageLoaded, setIsImageLoaded] = useState(false);

    const handleClickOutside = (event: any) => {
        if (menuRef.current && !menuRef.current.contains(event.target as Node)) {
            setOpenMenuMobile(false);
            setOpenMenuDesktop(false);
        }
    };
    const isMobile = /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent);

    useEffect(() => {
        if (userInfo === undefined) {
            navigate('/signin', { replace: true })
        } else {
            setImgSrc(userInfo.avatar_url ?? AvatarTemp)
            BillingService.GetSubscription().then(
                (sub) => {
                    if (!sub || sub.plan.name == "Free Plan" || UtilityHelper.isPresent(sub.ending_at)) {
                        setIsFreePlan(true);
                    }
                }
            );
            var user = AuthService.GetCurrentUser();
            WebsiteService.GetWebsite({ account_id: user.account_id }).then((e) => {
                setLinkWebsiteDomain(WebsiteService.getSiteDomain(e[0]))
            });
            setIsImageLoaded(true)
        }

    }, []);
    const handleScroll = () => {
        setOpenMenuMobile(false);
        setOpenMenuDesktop(false);
    };
    const handleOpenTab = () => {
        if (isMobile) {
            setOpenMenuMobile((prev) => !prev);
        } else {
            setOpenMenuDesktop((prev) => !prev);
        }

    }
    const [imgSrc, setImgSrc] = useState('');

    const openEmailClient = () => {
        const recipientEmail = "chat@castlebird.com"; // Địa chỉ email nhận
        const subject = encodeURIComponent("Support with Billing"); // Tiêu đề email
        const body = encodeURIComponent("Hi CastleBird,"); // Nội dung email

        const mailtoLink = `mailto:${recipientEmail}?subject=${subject}&body=${body}`;

        // Mở ứng dụng email
        window.location.href = mailtoLink;
    };
    const handleLogout = () => {
        AuthService.SaveCurrentUser(null);
        AuthService.SaveCurrentAccount(null);
        AuthService.Logout();

        navigate('/signin', { replace: true })

    }
    const handleInstallApp = async () => {
        const userAgent = navigator.userAgent || navigator.vendor;

        if (/android/i.test(userAgent)) {
            window.location.href = 'https://play.google.com/store/apps/details?id=com.castlebird.castleapp';
        } else if (/iPad|iPhone/.test(userAgent)) {
            console.log('iOS');
            window.open("https://apps.apple.com/us/app/castlebird/id6449547159")
        } else {
            navigate('/download?type=website');
        }
    };
    const handleExplorePlans = () => {
        navigate(`/subscription/plans`);
    };
    const handleMyBilling = () => {
        navigate(`/subscription`);
    };
    useEffect(() => {

        document.addEventListener("mousedown", handleClickOutside);
        window.addEventListener("scroll", handleScroll);
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
            window.removeEventListener("scroll", handleScroll);
        };
    }, []);

    return (
        <div className="navbar-billing">
            <div className="flex-1 text-center items-center cursor-pointer" onClick={handleMyBilling}> <img src={CB_Icon} alt="CastleBird Logo" style={{ height: "31px", width: "144px" }} />
            </div>
            {/* <div className=" flex-1 text-center flex justify-center items-center flex-row">
                    <div onClick={handleInstallApp} className="mr-8 font-medium text-base text-black cursor-pointer">Try AI Website builder</div>
                    <div className="font-semibold text-base cursor-pointer" style={{ color: ColorTemple.foreBlue, }}>Plan & Billings</div>
                </div> */}


            <div className=" flex-1 text-center items-center justify-end flex flex-row">
                {
                    isFreePlan && isMobile == false ? <div className="help-button px-4 py-2" onClick={handleExplorePlans} style={{ border: 'none', borderRadius: '8px', backgroundColor: "#0B86E7", marginRight: '16px', }} >
                        <div style={{ fontWeight: '600', fontSize: '14px', margin: 0, color: 'white' }} onClick={handleExplorePlans}>Upgrade plan</div>
                    </div> : <></>
                }

                <div className="flex flex-row items-center cursor-pointer mr-4 hover:bg-gray-200 px-2 py-1 rounded-md">
                    <div> {
                        isMobile ? <></> :
                            <img
                                src={DownloadIcon}
                                loading="lazy"
                                style={{
                                    marginLeft: '0px', marginRight: '8px', height: '17px', width: '17px', display: 'block',
                                }}
                            />
                    }
                    </div>
                    <h5 style={{ fontWeight: 'w600', fontSize: '14', margin: 0, color: 'black' }} onClick={handleInstallApp}>
                        {isMobile ? 'Open app' : 'Install app'}
                    </h5>
                </div>
                <div className="flex flex-row items-center cursor-pointer hover:bg-gray-200 px-2 py-1 rounded-md" onClick={handleOpenTab}>
                    <div style={{ position: "relative", display: "inline-block" }}>
                        <img
                            src={imgSrc}
                            loading="lazy"
                            onLoad={() => setIsImageLoaded(true)} // Khi ảnh load xong, cập nhật state
                            onError={() => {
                                console.log('aaaaaaaaaaa');
                                setImgSrc(AvatarTemp); // Nếu lỗi, thay bằng ảnh mặc định
                                setIsImageLoaded(true);
                            }}
                            style={{
                                height: "32px",
                                width: "32px",
                                borderRadius: "50%",
                                display: isImageLoaded ? "block" : "none", // Ẩn ảnh cho đến khi load xong
                            }}
                        />
                        {!isImageLoaded && (
                            <img
                                src={AvatarTemp}
                                style={{
                                    height: "32px",
                                    width: "32px",
                                    borderRadius: "50%",
                                    position: "absolute",
                                    top: 0,
                                    left: 0,
                                }}
                            />
                        )}
                    </div>
                    <div className="ml-3"> <img
                        src={ArrowDown}
                        style={{
                            marginLeft: '0px', marginRight: '8px', height: '12px', width: '12x', display: 'block',
                        }}
                    /></div>
                    {isOpenMenuDesktop && (
                        <div ref={menuRef} className="absolute right-0 rounded-lg shadow-lg ring-1 ring-black ring-opacity-5 bg-white" style={{ marginTop: '350px', marginRight: '54px', zIndex: 1000, paddingBottom: '4px', paddingTop: "4px", }}>
                            <div className="text-left flex flex-row items-center" style={{ margin: "0px", padding: '20px' }}>
                                <div className="pr-4">
                                    <h4 className="text-base font-bold ">{userInfo.first_name ?? ""} {userInfo.last_name ?? ""}</h4>
                                    <p className="text-sm font-medium" style={{ color: ColorTemple.gray61 }}>{userInfo.email ?? ""}</p>
                                </div>
                                <div> <img src={userInfo.avatar_url ?? AvatarTemp} loading="lazy" style={{ height: "32px", width: "32px", borderRadius: "50%", }} /></div>

                            </div>
                            <hr className="m-0"></hr>
                            <div className="p-2 text-left">
                                <a
                                    href={linkWebsiteDomain}
                                    className="flex items-center space-x-2 px-2 py-2 hover:bg-gray-100 rounded-lg text-sm text-black"
                                >
                                    <div> <img src={KeyBoardOpen} style={{ height: "23px", width: "23px", }} /></div>
                                    <div className="font-medium text-sm text-black">My Website</div>
                                </a>
                                <a
                                    onClick={handleMyBilling}
                                    className="flex items-center space-x-2 px-2 py-2 hover:bg-gray-100 rounded-lg text-sm text-black"
                                >
                                    <div> <img src={CardIcon} style={{ height: "23px", width: "23px", }} /></div>
                                    <div className="font-medium text-sm text-black">My Plan & Billings</div>
                                </a>
                            </div>
                            <hr className="m-0"></hr>
                            <div className="text-left" style={{ marginTop: '10px', color: '#292D32', fontSize: '13px', fontWeight: "500" }} >

                                <div
                                    className="block hover:bg-gray-100 rounded-lg"
                                    style={{ padding: "10px 20px " }}
                                    onClick={openEmailClient}
                                >
                                    Support
                                </div>
                                <div
                                    className="block hover:bg-gray-100 rounded-lg"
                                    style={{ padding: "10px 20px " }}
                                    onClick={handleLogout}
                                >
                                    Logout
                                </div>
                            </div>
                        </div>
                    )}

                </div>
                <div
                    style={{
                        position: "fixed",
                        bottom: isOpenMenuMobile ? "0" : "-100%", // Show or hide tab
                        left: "0",
                        width: "100%",
                        zIndex: '100',
                        height: "fit-content",
                        backgroundColor: "#ffffff",
                        boxShadow: "0px -2px 10px rgba(0, 0, 0, 0.2)",
                        borderTopLeftRadius: "16px",
                        borderTopRightRadius: "16px",
                        transition: "bottom 0.1s ease-in-out",
                        padding: "20px",
                    }}
                >
                    <div className="p-2 text-left">
                        <a
                            href={linkWebsiteDomain}
                            className="flex items-center space-x-2 px-2 py-2 hover:bg-gray-100 rounded-lg text-sm text-black"
                        >
                            <div> <img src={KeyBoardOpen} style={{ height: "23px", width: "23px", }} /></div>
                            <div className="font-medium text-sm text-black">My Website</div>
                        </a>
                        <a
                            onClick={handleMyBilling}
                            className="flex items-center space-x-2 px-2 py-2 hover:bg-gray-100 rounded-lg text-sm text-black"
                        >
                            <div> <img src={CardIcon} style={{ height: "23px", width: "23px", }} /></div>
                            <div className="font-medium text-sm text-black">My Plan & Billings</div>
                        </a>
                    </div>
                    <hr className="m-0"></hr>
                    <div className="text-left" style={{ marginTop: '10px', color: '#292D32', fontSize: '13px', fontWeight: "500" }} >

                        <div
                            className="block hover:bg-gray-100 rounded-lg"
                            style={{ padding: "10px 20px " }}
                            onClick={openEmailClient}
                        >
                            Support
                        </div>
                        <div
                            className="block hover:bg-gray-100 rounded-lg"
                            style={{ padding: "10px 20px " }}
                            onClick={handleLogout}
                        >
                            Logout
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}



export default NavBarBilling;
