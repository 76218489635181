import { FunctionComponent, useEffect, useState } from "react";
import FullScreenImage from "components/FullScreenImage";
import { Theme } from "pages/Home";

import "./styles.scss";
import SectionHelper from "helpers/SectionHelper";

const CTASmall: FunctionComponent<{
  section: any;
  theme: Theme | String;
}> = ({ section, theme }) => {
  const randomColor = "#" + (((1 << 24) * Math.random()) | 0).toString(16);

  if (Array.isArray(section?.data) && section?.data?.length !== 0) {
    return (
      <section className={"text-center cta cta-4 space--xxs border--bottom  " + SectionHelper.getColorClass(section)}>
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <span className="label label--inline" style={{ display: 'inline-flex', alignItems: 'center', justifyContent: 'center' }}>Hot!</span>
              {!!section.data[0].description ? (
                <span>
                  {section.data[0].description}
                  {!!section.data[0].link ? (
                    <a href={`https://${section.data[0].link.replace("https://", "")}`} style={{ marginLeft: '8px' }}>
                      Click here
                    </a>
                  ) : null}
                </span>
              ) : null}
            </div>
          </div>
        </div>
      </section>
    );
  } else {
    return <></>;
  }
};

export default CTASmall;
