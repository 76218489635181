import FullScreenImage from "components/FullScreenImage";
import { useState } from "react";
import { randomHSLA } from "../../../FAQ";
import { timeSince } from "pages/Chat/components/MessContact";
import { Link, useLocation } from "react-router-dom";

function UpdateCardStandard({
  post,
  site,
}: {
  post: any;
  site: any;
}) {
  const [seeMore, setSeeMore] = useState<boolean>(false);
  const location = useLocation();

  if (!!post?.content || !!post?.picture_data) {
    return (
      <Link
        to={`/post/${post.id}`}
        state={{ prevRoute: location.pathname }}
        style={{ width: "100%" }}
      >
        <article className="update-card flex-vertical">
          {!!post.picture_data.length ? (
            <div className="update-image">
              <img
                src={post.picture_data[0]?.image_s}
                alt="Customer's picture"
              />
            </div>
          ) : (
            <div className="update-image"></div>
          )}
          <div className="update-info flex-vertical">
            <div className="update-text flex-vertical">
              <p className="update-title">{post.title}</p>
              {typeof post?.content === "string" ? (
                <div
                  onClick={() => setSeeMore(!seeMore)}
                  className={`update-description ${typeof post?.content === "string" &&
                    post?.content.length > 70 &&
                    "des-overflow"
                    } ${seeMore ? "des-all" : ""}`}
                >
                  {post.content}
                </div>
              ) : null}
            </div>
            {/* <button>
         <p>View case</p>
       </button> */}
          </div>
        </article>
      </Link>
    );
  } else {
    return <></>;
  }
}

export default UpdateCardStandard;
