import { JSX, useEffect, useState } from 'react';
import { CreditCard, Plus } from 'lucide-react';
import CustomerService from 'api/services/CustomerService';
import PaymentHelper from 'helpers/PaymentHelper';
import { useLocation, useNavigate } from 'react-router-dom';
import LoadingSuspense from 'components/Suspense';
import AuthService from 'api/services/authService';
import ApiConfig from 'api/apiConfig';
interface PaymentMethod {
    id: string;
    card: {
        last4: string;
        exp_month: number;
        exp_year: number;
        card_brand: string;
    };
}
export default function ChoosePaymentMethod() {
    const [selectedMethod, setSelectedMethod] = useState('existing');
    const [paymentMethod, setPaymentMethod] = useState<PaymentMethod | null>(null);

    const navigate = useNavigate();
    const location = useLocation();
    const { feature_selected, plan_code } = location.state || { feature_selected: null, plan_code: 'test-plan' };

    const [loading, setLoading] = useState(false);


    useEffect(() => {
        const userInfo = AuthService.GetCurrentUser();
        if (userInfo === undefined) {
            window.location.href = "https://" + ApiConfig.MAIN_DOMAIN

        }
        setLoading(true);

        console.log(plan_code)
        CustomerService.GetPaymentMethods().then((data) => {
            if (data.length === 0) {
                setSelectedMethod('new')
            } else
                if (data[0]['type'] === 'card') {
                    setPaymentMethod({
                        id: data[0].id,
                        card: {
                            last4: data[0]['card']['last4'],
                            exp_year: data[0]['card']['exp_year'],
                            exp_month: data[0]['card']['exp_month'],
                            card_brand: data[0]['card']['display_brand']
                        }
                    });
                } else if (data[0]['type'] === 'sepa_debit') {
                    setPaymentMethod({
                        id: data[0].id,
                        card: {
                            last4: data[0]['sepa_debit']['last4'],
                            exp_year: 0,
                            exp_month: 0,
                            card_brand: data[0]['type']
                        }
                    });
                } else {
                    setPaymentMethod(null);
                }
            setLoading(false);

        });
    }, []);

    const handleOption = () => {

        if (selectedMethod === 'existing') {
            //Call API subscribe and then navigate to screen result
            navigate(`/stripe/intermediate`, { state: { statusLogin: true, plan_code: plan_code, feature_selected: feature_selected } });


        } else {
            navigate('/subscription/form', { state: { statusLogin: true, plan_code: plan_code, feature_selected: feature_selected } });

        }
    }

    const setHome: () => JSX.Element = () => {
        return (
            <div style={{ height: '100vh', width: 'auto', display: 'flex', alignItems: 'center', justifyContent: 'center', }}>
                <div className="w-full max-w-md mx-auto shadow-xl rounded-lg overflow-hidden justify-center" style={{ backgroundColor: "#f9fafc" }}>
                    <div className="p-6">
                        <h2 className="text-2xl font-bold mb-2">Choose Payment Method</h2>
                        <p className="text-gray-600 mb-6 font-semibold">Select an existing card or add a new payment method</p>

                        <div className="space-y-4">
                            {/* Option for Existing Card */}

                            {paymentMethod ? <div
                                style={{
                                    display: 'flex',
                                    alignItems: 'center',
                                    gap: '1rem',
                                    borderRadius: '0.375rem',
                                    border: `2px solid ${selectedMethod === 'existing' ? 'black' : '#E2E8F0'}`,
                                    padding: '1rem',
                                    cursor: 'pointer',
                                    justifyContent: 'center'
                                }}
                                onClick={() => setSelectedMethod('existing')}
                            >
                                <div style={{
                                    width: '1.35rem',
                                    height: '1.35rem',
                                    borderRadius: '100%',
                                    border: `2px solid ${selectedMethod === 'existing' ? 'black' : '#A0AEC0'}`,
                                    display: 'flex',
                                    alignItems: 'center',
                                    justifyContent: 'center',
                                }}>
                                    {selectedMethod === 'existing' && (
                                        <div
                                            style={{
                                                width: '0.83rem',
                                                height: '0.83rem',
                                                borderRadius: '100%',
                                                backgroundColor: 'black',
                                            }}
                                        />
                                    )}

                                </div>
                                <div className="flex flex-1 items-center justify-between">
                                    <div className="flex items-center space-x-2">
                                        <CreditCard className="h-8 w-8 text-gray-500" />
                                        <span className="text-gray-500 text-lg font-medium">{PaymentHelper.getBrandName(paymentMethod?.card.card_brand ?? '')}: ••••{paymentMethod?.card.last4 ?? ''}</span>
                                    </div>
                                </div>
                            </div> : <></>}


                            {/* Option for New Payment Method */}
                            <div
                                style={{
                                    display: 'flex',
                                    alignItems: 'center',
                                    gap: '1rem',
                                    borderRadius: '0.375rem',
                                    border: `2px solid ${selectedMethod === 'new' ? 'black' : '#E2E8F0'}`,
                                    padding: '1rem',
                                    cursor: 'pointer',
                                }}
                                onClick={() => setSelectedMethod('new')}
                            >
                                <div style={{
                                    width: '1.35rem',
                                    height: '1.35rem',
                                    borderRadius: '100%',
                                    border: `2px solid ${selectedMethod === 'new' ? 'black' : '#A0AEC0'}`,
                                    display: 'flex',
                                    alignItems: 'center',
                                    justifyContent: 'center',
                                }}>
                                    {selectedMethod === 'new' && (
                                        <div
                                            style={{
                                                width: '0.83rem',
                                                height: '0.83rem',
                                                borderRadius: '100%',
                                                backgroundColor: 'black',
                                            }}
                                        />
                                    )}

                                </div>
                                <div className="flex flex-1 items-center justify-between">
                                    <div className="flex items-center space-x-2">
                                        <Plus className="h-8 w-8 text-gray-500" />
                                        <span className="text-gray-500 text-lg font-medium">Add a new payment method</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="px-6 py-4 bg-gray-50">
                        <button
                            className="w-full bg-black text-white py-2 px-4 rounded-md hover:bg-gray-800 transition duration-200" onClick={handleOption}
                        >
                            {selectedMethod === 'existing' ? 'Continue with existing card' : 'Add new payment method'}
                        </button>
                    </div>
                </div>
            </div>
        );
    };

    return <>{loading ? <LoadingSuspense /> : setHome()}</>;

}
