// import { Theme } from "pages/Home";

import UpdateCardYosha from "./WorkCardYosha";
import "./styles.scss";

interface WorkProps {
  posts: any;
  theme: String;
  site: any;
}

function BlogYosha({ posts, theme, site }: Partial<WorkProps>) {
  return (
    <section className="update-container yosha">
      <div className="update-text">
        <h3>Jelajahi artikel kami</h3>
        <p>
          Baca kumpulan artikel mencerahkan yang dibuat untuk memandu Anda
          menuju pemberdayaan. Dari wawasan hukum hingga kesejahteraan
          psikologis, artikel-artikel ini berfungsi sebagai pusat pengetahuan,
          menginspirasi perubahan positif dan ketahanan.
        </p>
      </div>
      <div className="update-items flex-horizontal">
        {posts.map((post: any, index: number) => (
          <UpdateCardYosha key={index} post={post} site={site} />
        ))}
      </div>
    </section>
  );
}

export default BlogYosha;
