import ApiClient from "api/apiClient";
import ApiConfig from "api/apiConfig";
import AuthService from "./authService";

class WebsiteService {
  static type = "website";

  static GetWebsite = async (where: any) => {
    const url = ApiConfig.API_URL + ApiConfig.WEBSITES_INDEX;

    const response = await ApiClient.sendGet(url, where, false);
    return response;
  };

  static CreateWebsite = async (attributes: any) => {
    const url = ApiConfig.API_URL + ApiConfig.WEBSITES_CREATE;
    const response = await ApiClient.sendPost(this.type, url, attributes);
    return response;
  };

  static Update = async (id: any, attributes: any) => {
    const url = ApiConfig.API_URL + ApiConfig.WEBSITES_UPDATE;

    const response = await ApiClient.sendPatch(this.type, url, id, attributes);
    return response;
  };

  static getAiContent = async (where: any = {}) => {
    const url = ApiConfig.API_URL + ApiConfig.WEBSITES_AI;

    const response = await ApiClient.sendPost(null, url, where, true);
    return response;
  }

  // ====================================================================================
  // === HELPER =========================================================================
  static getCurrentSite = async () => {
    let currentSiteJson = localStorage.getItem("current_site");
    if (currentSiteJson) {
      return JSON.parse(currentSiteJson);
    }

    const currentAccount = await AuthService.GetCurrentAccount();
    if (!currentAccount) {
      return null;
    }

    const currentWebsites = await WebsiteService.GetWebsite({
      account_id: currentAccount.id,
    });
    if (!currentWebsites) {
      return null;
    }

    const currentWebsite = currentWebsites[0];
    localStorage.setItem("current_site", currentWebsite ? JSON.stringify(currentWebsite) : "");

    return currentWebsite;
  };

  static getSiteDomain = (site: any) => {
    if (!site) {
      return "";
    }

    const domain = site.domain;

    if (domain.includes('.')) {
      return `https://${domain}`;
    } else {
      return `https://${domain}.${ApiConfig.MAIN_DOMAIN}`;
    }
  };
}

export default WebsiteService;
