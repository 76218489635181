import React, { useEffect, useState } from 'react';
import { LoadScript } from '@react-google-maps/api';
import { Button } from 'primereact/button';
import Step1 from './Step1';
import Step2 from './Step2';
import FinalStep from './FinalStep';
import ApiConfig from 'api/apiConfig';
import { Steps } from 'primereact/steps';
import { Card } from 'primereact/card';

import 'primereact/resources/themes/saga-blue/theme.css';
import 'primereact/resources/primereact.min.css';
import 'primeicons/primeicons.css';
import './styles/Onboarding.css';

const API_KEY = ApiConfig.GOOGLE_MAP_KEY || '';

const Onboarding: React.FC = () => {
  const [currentStep, setCurrentStep] = useState<number>(1);
  const [selectedBusinessTag, setSelectedBusinessTag] = useState<string | null>(null);
  const [selectedJob, setSelectedJob] = useState<string | null>(null);
  const [selectedBusinessName, setSelectedBusinessName] = useState<string | null>(null);
  const [location, setLocation] = useState<string>('');
  const [latLng, setLatLng] = useState<string>('');
  
  const steps = [
    { label: 'Website Type' },
    { label: 'Company Name' },
  ];

  const nextStep = () => {
    setCurrentStep((prev) => prev + 1);
  };

  const prevStep = () => {
    setCurrentStep((prev) => prev - 1);
  };
  return (
    <div className="onboarding-container">
      <a href="https://castlebird.com">
        <img src={require('assets/castlebird_white 3.png')} className="logo pt-2 ml-3" alt="Logo" />
      </a>

      {currentStep > 0 && currentStep < 3 && (
        <Steps
          model={steps}
          activeIndex={currentStep - 1}
          onSelect={(e) => setCurrentStep(e.index + 1)}
          readOnly={true}
          className="steps-container"
        />
      )}

      <Card className="onboarding-card">
 
        {/* {currentStep > 2 && currentStep < 6 && (
          <Button icon="pi pi-arrow-left"  
                  text severity="help" 
                  label="Back" 
                  className='back-button' 
                  onClick={()=>{ prevStep() }}
           />
         )} */}

        {/* {currentStep === 1 && <Step1 onComplete={nextStep} />} */}
        {currentStep === 1 && (
          <Step1
            selectedBusinessTag={selectedBusinessTag}
            setSelectedBusinessTag={setSelectedBusinessTag}
          />
        )}
      
        {currentStep === 2 && (
          <Step2
            selectedBusinessTag={selectedBusinessTag}
            selectedBusinessName={selectedBusinessName}
            setSelectedBusinessName={setSelectedBusinessName}
            setLocationData={(location, latLng) => {
              setLocation(location);
              setLatLng(latLng);
            }}
          />
        )}
        
        {currentStep === 3 && (
          <FinalStep
            selectedBusinessTag={selectedBusinessTag}
            selectedBusinessName={selectedBusinessName}
            location={location}
            latLng={latLng}
          />
        )}

        <div className="button-container">
         
          {currentStep <= 2 && (
            <div></div>
          )}
          {currentStep < 2 && (
            <Button
              label="Next"
              className="p-button-primary next-button"
              onClick={()=>{
                nextStep();
              }}
              disabled={!selectedBusinessTag}
            />
          )}
          { currentStep === 2 && (
            <Button
              label="Generate my website"
              className="p-button-primary next-button"
              onClick={()=>{
                nextStep();
                
              }}
              disabled={!selectedBusinessName}
            />
          )}
        </div>
      </Card>
    </div>
  );
};

export default Onboarding;