import React from 'react';
import MastercardIcon from "assets/icons/icon_card/mastercard.png";
import AELogo from "assets/icons/icon_card/AE.png";
import JCBLogo from "assets/icons/icon_card/JCB.png";
import UnionPayLogo from "assets/icons/icon_card/UnionPay.png";
import VisaLogo from "assets/icons/icon_card/visa.png";
import DinerClubLogo from "assets/icons/icon_card/dinerclub.png";
import DiscoverLogo from "assets/icons/icon_card/discover.png";
import SepaLogo from "assets/icons/icon_card/sepa.png";

interface LogoBrandProps {
    card_brand: string;
}

const LogoBrand: React.FC<LogoBrandProps> = ({ card_brand }) => {
    const getIconPaymentMethod = (card_brand: string) => {
        switch (card_brand) {
            case 'visa':
                return VisaLogo;
            case 'mastercard':
                return MastercardIcon;
            case "american_express":
                return AELogo;
            case "diners_club":
                return DinerClubLogo;
            case "discover":
                return DiscoverLogo;
            case "jcb":
                return JCBLogo;
            case "union_pay":
                return UnionPayLogo;
            case "sepa_debit":
                return SepaLogo;
            default:
                return VisaLogo;

        }
    }

    return (
        <img
            src={getIconPaymentMethod(card_brand ?? "")}
            alt="Card logo"
            className="mr-4"
            style={{ height: '38px', width: 'auto', objectFit: 'cover' }}
        />
    );
};

export default LogoBrand;