import { useEffect, } from 'react';
import './style.scss';
import { useLocation, useNavigate } from 'react-router-dom';
import NavBarBilling from './components/navbar/navbar';
import AuthService from 'api/services/authService';


function WelcomePage() {

    const navigate = useNavigate();
    const location = useLocation();
    const state = location.state || {};
    const handleOnNext = (plan_code: string, feature_selected: string) => {
        const userInfo = AuthService.GetCurrentUser();
        if (userInfo === undefined) {
            navigate('/login', { state: { plan_code: plan_code, feature_selected: feature_selected } });

        } else {
            navigate('/subscription/form-google', { state: { plan_code: plan_code, statusLogin: true, feature_selected: feature_selected } });
        }

    }
    useEffect(() => {
        console.log(state)
    }, []);
    return (
        <div style={{ justifyContent: 'center', alignItems: 'center', display: 'flex', flexDirection: "column", height: '100vh' }}>
            {/* <NavBarBilling /> */}

            <div style={{ fontSize: 20, fontWeight: '600', paddingBottom: "16px" }}> This is welcome page</div>
            <button onClick={() => handleOnNext('test-plan', 'Custom Domain')} className='bg-blue-600 rounded-lg p-4 mb-4 items-center flex font-bold font-'>Next with Custom Domain</button>
            <button onClick={() => handleOnNext('test-plan', 'SEO Optimization Tools')} className='bg-blue-600 rounded-lg p-4 mb-4 items-center flex font-bold font-'>Next with SEO Optimization Tools</button>
            <button onClick={() => handleOnNext('test-plan', 'Advanced Website Metrics')} className='bg-blue-600 rounded-lg p-4 mb-4 items-center flex font-bold font-'>Next with Advanced Website Metrics</button>

        </div>
    );
}

export default WelcomePage;
