import "./styles/style.scss";
import React, { JSX, useContext, useEffect, useRef, useState } from "react";
import {
  UserInterface,
} from "types/ApiInterface";
import LoadingSuspense from "components/Suspense";
import "aos/dist/aos.css";
import PagesService from "api/services/PagesService";
import { Theme } from "pages/Home";
import { StateContext } from "store/Provider";
import { Helmet } from "react-helmet";
import { ActionKind } from "store/Reducer";
import UtilityHelper from "helpers/UtilityHelper";
import { Outlet, useLocation, useNavigate, useParams } from "react-router-dom";
import DateTimeHelper from "helpers/DateTimeHelper";
import EmptyFeed from "assets/PublicProfile/empty/empty_feed.png";
import SectionsService from "api/services/SectionsService";
import SectionHelper from "helpers/SectionHelper";
import defaultBGTitle from "assets/background.jpg";
import ApiConfig from "api/apiConfig";
import TrackerHelper from "helpers/TrackerHelper";
<Helmet>
  <script src="../../js/jquery-3.1.1.min.js" type="text/javascript" />
  <script src="../../js/flickity.min.js" type="text/javascript" />
  <script src="../../js/easypiechart.min.js" type="text/javascript" />
  <script src="../../js/parallax.js" type="text/javascript" />
  <script src="../../js/typed.min.js" type="text/javascript" />
  <script src="../../js/datepicker.js" type="text/javascript" />
  <script src="../../js/isotope.min.js" type="text/javascript" />
  <script src="../../js/ytplayer.min.js" type="text/javascript" />
  <script src="../../js/lightbox.min.js" type="text/javascript" />
  <script src="../../js/granim.min.js" type="text/javascript" />
  <script src="../../js/jquery.steps.min.js" type="text/javascript" />
  <script src="../../js/countdown.min.js" type="text/javascript" />
  <script src="../../js/twitterfetcher.min.js" type="text/javascript" />
  <script src="../../js/spectragram.min.js" type="text/javascript" />
  <script src="../../js/smooth-scroll.min.js" type="text/javascript" />
  <script src="../../js/scripts.js" type="text/javascript" />
</Helmet>

function Blog() {
  let params = useParams();
  const navigate = useNavigate();
  const [isHome, setIsHome] = useState(true);
  const [user, setUser] = useState<UserInterface>();
  const [site, setSite] = useState<Record<string, any> | null>(null);
  const [loading, setLoading] = useState(true);
  const formRef = useRef<HTMLInputElement>(null);
  const { state, dispatch } = useContext(StateContext);
  const [pages, setPages] = useState<Array<any>>([]);
  const [currentPage, setCurrentPage] = useState();
  const footerRef = useRef<HTMLDivElement>(null);
  const publishedBlogs = useState();
  const [sections, setSections] = useState<Array<any>>([]);
  const [blogExcerpts, setBlogExcerpts] = useState<{ [key: string]: string }>({});
  
  console.log(params.slug)
  const getSections = async (page_id: string, permaid: string) => {
    setLoading(true);
    const data = await SectionsService.GetSections({
      page_id: page_id,
      permaid: permaid,
    });
    console.log(data)
    setSections(data);
    setLoading(false);
  };

  useEffect(() => {
    setLoading(true);
    UtilityHelper.initApp(state).then((val) => {
      console.log(val)

      setSite(val.website);
      setUser(val.user);
      dispatch({ type: ActionKind.SET_CURRENT_WEBSITE, payload: val.website });
      dispatch({ type: ActionKind.SET_CURRENT_USER, payload: val.user });
      if (params.slug && params.slug != "null") {
        PagesService.GetPages({ website_id: val.website.id, permaid: params.slug }).then(
          (val) => {
            console.log(val)
            if (!val.length) {
              navigate(ApiConfig.BLOG_URL);
            }
            setCurrentPage(val[0]);
            setLoading(false);
          }
        );
      }
      else {
        PagesService.GetPages({
          website_id: val.website.id, page_type: "blog_post",
        }).then(
          (val) => {
            console.log(val[0])

            const draftPages = val.filter((blog: { snap_blog: { status: string } }) => UtilityHelper.isPresent(blog.snap_blog) && blog.snap_blog.status === 'draft');
            const publishedPages = val.filter((blog: { snap_blog: { status: string } }) => UtilityHelper.isPresent(blog.snap_blog) && blog.snap_blog.status === 'published');
            // Neu khong co publish blog thi back ve home
            // if (!UtilityHelper.isPresent(publishedPages)) {
            //   navigate("/home");
            // };
            // Đặt giá trị của publishedPages vào state
            if (site?.is_temporary === true || site?.is_temporary === 'true') {
              setPages(draftPages);
              TrackerHelper.virtualPage("/ai-blog-onboarding/preview_blog_page", "Blog-onboarding - Preview Blog Page");
              if (draftPages.length > 0) {
                  getSections(draftPages[0].id, draftPages[0].permaid);
              }
          } else {
              setPages(publishedPages);
              TrackerHelper.virtualPage("/preview_blog_page", "User on app - Preview Blog Page");
              if (publishedPages.length > 0) {
                  getSections(publishedPages[0].id, publishedPages[0].permaid);
              }
          }
          
            setLoading(false);
          }
        );
      }
    });

  

  }, [site]);

  useEffect(() => {
    const getExcerpts = async () => {
        const excerptsData: { [key: string]: string } = {};
        const fetchPromises = pages.map(async (page) => {
            const data = await SectionsService.GetSections({
                page_id: page.id,
                permaid: page.permaid,
            });
            excerptsData[page.id] = data[0]?.data[0]?.description;
        });

        await Promise.all(fetchPromises);
        setBlogExcerpts(excerptsData);
    };

    if (pages.length > 0) {
      getExcerpts();
    }
}, [pages]);

  const defaultFeatureImg = () => {
    var defaultFeatureImgUrl = '';
    sections.forEach((section) => {
      if (SectionHelper.hasImage(section)) {
        defaultFeatureImgUrl = section.data[0]?.image_file_data?.image_s;
      }
    })
    if (defaultFeatureImgUrl) {
      return defaultFeatureImgUrl;
    } else {
      return defaultBGTitle;
    }
  }

  const setHome: () => JSX.Element = () => {
  console.log(pages)

    return (
      <div className="sections-container flex-vertical">
        {pages.length ? <section className="cover height-80 imagebg" data-overlay="5">
          {pages[0].snap_blog.feature_image !== null ? (
            <div className="background-image-holder" style={{ backgroundImage: "url(" + pages[0].snap_blog?.feature_image + ")", opacity: '1', backgroundSize: "auto", }}>
              <img alt="background" src={pages[0].snap_blog?.feature_image} style={{ width: '100%', height: '290px', borderRadius: ' 6px 6px 0px 0px ' }} />
            </div>
          ) : (
            <div className="background-image-holder" style={{ backgroundImage: "url(" + defaultFeatureImg() + ")", opacity: '1', backgroundSize: "auto", }}>
              <img alt="background" src={``} style={{ width: '100%', height: '290px', borderRadius: ' 6px 6px 0px 0px ' }} />
            </div>
          )}
          <div className="container pos-vertical-center">
            <div className="col-md-12">
              <span className="post-label"> Recently created </span>
              <h1 className="h1--large">
                {pages[0].name}
              </h1>
              <h5 className="">{DateTimeHelper.formatDateString(pages[0].updated_at)}</h5>
              <p className="text-xl excerpt"> {pages[0]?.snap_blog?.excerpt ? pages[0]?.snap_blog?.excerpt : sections[0]?.data[0]?.description}..</p>
              <a className="btn btn--primary type--uppercase" onClick={() => {
                setCurrentPage(pages[0]);
                navigate(`${ApiConfig.BLOG_URL}/${pages[0].permaid}`);
              }} style={{ borderRadius: '4px', backgroundColor: '#2318DD ', border: 'none' }}>
                <span className="btn__text" >
                  Read more
                </span>
              </a>
            </div>
          </div>
        </section> : (
  <div className="no-blog-container">
  <div className="no-blog-card">
    {/* <img src="https://cdn-icons-png.flaticon.com/512/4076/4076474.png" alt="No Blog Posts" /> */}
    <h2>        
        You don't have any published blog posts.
    </h2>
    <p>
        Create and publish your blog posts to share your thoughts with the world.
    </p>
  </div>
</div>
      
        )
      }
        {pages.length ? <section className="switchable feature-large all-posts-container">
          <div className="container_Tab">
            <div className="row justify-evenly">
              <div className=" col-lg-2 mt-4 ml-2 sm:ml-0">
                <div className="text-block">
                  <h2>Top Blog</h2>
                  {pages.map((page, index) => (
                    <a onClick={() => {
                      setCurrentPage(page);
                      navigate(`${ApiConfig.BLOG_URL}/${page.permaid}`);
                    }}
                      style={{ cursor: 'pointer', }} key={page.id} className="block">
                      {index === 0 ? (
                        <h5 className="text-lg" style={{ fontWeight: "400" , marginBottom:'8px'}}>{page.name}</h5>
                      ) : <p style={{marginBottom:'8px'}}>{page.name}</p>}

                    </a >
                  ))
                  }
                </div>
                <div className="text-block">
                  <h2>Lastest Blog</h2>
                  {pages.slice().sort((a, b) => new Date(b.updated_at).getTime() - new Date(a.updated_at).getTime()).map((page, index) => (
                    <a onClick={() => {
                      setCurrentPage(page);
                      navigate(`${ApiConfig.BLOG_URL}/${page.permaid}`);
                    }}
                      style={{ cursor: 'pointer', }} key={page.id} className="block">
                      {index === 0 ? (
                        <h5 className="text-lg" style={{ fontWeight: "400", marginBottom:'8px'}}>{page.name}</h5>
                      ) : <p style={{marginBottom:'8px'}}>{page.name}</p>}

                    </a >
                  ))
                  }
                </div>
              </div>
              <div className=" col-lg-8" >
                <div className="grid-container">
                  {pages.map( (page, index)  => (
                      <a onClick={() => {
                        setCurrentPage(page);
                        navigate(`${ApiConfig.BLOG_URL}/${page.permaid}`);
                      }}
                        style={{ cursor: 'pointer' }} key={page.id} className="block">
                        <div className="grid-item">
                          <article className="feature-1">
                            {pages[index].snap_blog.feature_image !== null ? (
                              <img alt="Feature image" style={{ width: '100%', height: '290px', borderRadius: ' 0px 0px 0px 0px ' }} src={pages[index].snap_blog?.feature_image} />
                            ) : (
                              <img alt="Feature image" style={{ width: '100%', height: '290px', borderRadius: ' 0px 0px 0px 0px ' }} src={defaultFeatureImg()} />
                            )}
                            <div className="feature__body boxed boxed--border" style={{ borderRadius: ' 0px 0px 0px 0px ' }}>
                              <span>{DateTimeHelper.formatDateString(page.updated_at)}</span>
                              <h5>{page.name}</h5>
                              <p className="text-base text-preview" style={{}}>  {page.snap_blog?.excerpt ? page.snap_blog.excerpt : blogExcerpts[page.id]} </p>
                              <a className="readmore" onMouseEnter={(e) => e.currentTarget.style.textDecoration = 'underline'}
                                onMouseLeave={(e) => e.currentTarget.style.textDecoration = 'none'} style={{ cursor: 'pointer', textDecoration: 'none', color: '#4a90e2' }}>
                                Read more
                              </a>
                            </div>
                          </article >
                        </div >
                      </a >
                    )     
                )
                  }
                </div >
              </div>
            </div>
          </div >
        </section > : null}
      </div >
    );
  };

  return <>{loading ? <LoadingSuspense /> : setHome()}</>;
}

export default Blog;
