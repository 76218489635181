import axios from "axios";

class ApiClient {
  static getAxios = (requireLogin = true,) => {

    const accessToken = localStorage.getItem("accessToken");
    if (requireLogin) {
      console.log("Bearer " + accessToken)
      return axios.create({
        baseURL: process.env.REACT_APP_AUTH_SERVER,
        timeout: 50000,
        headers: {
          // Authorization: "Bearer 3iDeJJauL9OGt7ppV8DYLtY1Ogj507WKi2aERH7KMjg",
          Authorization: "Bearer " + accessToken,
          "Content-Type": "application/json; charset=UTF-8",
          Accept: "application/json",
        },
      });
    } else {
      return axios.create({
        baseURL: process.env.REACT_APP_AUTH_SERVER,
        timeout: 50000,
        headers: {
          "Content-Type": "application/json; charset=UTF-8",
          Accept: "application/json",
        },
      });
    }
  };

  static checkLogin = async () => {
    return true;
    // if (await AuthService.IsLoggedIn()) {
    //     return true;
    // }
    // window.location.href = process.env.REACT_APP_LOGIN_PAGE;
    // return false;
  };

  static sendPost = async (
    type: any,
    url: string,
    bodyParameters: any,
    requireLogin = true,
  ) => {
    if (requireLogin == true) {
      let checkLoginResult = await this.checkLogin();
      if (checkLoginResult == false) {
        return;
      }
    }

    // Prepare the request parameters
    let params = {
      [type]: bodyParameters ?? {},
      locale: "en",
    };

    if (!type) {
      params = bodyParameters ?? {};
    }

    const ax = this.getAxios(requireLogin);
    let result = await ax.post(url, params);
    let data = result.data;

    // return data;

    // NO DATA
    if (!data) return data;
    data = data.data;

    if (!data) return data;

    // PARSE ITEMS
    if (typeof data[Symbol.iterator] === "function") {
      var parsedItems = [];
      for (const item of data) {
        let parsedItem = item.attributes ? item.attributes : item;
        parsedItem.id = item.id;
        parsedItems.push(parsedItem);
      }
      return parsedItems;
    } else {
      let parsedItem = data.attributes ? data.attributes : data;
      parsedItem.id = data.id;
      return parsedItem;
    }
  };


  static sendPatch = async (
    type: string,
    url: string,
    id: any,
    bodyParameters: any,
    requireLogin = true,
  ) => {
    url += id;

    if (requireLogin === true) {
      let checkLoginResult = await this.checkLogin();
      if (checkLoginResult === false) {
        return;
      }
    }

    let params = {
      [type]: bodyParameters ?? {},
      id: id,
      locale: "en",
    };

    const ax = this.getAxios(requireLogin);
    let result = await ax.patch(url, params);
    return result.data;
  };

  static sendGet = async (
    url: string,
    filters: any,
    requireLogin = true,
    isNeedRawRespond = false,
    page?: any,
    order?: any
  ) => {
    if (requireLogin == true) {
      let checkLoginResult = await this.checkLogin();
      if (checkLoginResult == false) {
        return;
      }
    }
    const ax = this.getAxios(requireLogin,);
    let result = await ax.get(url, {
      params: filters,
    });

    let data = result.data;
    // FOR RAW RESPOND
    if (isNeedRawRespond) return data;
    // NO DATA
    if (!data) return data;
    data = data.data;
    if (!data) return data;

    // PARSE ITEMS
    if (typeof data[Symbol.iterator] === "function") {
      var parsedItems = [];
      for (const item of data) {
        let parsedItem = item.attributes ? item.attributes : item;
        parsedItem.id = item.id;
        parsedItems.push(parsedItem);
      }
      return parsedItems;
    } else {
      let parsedItem = data.attributes ? data.attributes : data;
      parsedItem.id = data.id;
      return parsedItem;
    }
  };


  // static sendDel = async (
  //   type: any,
  //   url: string,
  //   bodyParameters: any,
  //   requireLogin = true
  // ) => {
  //   if (requireLogin == true) {
  //     let checkLoginResult = await this.checkLogin();
  //     if (checkLoginResult == false) {
  //       return;
  //     }
  //   }
  //   let params = {
  //     data: {
  //       type: type,
  //       attributes: bodyParameters ?? {},
  //     },
  //     locale: "en",
  //   };
  //   if (!type) {
  //     params = bodyParameters ?? {};
  //   }
  //   const ax = this.getAxios(requireLogin);
  //   let result = await ax.delete(url, params);
  //   return result.data;
  // };

  static sendDel = async (url: string, filters: any, requireLogin = true,) => {
    if (requireLogin == true) {
      let checkLoginResult = await this.checkLogin();
      if (checkLoginResult == false) {
        return;
      }
    }
    const ax = this.getAxios(requireLogin,);
    let result = await ax.delete(url, {
      params: {
        filter: filters ?? {},
      },
    });
    let data = result.data;
    // NO DATA
    if (!data) return data;
    data = data.data;
    if (!data) return data;
    // PARSE ITEMS
    if (typeof data[Symbol.iterator] === "function") {
      var parsedItems = [];
      for (const item of data) {
        let parsedItem = item.attributes;
        parsedItem.id = item.id;
        parsedItems.push(parsedItem);
      }
      return parsedItems;
    } else {
      let parsedItem = data.attributes;
      parsedItem.id = data.id;
      return parsedItem;
    }
  };
}
export default ApiClient;
