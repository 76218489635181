import React, { JSX, useEffect, useState } from 'react';
import CancelSuccessByeBye from "assets/Home/billing/cancellation.png";
import { useNavigate } from 'react-router-dom';
import DateTimeHelper from 'helpers/DateTimeHelper';
import LoadingSuspense from 'components/Suspense';
import BillingService from 'api/services/BillingService';
import UtilityHelper from 'helpers/UtilityHelper';
import AuthService from 'api/services/authService';
import ApiConfig from 'api/apiConfig';

const CancelSuccess: React.FC = () => {

    const navigate = useNavigate();
    const [currentPlan, setCurrentPlan] = useState('Starter plan');
    const [dateExpiry, setDateExpiry] = useState('2024-12-29T04:59:46Z');

    const clickToPlansAndBilling = () => {
        navigate(`/subscription`, { state: { isCancel: true, subPlan: '' }, replace: true });
    };

    const handleReSubPlan = () => {
        navigate(`/subscription/plans`, { replace: true });
    };
    useEffect(() => {
        setCurrentPlan('Starter plan')
        setDateExpiry("2024-12-29T04:59:46Z")
    }, []);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        const userInfo = AuthService.GetCurrentUser();
        if (userInfo === undefined) {
            window.location.href = "https://" + ApiConfig.MAIN_DOMAIN

        }
        setLoading(true);
        BillingService.GetSubscription().then(
            (sub) => {
                if (sub.response && sub.response.status == 401) {
                    setLoading(false);
                    AuthService.SaveCurrentUser(null);
                    AuthService.SaveCurrentAccount(null);
                    AuthService.Logout();
                    navigate('/signin', {
                        state: {}, replace: true
                    });
                }
                setCurrentPlan(sub['plan']['name'])
                setDateExpiry(sub['ending_at'])
                setLoading(false);

            }
        );
    }, []);


    const setHome: () => JSX.Element = () => {
        return (
            <div className="bg-gray-100 flex items-center justify-center min-h-screen">
                <div className="bg-white p-8 rounded-xl shadow-md text-center mx-auto my-6" style={{ width: '1200px' }}>
                    <div style={{ width: '100%', display: 'flex', justifyContent: 'center', }}><img alt="Illustration of three people waving goodbye" className="mb-6 rounded-lg" style={{ height: '320px', width: '417px' }} src={CancelSuccessByeBye} /></div>
                    <div className="text-3xl font-bold mt-6 mb-2 text-black">
                        We hope to see you again!
                    </div>
                    <div className="mb-6" style={{ color: '#0A0000', fontSize: '16px', fontWeight: '500', lineHeight: '24px' }}>

                        Your {currentPlan ?? ''} subscription will end on {DateTimeHelper.convertDate(dateExpiry)}. You won't be charged again after this date.<br></br> You can continue to access your account and its basic features until then.

                    </div>
                    <div className="flex justify-center space-x-4">
                        <button className=" text-white px-4 py-2 rounded-lg" style={{ backgroundColor: "#0B86E7", fontSize: '16px', fontWeight: 600, lineHeight: '24px' }} onClick={clickToPlansAndBilling}>
                            Go to My Account
                        </button>
                        <button className="text-black px-4 py-2 rounded-lg border border-[#c4c4c4] border-solid border-1" style={{ backgroundColor: "#fff", fontSize: '16px', fontWeight: 600, lineHeight: '24px' }} onClick={handleReSubPlan}>
                            Resubscribe
                        </button>
                    </div>
                </div>
            </div>
        )
    };

    return <>{loading ? <LoadingSuspense /> : setHome()}</>;
};

export default CancelSuccess;