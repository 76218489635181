import { useEffect, useRef, useState } from 'react';
import BillingService from 'api/services/BillingService';
import LoadingSuspense from 'components/Suspense';
import { useLocation, useNavigate } from 'react-router-dom';
import ColorTemple from 'styles/ColorTemple';
import React, { forwardRef, useImperativeHandle } from 'react';
import CastleBirdLogo from "assets/logo.png"
import TickLogo from "assets//icons/tick-circle.png"
import TickBlueLogo from "assets//icons/tick-circle-blue.png"
import NarBarWelcomeFlow from '../components/navbar/navbarSignIn';
import UtilityHelper from 'helpers/UtilityHelper';


const SeoOptimization: React.FC = () => {
    const location = useLocation(); // Get location without type argument
    const { statusLogin, planName } = location.state || { statusLogin: false, planName: 'Starter Plan' };
    const navigate = useNavigate();

    const isMobile = /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent);

    const handleOpenStore = (isAndroid: boolean) => {

        if (isAndroid) {
            // Điều hướng đến Play Store nếu là Android
            window.location.href = 'https://play.google.com/store/apps/details?id=com.castlebird.castleapp';
        } else {
            window.open("https://apps.apple.com/us/app/castlebird/id6449547159")
        }
    };

    const handleOpenCBApp = () => {
        if (isMobile) {
            // navigate('/download?type=website',);

        } else {
            navigate('/download?type=website',);
        }

    }

    const handleGotoMyAccount = () => {
        if (statusLogin) { navigate('/subscription',); } else {
            navigate('/signin',);
        }

    }
    useEffect(() => {

        // BillingService.GetPlans().then(
        //     (plans) => {
        //         if (UtilityHelper.isPresent(plans)) {
        //             setPlans(plans['data']);
        //         } else { setPlans([]) }
        //         console.log(plans['data']);
        //     }
        // );

    }, []);

    return (
        <div style={{ height: '100vh', }}>
            <NarBarWelcomeFlow></NarBarWelcomeFlow>
            <div className='welcome-content-guest-success' style={{ justifyContent: "space-between", height: '90vh' }} >
                <div style={{ width: '72px', height: '72px', marginBottom: "16px" }}><img src={TickLogo} /></div>
                <div style={{ fontWeight: "700", fontSize: '32px', lineHeight: '44.8px', textAlign: 'center', color: 'black' }}>Welcome to CastleBird {planName}</div>
                {/* <div style={{ fontWeight: "700", fontSize: '32px', lineHeight: '44.8px', textAlign: 'center', color: 'black', }}>Your AI tools are ready to simplify your business.</div> */}
                <div style={{ fontWeight: "600", fontSize: '20px', lineHeight: '24px', textAlign: 'center', color: ColorTemple.gray61, marginTop: "16px", marginBottom: "48px" }}>Your AI tools are ready to simplify your business.<br></br>Check your email for the invoice and start exploring the app.<br></br>
                </div>

                <div className='whats-next-card-guest-success' style={{ marginBottom: "32px" }}>
                    <div style={{ fontWeight: "700", fontSize: "12px", lineHeight: "15.6px", color: ColorTemple.foreBlue, marginBottom: "12px" }}>WHAT’S NEXT</div>
                    <div style={{ fontWeight: "700", fontSize: "20px", lineHeight: "26px", color: 'black' }}>Experience more of our SEO optimization tools within your business!</div>
                    <div style={{ fontWeight: "500", fontSize: "14px", lineHeight: "21px", color: ColorTemple.gray61, marginBottom: "32px" }}>Make sure to download the latest version of the CastleBird app on <span onClick={() => { handleOpenStore(false) }} style={{ color: "black", cursor: "pointer" }} >App store</span> or <span style={{ color: "black", cursor: "pointer" }} onClick={() => { handleOpenStore(true) }}>Play store</span> to get all your new benefits</div>
                    <div className='flex flex-row' style={{ marginBottom: "16px", }}>

                        <div><img src={TickBlueLogo} style={{ height: '24px', width: "24px", }}></img></div>
                        <div style={{ fontWeight: "600", fontSize: "14px", lineHeight: "25.2px", color: "black", marginLeft: "16px", }}>AI-Powered Keyword Research</div>


                    </div>
                    <div className='flex flex-row' style={{ marginBottom: "16px", }}>
                        <div> <img src={TickBlueLogo} style={{ height: '24px', width: "24px" }}></img></div>
                        <div style={{ fontWeight: "600", fontSize: "14px", lineHeight: "25.2px", color: "black", marginLeft: "16px" }}>Brand Voice AI</div>


                    </div>
                    <div className='flex flex-row' style={{ marginBottom: "16px" }}>
                        <div> <img src={TickBlueLogo} style={{ height: '24px', width: "24px" }}></img></div>

                        <div style={{ fontWeight: "600", fontSize: "14px", lineHeight: "25.2px", color: "black", marginLeft: "16px" }}>AI-Powered Content Optimization</div>


                    </div>

                    <div className='flex flex-row' style={{ marginBottom: "16px" }}>
                        <div> <img src={TickBlueLogo} style={{ height: '24px', width: "24px" }}></img></div>

                        <div style={{ fontWeight: "600", fontSize: "14px", lineHeight: "25.2px", color: "black", marginLeft: "16px" }}>AI-Powered Image Optimization</div>


                    </div>
                    <div className='flex flex-row' style={{ marginBottom: "0px" }}>
                        <div> <img src={TickBlueLogo} style={{ height: '24px', width: "24px" }}></img></div>


                        <div style={{ fontWeight: "600", fontSize: "14px", lineHeight: "25.2px", color: "black", marginLeft: "16px" }}>Title Tag and Meta Description Optimization</div>


                    </div>
                    {/* <div onClick={handleOpenCBApp} style={{ cursor: "pointer", backgroundColor: ColorTemple.foreBlue, color: 'white', borderRadius: "12px", padding: "16px 24px", fontSize: "16px", fontWeight: "700", lineHeight: "19.36px", marginBottom: "0px", userSelect: "none", }} className='flex justify-center items-center'>{isMobile ? 'Open CastleBird App' : 'Download CastleBird App'}</div> */}
                </div>
                <div className='font-medium text-center text-sm' style={{ lineHeight: '19.6px', }}>View your plan info, receipts or cancel anytime
                    on your CastleBird Account Page. <br />
                    <a onClick={() => { handleGotoMyAccount() }} style={{ textDecoration: "underline", cursor: 'pointer', userSelect: "none" }}>{statusLogin ? 'Go to my Account ' : 'Back to Login'}</a></div>
            </div>

        </div>

    );
};



export default SeoOptimization;

