import "./style.scss";
import BlogYosha from "./components/BlogYosha";
import BlogStandard from "./components/BlogStandard";
import BlogIntenxFitness from "./components/BlogNikesh";
import BlogDefault from "./components/BlogDefault";

interface WorkProps {
  posts: any;
  theme: String;
  site: any;
}

function Work({ posts, theme, site }: Partial<WorkProps>) {
  const renderBlog = () => {
    switch (theme) {
      case "yosha":
        return <BlogYosha posts={posts} theme={theme} site={site} />;
      case "standard":
        return (
          <BlogStandard posts={posts} theme={theme} site={site} />
        );
      case "intenxfitness":
        return (
          <BlogIntenxFitness

            posts={posts}
            theme={theme}
            site={site}
          />
        );
      default:
        return (
          <BlogDefault posts={posts} theme={theme} site={site} />
        );
    }
  };

  if (Array.isArray(posts) && posts.length !== 0) {
    return <>{renderBlog()}</>;
  } else {
    return <></>;
  }
}

export default Work;
