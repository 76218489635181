import { useEffect, useRef, useState } from 'react';
import BillingService from 'api/services/BillingService';
import LoadingSuspense from 'components/Suspense';
import { useLocation, useNavigate } from 'react-router-dom';
import ColorTemple from 'styles/ColorTemple';
import React, { forwardRef, useImperativeHandle } from 'react';
import CastleBirdLogo from "assets/logo.png"
import TickLogo from "assets//icons/tick-circle.png"
import TickBlueLogo from "assets//icons/tick-circle-blue.png"
import NarBarWelcomeFlow from '../../components/navbar/navbarSignIn';
import "./style.scss"
import UtilityHelper from 'helpers/UtilityHelper';
import TrackerHelper from 'helpers/TrackerHelper';

const GuestSuccess: React.FC = () => {
    const location = useLocation();
    const { statusLogin, planName, featureSelected } = location.state || { statusLogin: false, planName: 'Starter Plan', featureSelected: '' };

    const navigate = useNavigate();

    const handleGotoMyAccount = () => {
        if (statusLogin) { navigate('/subscription',); } else {
            navigate('/signin',);
        }

    }
    // const isMobile = /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent);


    const handleInstallApp = async () => {
        const userAgent = navigator.userAgent || navigator.vendor;

        if (/android/i.test(userAgent)) {
            window.location.href = 'https://play.google.com/store/apps/details?id=com.castlebird.castleapp';
        } else if (/iPad|iPhone/.test(userAgent)) {
            console.log('iOS');
            window.open("https://apps.apple.com/us/app/castlebird/id6449547159")
        } else {
            navigate('/download', {state: {from:'subscription'}});
        }
    };

    useEffect(() => {
        console.log(statusLogin)
        TrackerHelper.virtualPage("/subscribe-success", "Subscribe success")
    }, []);
    console.log("featureSelected value:", featureSelected);
    console.log("featureSelected type:", typeof featureSelected);


    return (
        <div className='container-guest-success'>
            <NarBarWelcomeFlow></NarBarWelcomeFlow>
            <div className='content-section-guest-success'>
                < div className='welcome-content-guest-success'>
                    <div style={{ width: '72px', height: '72px' }}><img src={TickLogo} /></div>
                    <div style={{ fontWeight: "700", fontSize: '32px', lineHeight: '130%', textAlign: 'center', color: 'black' }}>Welcome to {planName === '' ? planName : 'Starter Plan'}</div>

                    {(!featureSelected || featureSelected === "null") && <div style={{ fontWeight: "500", fontSize: '20px', lineHeight: '130%', textAlign: 'center', color: 'black' }}>Check your email for the invoice and start exploring the app.</div>}
                    {featureSelected === "Custom Domain" && <div style={{ fontWeight: "500", fontSize: '20px', lineHeight: '130%', textAlign: 'center', color: 'black' }}>Your custom domain will be live on your website within 24-48 hours.</div>}
                    {featureSelected === "SEO Optimization Tools" && <div style={{ fontWeight: "500", fontSize: '20px', lineHeight: '130%', textAlign: 'center', color: 'black' }}>Your website’s On-Page SEO has been optimized for search engines.</div>}
                    {featureSelected === "Advanced Website Metrics" && <div style={{ fontWeight: "500", fontSize: '20px', lineHeight: '130%', textAlign: 'center', color: 'black' }}>Your website analytics are now gathering data..</div>}
                    {featureSelected === "Business Email" && <div style={{ fontWeight: "500", fontSize: '20px', lineHeight: '130%', textAlign: 'center', color: 'black' }}>Your Business Email is Ready</div>}

                    <button onClick={handleInstallApp} className="open-app-button" style={{ padding: "12px 16px", background: "#2318DD", fontWeight: '500', borderRadius: '4px', marginTop: '16px', marginBottom: '20px' }}>View in app</button>
                    <div className='whats-next-card-guest-success'>
                        <div style={{ fontWeight: "600", fontSize: "20px", lineHeight: "26px", color: 'black' }}>Discover more ways to convert visitors with your premium features in app:</div>

                        {featureSelected !== "Custom Domain" && (

                            <div className='flex flex-row'>
                                <div style={{ minWidth: '40px' }}> <img src={TickBlueLogo} style={{ height: '24px', width: "24px" }}></img></div>
                                <div style={{ fontWeight: "600", fontSize: "18px", lineHeight: "25.2px", color: "black" }}>Custom domain:<span style={{ fontSize: "18px", color: 'black', fontWeight: '400', marginLeft: '6px' }}>Get your business taken seriously</span></div>
                            </div>
                        )}

                        {featureSelected !== "SEO Optimization Tools" && (
                            <div className='flex flex-row'>
                                <div style={{ minWidth: '40px' }}> <img src={TickBlueLogo} style={{ height: '24px', width: "24px" }}></img></div>
                                <div style={{ fontWeight: "600", fontSize: "18px", lineHeight: "25.2px", color: "black" }}>On-page SEO Tools:<span style={{ fontSize: "18px", color: 'black', fontWeight: '400', marginLeft: '6px' }}>Attract more qualified visitors to your website </span></div>
                            </div>
                        )}

                        {featureSelected !== "Advanced Website Metrics" && (
                            <div className='flex flex-row'>
                                <div style={{ minWidth: '40px' }}> <img src={TickBlueLogo} style={{ height: '24px', width: "24px" }}></img></div>
                                <div style={{ fontWeight: "600", fontSize: "18px", lineHeight: "25.2px", color: "black" }}>Advanced Website Metrics:<span style={{ fontSize: "18px", color: 'black', fontWeight: '400', marginLeft: '6px' }}>Track your progress and make data-driven decisions</span></div>
                            </div>
                        )}

                        {featureSelected !== "Business Email" && (
                            <div className='flex flex-row'>
                                <div style={{ minWidth: '40px' }}> <img src={TickBlueLogo} style={{ height: '24px', width: "24px" }}></img></div>
                                <div style={{ fontWeight: "600", fontSize: "18px", lineHeight: "25.2px", color: "black" }}>Business email:<span style={{ fontSize: "18px", color: 'black', fontWeight: '400', marginLeft: '6px' }}>Build credibility and trust with a professional email address</span></div>
                            </div>
                        )}
                        <div className='font-medium text-center text-sm' style={{ lineHeight: '19.6px', }}>View your plan info, receipts or cancel anytime
                            on your CastleBird Account Page. <br />
                            <a onClick={() => { handleGotoMyAccount() }} style={{ textDecoration: "underline", cursor: 'pointer', userSelect: "none" }}>Go to my account </a></div>

                    </div>


                </div >
            </div >

        </div >

    );
};



export default GuestSuccess;

