import AuthService from "api/services/authService";
import BillingService from "api/services/BillingService";
import BillingHelper from "helpers/BillingHelper";
import LoadingSuspense from "components/Suspense";
import UtilityHelper from "helpers/UtilityHelper";
import { useEffect, useState, } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import NarBarWelcomeFlow from "./navbar/navbarSignIn";
import PaymentImage from "../../../assets/payment_processing.svg";

function Intermediate() {
    const navigate = useNavigate();
    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);

    const planName = queryParams.get('plan');
    const featureSelected = queryParams.get('featureSelected');
    const upgrade_plan = queryParams.get('upgrade_plan');
    const emailGuest = queryParams.get('emailGuest') ?? '';


    const statusLogin = queryParams.get('login') === 'true';
    const isUpdated = queryParams.get('isUpdated') === 'true';

    const subPlan = (plan_code: string, feature: string, plan_name: string) => {
        console.log(["plan name:" + planName])
        console.log(["feature:" + featureSelected])
        console.log(["email:" + emailGuest])


        BillingService.SignUpPlan(plan_code, emailGuest).then(
            (result) => {
                console.log(result)
                if (result.response && result.response.status == 401) {
                    AuthService.SaveCurrentUser(null);
                    AuthService.SaveCurrentAccount(null);
                    AuthService.Logout();
                    navigate('/signin', {
                        state: { from: location.pathname, previousState: location.state, }, replace: true
                    });
                }
                if (UtilityHelper.isPresent(result)) {
                    navigate(`/subscription/subscribe-successs/${feature}`, { state: { statusLogin: statusLogin, planName: plan_name, featureSelected: featureSelected }, replace: true });
                }
            }
        )

    };
    useEffect(() => {
        BillingService.GetPlans().then(
            (plans) => {
                let planCode = '';
                const defaultPlan = BillingHelper.getFirstPlanNotFree(plans);
                if (defaultPlan) {
                    planCode = defaultPlan.code
                } else {
                    planCode = plans[0].code
                }
                plans.forEach((e: { name: string, code: string }) => {
                    if (e.name === planName) {
                        planCode = e.code
                        return;
                    }
                });
                console.log(planCode)
                if (isUpdated) {
                    navigate('/subscription', { state: { isUpdateCard: true }, },);
                } else if (upgrade_plan) {
                    subPlan(upgrade_plan, 'upgrade-success', planName!);
                } else {
                    subPlan(planCode ?? 'test-plan', 'subscribeSuccess', planName!);
                }
            }
        );
    }, [planName, upgrade_plan, isUpdated, navigate]);

    const [dots, setDots] = useState("");

    useEffect(() => {
        const interval = setInterval(() => {
            setDots((prevDots) => {
                if (prevDots.length >= 3) return "";
                return prevDots + ".";
            });
        }, 400);

        return () => clearInterval(interval);
    }, []);

    return (
        <>
            <NarBarWelcomeFlow></NarBarWelcomeFlow>
            <div className="flex items-start justify-center p-6" style={{ marginTop: '60px' }}>
                <div className="">
                    <h3 className="font-semibold" style={{ marginBottom: '8px' }}>Payment processing{dots}</h3>
                    <p className="text-xl text-black">Your payment is being processed... Please wait a moment.</p>
                </div>
            </div>
            <img src={PaymentImage} style={{ width: '500px', margin: '0 auto' }} />
        </>
    );
}

export default Intermediate;
