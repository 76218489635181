import { FunctionComponent, useEffect, useState } from "react";
import CBlogo from "assets/logo.webp";

import { TbMenu2 } from "react-icons/tb";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import AuthService from "api/services/authService";
import { UserInterface } from "types/ApiInterface";
import UserService from "api/services/UserService";
import ProfileNavMobile from "./Mobile";
import "./style.scss";
import { IoClose } from "react-icons/io5";
import { CgClose } from "react-icons/cg";
import ApiConfig from "api/apiConfig";

interface ProfileNavbarProps {
  pages: any;
  current_page: any;
  onChangePage: (page: any) => void;
  site: any;
}

const ProfileNavbar: FunctionComponent<ProfileNavbarProps> = ({
  pages,
  current_page,
  site,
  onChangePage,
}) => {
  const { pathname, state } = useLocation();
  const params = useParams();
  const [open, setOpen] = useState<boolean>(false);
  const [isLogin, setIsLogin] = useState<boolean>(false);
  const [user, setUser] = useState<UserInterface>();
  const [accountOpen, setAccountOpen] = useState<boolean>(false);
  const [messDrawer, setMessDrawer] = useState<boolean>(false);
  const location = useLocation();
  const navigate = useNavigate();

  console.log(current_page)
  console.log(pages)

  // Filter pages not of blog type
  const filteredPages = pages.filter(
    (page: { page_type: string }) => page.page_type !== 'blog_post'
  );
  console.log(filteredPages)
  const Logout = () => {
    AuthService.Logout();
    setIsLogin(false);
    setAccountOpen(false);
    navigate('/');
  };

  useEffect(() => {
    const getLogin = async () => {
      const data = await AuthService.IsLoggedIn();
      setIsLogin(data);
    };
    const getUser = async () => {
      const data = await UserService.GetUsers({
        permaid: state?.permaid,
      });
      setUser(data[0]);
    };

    if (state) {
      getUser();
    } else {
      setUser(AuthService.GetCurrentUser());
    }
    getLogin();
  }, [pathname]);

  const onMessage = () => {
    navigate('/message');
    setMessDrawer(!messDrawer);
  };

  return (
    <>
      <section
        className={`nav-profile-container flex-vertical ${site?.logo_data && Object.keys(site?.logo_data).length !== 0
          ? 'with-logo'
          : ''
          }`}
      >
        <nav className="nav-desktop flex-horizontal container">
          {!!site?.logo_data && Object.keys(site?.logo_data).length !== 0 ? (
            <div className="header flex-vertical">
              <div className="pro-logo">
                <img src={site?.logo_data?.image_s} alt="Profile's logo"
                    onClick={() => {
                      if (current_page.page_type === 'blog') {
                        navigate(ApiConfig.BLOG_URL)
                      } else {
                        navigate('/')
                      }
                    }} 
                    style={{cursor:'pointer'}} 
                />
              </div>
            </div>
          ) : (
            <div className="header flex-vertical">
              {(site.is_temporary === true || site.is_temporary === 'true') && filteredPages?.[0]?.page_type === "blog" ? (
                <h1 className="pro-name text-capitalized" onClick={() => {
                  if (current_page.page_type === 'blog') {
                    navigate(ApiConfig.BLOG_URL)
                  } else {
                    navigate('/')
                  }
                }}
                  style={{ cursor: 'pointer' }} >
                  PREVIEW
                </h1>
              ) : (
                <h1 className="pro-name text-capitalized" 
                    onClick={() => {
                      if (current_page.page_type === 'blog') {
                        navigate(ApiConfig.BLOG_URL)
                      } else {
                        navigate('/')
                      }
                    }} 
                    style={{ cursor: 'pointer' }}
                >
                  {site?.name ? site?.name : "PREVIEW" }
                </h1>
              )}
            </div>
          )}
          <nav>
            <ul className="list-items flex-horizontal">
              {filteredPages?.map((filteredPage: any, index: number) => (
                <li
                  key={index}
                  className={`${current_page?.id === filteredPage?.id ? 'active-page' : ''}`}
                >
                  <span
                    onClick={() => { onChangePage(filteredPage) }}
                    style={{ cursor: 'pointer' }}
                  >
                    {filteredPage.name}
                  </span>
                </li>
              ))}
            </ul>
          </nav>
        </nav>
        <nav className="nav-mobile flex-horizontal">
          {!!site?.logo_data ? (
            <div className="header flex-vertical">
              <div className="pro-logo">
                <img src={site?.logo_data?.image_s} alt="Profile's logo" 
                    onClick={() => {
                      if (current_page.page_type === 'blog') {
                        navigate(ApiConfig.BLOG_URL)
                      } else {
                        navigate('/')
                      }
                    }} 
                    style={{cursor:'pointer'}}
                />
              </div>
            </div>
          ) : (
            <div className="header flex-vertical">
            {(site.is_temporary === true || site.is_temporary === 'true') && pages?.[0]?.page_type === "blog_post" ? (
              <h1 className="pro-name text-capitalized" onClick={() => {
                if (current_page.page_type === 'blog') {
                  navigate(ApiConfig.BLOG_URL)
                } else {
                  navigate('/')
                }
              }}
                style={{ cursor: 'pointer' }} >
                PREVIEW
              </h1>
            ) : (
              <h1 className="pro-name text-capitalized"                     
                  onClick={() => {
                    if (current_page.page_type === 'blog') {
                      navigate(ApiConfig.BLOG_URL)
                    } else {
                      navigate('/')
                    }
                  }} 
                  style={{ cursor: 'pointer' }}
              >
                {site?.name ? site?.name : "PREVIEW" }
              </h1>
            )}
          </div>
          )}

          <button className="right-nav-mobile" onClick={() => setOpen(!open)}>
            {open ? <CgClose size={24} /> : <TbMenu2 size={24} />}
          </button>
        </nav>
      </section>
      <ProfileNavMobile
        site={site}
        isOpen={open}
        isLogin={isLogin}
        user={user}
        current_page={current_page}
        onClick={() => setOpen(false)}
        logOut={Logout}
        pages={filteredPages}
        onChangePage={(selectedPage) => {
          onChangePage(selectedPage);
        }}
      />
    </>
  );
};

export default ProfileNavbar;
