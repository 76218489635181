import AccountService from "api/services/AccountService";
import WebsiteService from "api/services/SiteService";
import { ActionKind, GlobalStateInterface } from "store/Reducer";
import { StateContext, StateProvider } from "store/Provider";
import { useContext } from "react";
import UserService from "api/services/UserService";
import ApiConfig from "api/apiConfig";

class UtilityHelper {
  static isObject(value: any) {
    return typeof value === "object" && value !== null && !Array.isArray(value);
  }

  static isObjectEmpty = (object: any) => {
    let isDataPresent = false;
    if (Object.keys(object).length === 0) {
      isDataPresent = false;
    } else {
      for (const key in object) {
        if (this.isPresent(object[key])) {
          isDataPresent = true;
          break;
        } else {
          isDataPresent = false;
        }
      }
    }
    return isDataPresent;
  };

  static isPresent = (item: any) => {
    if (Boolean(item)) {
      if (Array.isArray(item)) {
        return !!item.length;
      } else if (this.isObject(item)) {
        return this.isObjectEmpty(item);
      } else {
        return true;
      }
    } else {
      return false;
    }
  };

  static async initApp(state: any) {
    var fullUrl = window.location.hostname;
    var url;

    if (fullUrl.includes(ApiConfig.MAIN_DOMAIN) || fullUrl.includes(ApiConfig.STAGING_DOMAIN)) {
      url = fullUrl.split('.')[0];
    } else {
      url = fullUrl;
    }

    var website = state.currentWebsite;
    var user = state.currentUser;

    if (this.isPresent(website) && url == website.domain) {
      console.log(website);

      return { website: website, user: user };
    }

    var websites = await WebsiteService.GetWebsite({ domain: url });
    if (websites.length == 0) {
      console.log(website);

      return { website: null, user: null };
    }

    var website = websites[websites.length - 1];
    // var account = (await AccountService.GetAccount(website.account_id))[0];
    // var user = (await UserService.GetUsers({ account_id: account.id }))[0];
    // console.log(website);
    // console.log(account);
    // console.log(user);

    // return { website: website, user: user };
    return { website: website, };

  }

  static getTheme() {
    var url = window.location.hostname.split(".")[0];
    if (url.includes("julia")) {
      return "standard";
    } else if (url.includes("yosha")) {
      return "yosha";
    } else if (url.includes("womeninlaw")) {
      return "yosha";
    } else if (url.includes("justine")) {
      return "default justine";
    } else if (url.includes("freemium")) {
      return "freemium";
    } else if (url.includes("intenxfitness")) {
      return "intenxfitness";
    } else if (url.includes("rimalchhetri")) {
      return "default rimal";
    } else if (url.includes("somebodysgun")) {
      return "default somebodysgun";
    }

    return "default";
  }
}

export default UtilityHelper;
