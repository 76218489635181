import { useState } from "react";
import "./style.scss";
import { Theme } from "pages/Home";
import BioLeft from "./components/BioLeft";
import BioStandard from "./components/BioStandard";
import BioJames from "./components/BioJames";
import BioDefaultRimal from "./components/BioDefaultRimal";
import BioDefaultTaylor from "./components/BioDefault copy";
import BioCenter from "./components/BioCenter";
import BioDefault from "./components/BioItalic";
import BioItalic from "./components/BioItalic";

interface InfoProps {
  section: any;
  theme: Theme | String;
  site: any;
}

function Info({ section, theme, site }: Partial<InfoProps>) {
  const renderBio = () => {
    switch (theme) {
      case "standard":
        return <BioStandard website={site} section={section} />;
      case "freemium":
        return <BioJames section={section} site={site} />;
      case "default rimal":
        return <BioDefaultRimal website={site} section={section} site={site} />;
      case "default somebodysgun":
        return <BioDefaultTaylor website={site} section={section} site={site} />;
      default:
        if (section.variant == "italic") {
          return <BioItalic theme={theme} section={section} site={site} />;
        }
        return <BioCenter theme={theme} section={section} site={site} />;
    }
  };

  if (
    Array.isArray(section?.data) &&
    section?.data?.length !== 0 &&
    !!section?.data[0]?.description
  ) {
    return <>{renderBio()}</>;
  } else {
    return <></>;
  }
}

export default Info;
