import React, { useEffect, useState } from 'react';
import { GoogleOAuthProvider, useGoogleLogin, } from '@react-oauth/google';
import AuthService from 'api/services/authService';
import { UserCircle2 } from 'lucide-react';
// import GoogleLogo from "assets/flat-color-icons_google.png"
import GoogleLogo from "assets/icons/google-logo 2.png"
import CastleBirdLogo from "assets/logo.png"
import { useLocation, useNavigate } from 'react-router-dom';
import UtilityHelper from 'helpers/UtilityHelper';
import "../login/style.scss"
import BillingService from 'api/services/BillingService';
import ApiConfig from 'api/apiConfig';
import LoadingSuspense from 'components/Suspense';
import TrackerHelper from 'helpers/TrackerHelper';

const LoginPage: React.FC = () => {
    const navigate = useNavigate();
    const location = useLocation();
    // const { feature_selected, plan_code } = location.state || { feature_selected: null, plan_code: null };
    const queryParams = new URLSearchParams(location.search);
    const email = queryParams.get('email');
    const plan = queryParams.get('plan');
    const feature = queryParams.get('feature');
    const [isLoading, setIsLoading] = useState(false);

    const login = useGoogleLogin({
        onSuccess: async tokenResponse => {
            const data = await AuthService.loginGoogle(tokenResponse);
            if (UtilityHelper.isPresent(plan) || UtilityHelper.isPresent(feature)) {
                navigate('/subscription/form-google', { state: { planName: plan, statusLogin: true, featureSelected: feature } });
            } else {
                navigate('/subscription',);
            }

        },
        scope: 'https://www.googleapis.com/auth/userinfo.profile https://www.googleapis.com/auth/userinfo.email',
        flow: 'implicit'
    });

    const handleLoginAsGuest = () => {
        // navigate('/subscription/form', { state: { statusLogin: false, plan_code: plan_code, feature_selected: feature_selected } });
        navigate('/subscription/form-payment-guest', { state: { statusLogin: false, planName: plan, featureSelected: feature, userEmail: email } });

    }

    const buildTitleAndDescription = () => {
        if (feature == null || plan == null) {
            return <div> <div className='main-title'>

                Grow your <span>business effortlessly</span> <br />with the <span> {plan}</span></div>
                <div className='description'>Sign in to access premium features and start converting your web visitors.</div></div>

        } else {
            switch (feature) {
                case 'Custom Domain':
                    return <div> <div className='main-title'>
                        Get your <span>Custom Domain</span> with the <span> {plan}</span>
                    </div>
                        <div className='description'>Sign in to unlock premium features, including Advanced Website Metrics, On-Page SEO Tools, and more.
                        </div></div>
                case 'SEO Optimization Tools':
                    return <div> <div className='main-title'>
                        Attract more <span>Qualified Visitors</span> with the <span> {plan}</span>
                    </div>
                        <div className='description'>Sign in to unlock premium features, including Advanced Website Metrics, a custom domain, and more.
                        </div></div>
                case 'Advanced Website Metrics':
                    return <div> <div className='main-title'>
                        Get your <span>Audience Insights</span> with the <span> {plan}</span>

                    </div>
                        <div className='description'>Sign in to unlock premium features, including On-Page SEO tools, a custom domain, and more.
                        </div></div>
            }
        }

    }



    useEffect(() => {
        const userInfo = AuthService.GetCurrentUser();
        if (userInfo) {
            setIsLoading(true)
            BillingService.GetSubscription().then((data) => {
                if (data) {
                    if (data.response && data.response.status == 401) {
                        setIsLoading(false)
                        AuthService.SaveCurrentUser(null);
                        AuthService.SaveCurrentAccount(null);
                        AuthService.Logout();
                    } else {
                        navigate('/subscription/form-google', { state: { planName: plan, statusLogin: true, featureSelected: feature }, replace: true });
                    }
                } else {
                    navigate('/subscription/form-google', { state: { planName: plan, statusLogin: true, featureSelected: feature }, replace: true });
                }

            });
        }
    }, []);

    useEffect(() => {
        TrackerHelper.virtualPage("/login_subscription", "Login Subscription")
    }, []);
    
    if (isLoading) {
        return <LoadingSuspense />;
    }
    return (
        <div className='container-main flex items-center justify-center' style={{}} >
            <div className='main-content-login-page' style={{ width: '422px' }}>
                <div className='logo-container'><img src={CastleBirdLogo} /></div>
                <div className='center-container'>
                    {buildTitleAndDescription()}
                    {/* <div className='description'>Sign in with your CastleBird account to upgrade and start using <br /> our premium features like a pro business</div> */}
                    <GoogleOAuthProvider clientId="821735858312-8ggqo42192gf2udhueoc7s2ufduiokhs.apps.googleusercontent.com">
                        <button onClick={() => login()} className="google-login-button">
                            {/* <Chrome className="mr-2 h-4 w-4" /> */}
                            <div className="google-login-button-icon"> <img src={GoogleLogo} /></div>

                            <div className='google-login-button-text'>Continue with Google</div>
                        </button>
                    </GoogleOAuthProvider>
                    <div className='divider'>
                        <div className='divider-line'></div>
                        <span className='divider-text'>or</span>
                        <div className='divider-line'></div>
                    </div>
                    <button onClick={() => handleLoginAsGuest()} className="guest-login-button">
                        <div className='guest-button-text'>Continue as Guest</div>
                    </button>

                </div>
                <div className='policy-text'>By clicking “Sign in with Google”, you agree to our <br />
                    <a href={"https://" + ApiConfig.MAIN_DOMAIN + "/terms-of-service"}>Terms of Service</a> and <a href={"https://" + ApiConfig.MAIN_DOMAIN + "/privacy"}>Privacy Policy</a></div>
            </div>
        </div>

    );
};

export default LoginPage;