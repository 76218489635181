import { JSX, useEffect, useState } from 'react';
import LifeBuoyIcon from "assets/icons/lifebuoy.png";
import MastercardIcon from "assets/icons/icon_card/mastercard.png";
import AELogo from "assets/icons/icon_card/AE.png";
import JCBLogo from "assets/icons/icon_card/JCB.png";
import UnionPayLogo from "assets/icons/icon_card/UnionPay.png";
import VisaLogo from "assets/icons/icon_card/visa.png";
import DinerClubLogo from "assets/icons/icon_card/dinerclub.png";
import DiscoverLogo from "assets/icons/icon_card/discover.png";


import CB_Icon from "assets/logo.png"
import ArrowDown from "assets/icons/CaretDown.png"
import SuccessIcon from "assets/icons/tick-circle.png"
import UpgradePlan from "assets/icons/Arrow 77.png"

import './style.scss';
import BillingService from 'api/services/BillingService';
import LoadingSuspense from 'components/Suspense';
import DialogCancellation from './components/DialogConfirmCancel';
import { useLocation, useNavigate } from 'react-router-dom';
import UtilityHelper from 'helpers/UtilityHelper';
import AuthService from 'api/services/authService';
import CustomerService from 'api/services/CustomerService';
import NavBarBilling from './components/navbar/navbar';
import LogoBrand from './components/Logo_Brand';
import PaymentHelper from 'helpers/PaymentHelper';
import ColorTemple from 'styles/ColorTemple';
import DateTimeHelper from 'helpers/DateTimeHelper';
import ApiConfig from 'api/apiConfig';


interface Invoice {
    id: string;
    number: string;
    issuing_date: string;
    payment_due_date: string;
    payment_overdue: boolean;
    net_payment_term: number;
    invoice_type: string;
    status: string;
    payment_status: string;
    currency: string;
    total_amount_cents: number;
    file_url: string;
}



interface Subscription {
    plan: {
        name: string;
        amount_cents: number;

    };
    next_billing_date: string;
    ending_at: string;
}
interface Customer {
    payment_method_url: string
}

interface PaymentMethod {
    id: string;
    card: {
        last4: string;
        exp_month: number;
        exp_year: number;
        card_brand: string;
    };
}
function PlansAndBilling() {

    const [invoices, setInvoices] = useState<Invoice[]>([]);
    const [subscription, setSubscription] = useState<Subscription | null>(null);
    const [paymentMethod, setPaymentMethod] = useState<PaymentMethod | null>(null);
    // const [customer, setCustomer] = useState<Customer>({
    //     payment_method_url: ''
    // });
    const [loading, setLoading] = useState(true);
    // const [isSubSuccess, setIsSubSuccess] = useState(true);
    const [isSubSuccess, setIsSubSuccess] = useState(false);
    const [isCancelSuccess, setIsCancelSuccess] = useState(false);
    const [isUpdateSuccess, setIsUpdateSuccess] = useState(false);


    const [isDialogOpen, setIsDialogOpen] = useState(false);

    const [isCancelingPlan, setIsCancelingPlan] = useState(false);

    const navigate = useNavigate();
    const location = useLocation();
    const { isCancel, subPlan, isUpdateCard } = location.state || {};

    const handleOpenDialog = () => {
        setIsDialogOpen(true);
    };

    const handleCloseDialog = () => {
        setIsDialogOpen(false);
    };


    const handleUpdateCard = () => {
        navigate('/subscription/form', { state: { existingPaymentMethod: paymentMethod ?? '', statusLogin: true, isAdding: true } });
    }

    const handleViewInvoice = (fileUrl: string) => {
        if (UtilityHelper.isPresent(fileUrl)) {
            window.open(fileUrl, '_blank');

        }
    };

    const handleUpgradePlan = () => {
        navigate(`/subscription/plans`);
    };

    const isMobile = /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent);

    const cancelAnimation = async (callback: () => void) => {
        await new Promise(resolve => setTimeout(resolve, 6000));
        if (callback) {
            callback();
        }
    };



    useEffect(() => {
        setLoading(true);
        fetchData();
        // BillingService.GetCustomer().then(
        //     (customer) => {
        //         setLoading(false);
        //         setCustomer(customer);
        //     }
        // );

        // if (isCancel) {
        //     setIsCancelSuccess(true)
        //     cancelAnimation(() => setIsCancelSuccess(false));
        // }
        // if (UtilityHelper.isPresent(subPlan)) {
        //     setIsSubSuccess(true);
        //     cancelAnimation(() => setIsSubSuccess(false));
        // }
        // if (UtilityHelper.isPresent(isUpdateCard)) {
        //     setIsUpdateSuccess(true);
        //     cancelAnimation(() => setIsUpdateSuccess(false));
        // }

    }, []);

    const fetchData = async () => {
        const paymentMethodsData = await CustomerService.GetPaymentMethods();
        console.log(paymentMethodsData)
        if (paymentMethodsData.response && paymentMethodsData.response.status == 401) {
            setLoading(false);
            AuthService.SaveCurrentUser(null);
            AuthService.SaveCurrentAccount(null);
            AuthService.Logout();
            navigate('/signin', {
                state: { from: location.pathname, previousState: location.state, }, replace: true
            });
        }
        if (paymentMethodsData && paymentMethodsData.length !== 0) {
            if (paymentMethodsData[0]['type'] === 'card') {
                setPaymentMethod({
                    id: paymentMethodsData[0].id,
                    card: {
                        last4: paymentMethodsData[0]['card']['last4'],
                        exp_year: paymentMethodsData[0]['card']['exp_year'],
                        exp_month: paymentMethodsData[0]['card']['exp_month'],
                        card_brand: paymentMethodsData[0]['card']['display_brand']
                    }
                });
            } else if (paymentMethodsData[0]['type'] === "sepa_debit") {
                setPaymentMethod({
                    id: paymentMethodsData[0].id,
                    card: {
                        last4: paymentMethodsData[0]['sepa_debit']['last4'],
                        exp_year: 0,
                        exp_month: 0,
                        card_brand: paymentMethodsData[0]['type']
                    }
                });
            }
        } else {
            setPaymentMethod(null);
        }

        const invoicesData = await BillingService.GetInvoices();
        if (invoicesData.response && invoicesData.response.status == 401) {
            setLoading(false);
            AuthService.SaveCurrentUser(null);
            AuthService.SaveCurrentAccount(null);
            AuthService.Logout();
            navigate('/signin', {
                state: { from: location.pathname, previousState: location.state, }, replace: true
            });
        }
        console.log(invoicesData)

        if (invoicesData && invoicesData.length != 0) {
            setInvoices(invoicesData);
        }

        const subscriptionData = await BillingService.GetSubscription();
        if (subscriptionData) {
            if (subscriptionData.response && subscriptionData.response.status == 401) {
                setLoading(false);
                AuthService.SaveCurrentUser(null);
                AuthService.SaveCurrentAccount(null);
                AuthService.Logout();
                navigate('/signin', {
                    state: { from: location.pathname, previousState: location.state, }, replace: true
                });
            }
            if (UtilityHelper.isPresent(subscriptionData['ending_at'])) {
                setIsCancelingPlan(true);
            }
            setSubscription(subscriptionData);
        } else {
            setSubscription({
                plan: {
                    name: 'Free Plan',
                    amount_cents: 0,
                },
                ending_at: "",
                next_billing_date: ""
            });
        }
        setLoading(false);
    };


    const setHome: () => JSX.Element = () => {
        return (
            <div style={{ backgroundColor: '#F9FAFC' }}>
                <NavBarBilling />

                {/*Notification success */}
                {isSubSuccess
                    ?
                    <div className='flex flex-row p-4 rounded-lg items-center' style={{ backgroundColor: "#F0FDF6", boxShadow: '0px 15px 20px 0px #00000005, 0px 5px 10px 0px #00000005', marginLeft: "120px", marginRight: "120px" }}>
                        <div className='pr-4'>
                            <img src={SuccessIcon} style={{ width: "24px", display: "block" }} />
                        </div>
                        <div className='font-semibold' style={{ color: "#32A071", fontSize: "16px", lineHeight: "19.36px" }}>You have successfully subscribed to {subscription?.plan.name}</div>
                    </div>
                    : <></>
                }
                {/*Notification success */}
                {isUpdateSuccess
                    ?
                    <div className='flex flex-row p-4 mt-4 rounded-lg items-center' style={{ backgroundColor: "#F0FDF6", boxShadow: '0px 15px 20px 0px #00000005, 0px 5px 10px 0px #00000005', marginLeft: "120px", marginRight: "120px" }}>
                        <div className='pr-4'>
                            <img src={SuccessIcon} style={{ width: "24px", display: "block" }} />
                        </div>
                        <div className='font-semibold' style={{ color: "#32A071", fontSize: "16px", lineHeight: "19.36px" }}>You have successfully update your payment method</div>
                    </div>
                    : <></>
                }
                {/*Cancel success */}
                {isCancelSuccess
                    ?
                    <div className='flex flex-row p-4 rounded-lg items-center' style={{ backgroundColor: "#F0FDF6", boxShadow: '0px 15px 20px 0px #00000005, 0px 5px 10px 0px #00000005', marginLeft: "120px", marginRight: "120px" }}>
                        <div className='pr-4'>
                            <img src={SuccessIcon} style={{ width: "24px", display: "block" }} />
                        </div>
                        <div className='font-semibold' style={{ color: "#32A071", fontSize: "16px", lineHeight: "19.36px" }}>You have successfully cancellation to {subscription?.plan.name}</div>
                    </div>
                    : <></>
                }
                <div className='main-content-billing-main'>

                    <div style={{
                        display: 'flex',
                        flexDirection: 'row',
                        justifyContent: "space-between",
                        alignItems: 'center',
                        marginBottom: '48px',
                    }}>
                        <div >
                            <h1 style={{ fontWeight: 'bold' }}>Plan and Billing</h1>
                            <h5 style={{ fontWeight: 'bold', color: '#696969' }}>Manage your plan and billing history here</h5>
                        </div>
                        {/* Need Help TAB */}
                        {/* <div className="help-button">
                            <div> <img
                                src={LifeBuoyIcon}
                                loading="lazy"
                                style={{
                                    marginRight: '8px', height: '24px', width: '24px', display: 'block'
                                }}
                            /></div>
                            <h5 style={{ fontWeight: 'bold', margin: 0 }}>Need help?</h5>
                        </div> */}
                    </div>
                    <div className="grid grid-cols-1 md:grid-cols-2 gap-6 mb-8">
                        {/* Growth Plan */}
                        <div className="bg-white" style={{ border: '1.5px solid #E5E5E5', borderRadius: '12px', }}>
                            <div className='flex flex-col justify-between items-start mb-3 px-6 py-5 rounded-2xl' style={{ background: subscription?.plan.name == 'Free Plan' || subscription === null ? "#e5e5e5" : "linear-gradient(90deg, #4991F6 0%, #285FF6 100%)", color: subscription?.plan.name == 'Free Plan' || subscription === null ? 'black' : 'white' }}>
                                <div style={{ fontWeight: "600", fontSize: "12px", lineHeight: "16.8px", marginBottom: "4px" }}>Your current plan</div>
                                <div style={{ fontWeight: "700", fontSize: "28px", lineHeight: "33.6px", }}>{subscription?.plan.name}</div>
                            </div>
                            <div style={{}} className='p-6'>
                                <div className="text-4xl font-bold mb-5 items-center">
                                    {subscription?.plan.name == 'Free Plan' || subscription === null
                                        ? <div style={{ fontWeight: '500', fontSize: "16px", lineHeight: "22.4px", color: "black" }}>
                                            Unlock all premium features.<br></br>
                                            Starting from only $7 per month — Cancel anytime.
                                        </div>
                                        :
                                        isCancelingPlan ? <span style={{ fontSize: '16px', marginRight: "16px", color: '#616161', fontWeight: '500', lineHeight: "21px" }}>
                                            Your subscription of <span style={{ fontWeight: '700', color: "black" }}>{subscription?.plan.name}</span> will end on <span style={{ fontWeight: 'bold', color: "black" }}>{PaymentHelper.formatDate(subscription?.ending_at!)}</span>.<br></br>You will not be charged again.
                                        </span> : <div style={{}}>
                                            <div style={{ fontWeight: "600", fontSize: "16px", lineHeight: "22.4px", color: "black" }}>  Your next bill is for <span style={{ fontWeight: "700", color: "#0B86E7" }}>${subscription.plan.amount_cents / 100}</span> on <span style={{ fontWeight: "700", color: "#0B86E7" }}>{PaymentHelper.formatDate(subscription?.next_billing_date!)}</span></div>
                                            <div style={{ fontWeight: "500", fontSize: "14px", lineHeight: "19.6px", color: "#616161" }}>   You can cancel anytime before this date.</div>


                                        </div>

                                    }
                                </div>
                                <hr></hr>
                                <div className="flex flex-row justify-between items-center space-x-4" style={{ height: "fit-content" }}>
                                    {subscription?.plan.name == 'Free Plan' || subscription === null ?
                                        <div>

                                        </div>
                                        : isCancelingPlan ? <div style={{ fontWeight: '500', fontSize: "14px" }}>
                                            You can reactivate your subscription at any time to regain access to premium features.
                                        </div> : <></>
                                    }


                                    <DialogCancellation isOpen={isDialogOpen} onClose={handleCloseDialog} planName={subscription?.plan.name ?? ''} />

                                    {
                                        subscription?.plan.name == 'Free Plan' || subscription === null ?

                                            <div className="help-button flex flex-row items-center" style={{ backgroundColor: "#0B86E7", borderRadius: "26px", padding: "10px 16px", }} onClick={handleUpgradePlan}>
                                                <div style={{ fontWeight: '600', fontSize: '14px', margin: 0, color: 'white' }}>Explore plan</div>
                                                <img
                                                    src={UpgradePlan}
                                                    loading="lazy"
                                                    style={{
                                                        marginRight: "0px", marginLeft: "8px", width: "17px", height: "17px", objectFit: "contain"
                                                    }}
                                                />
                                            </div>

                                            : isCancelingPlan ? <div className="help-button" style={{ border: ' 0px solid #ccc', backgroundColor: "#0B86E7", borderRadius: "26px", padding: "10px 16px", }} onClick={handleUpgradePlan}>
                                                <h5 style={{ fontWeight: 'bold', margin: 0, color: 'white' }}>Resubscribe</h5></div> : <div onClick={handleOpenDialog} className=" text-gray-800 px-4 py-2 ml-auto b" style={{ border: ' 1px solid #ccc', fontWeight: '500', cursor: 'pointer', justifyItems: 'end', borderRadius: '26px' }}>Cancel subscription</div>

                                    }
                                </div>
                            </div>

                        </div>
                        {/* Payment Method */}

                        {paymentMethod ? <div className="bg-white p-6" style={{ border: '1.5px solid #E5E5E5', borderRadius: '12px', height: "fit-content" }}>
                            <div className='flex flex-row justify-between items-center'>
                                <div>
                                    <h2 className="mb-1" style={{ fontSize: "18px", fontWeight: "600", lineHeight: '21.6px', color: "black" }} >Payment method</h2>
                                    <p className="mb-4" style={{ fontSize: "14px", fontWeight: "500", lineHeight: '21px', color: "#616161" }}>Manage how you pay for your plan</p>
                                </div>
                                <div className="help-button" style={{ borderRadius: '32px', padding: '10px 18px', marginLeft: "24px" }} onClick={handleUpdateCard}>
                                    <h5 style={{ fontWeight: '600', fontSize: "14px", lineHeight: '16.9px', color: 'black', textAlign: 'center' }}>Update card</h5>
                                </div>
                            </div>
                            <div className="flex justify-between items-center p-4" style={{ backgroundColor: "#f5f5f5", borderRadius: '12px' }}>
                                <div className='flex items-center'  >
                                    <div>
                                        <LogoBrand card_brand={paymentMethod?.card.card_brand ?? ""} />
                                    </div>
                                    {paymentMethod?.card.card_brand === 'sepa_debit'
                                        ? <div>
                                            <div className='font-semibold mb-0' style={{ fontSize: '16px', color: '#2C2C2C' }}>Your Sepa card is: ••••{paymentMethod!.card.last4}</div>
                                        </div>
                                        : <div>
                                            <p className='font-semibold mb-0' style={{ fontSize: '16px', color: '#2C2C2C' }}>{PaymentHelper.getBrandName(paymentMethod!.card.card_brand)} ending in {paymentMethod!.card.last4}</p>
                                            <p style={{ color: '#696969', fontSize: '14px', fontWeight: 500 }}>Expiry on {PaymentHelper.formatDateExpiry(paymentMethod!.card.exp_month)}/{paymentMethod!.card.exp_year}</p>
                                        </div>
                                    }

                                </div>

                            </div>
                        </div> : <div className="flex justify-between items-center p-6" style={{ border: '1.5px solid #E5E5E5', borderRadius: '12px', height: "95px" }}>
                            <div className='flex items-center'  >
                                <div>
                                    <h2 className="text-xl font-semibold mb-1">Payment method</h2>
                                    <p className="text-gray-600">Manage how you pay for your plan</p>
                                </div>
                            </div>
                            <div className="help-button" >
                                <h5 style={{ fontWeight: 'bold', margin: 0, color: '#0B86E7' }} onClick={handleUpdateCard}>Add new card</h5>
                            </div>
                        </div>}

                    </div>
                    {/* Invoice History */}
                    <h2 className="text-2xl font-bold mb-4" style={{ lineHeight: "29.04px" }}>Invoice history</h2>
                    <p className="text-gray-600 mb-4">Access to all your previous invoices</p>
                    {isMobile
                        ? <div
                            style={{
                                backgroundColor: "white",
                                borderRadius: "16px",
                                boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.1)",
                            }}
                        >
                            <table style={{ width: "100%", borderCollapse: "collapse" }}>
                                <thead>
                                    <tr className="border-b " >

                                        <th className="py-4 pl-5 text-left font-bold" style={{ backgroundColor: "#F2F2F2", }}>Invoice</th>
                                        <th className="py-2 text-left font-bold" style={{ backgroundColor: "#F2F2F2" }}>Status</th>
                                        <th className="py-2 text-center font-bold" style={{ backgroundColor: "#F2F2F2" }}>Action</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {invoices.map((invoice, index) => (
                                        <tr key={index} style={{
                                            borderBottom: "1px solid #E5E7EB",
                                        }}>
                                            {/* Cột A */}
                                            <td
                                                style={{
                                                    padding: "12px",
                                                    fontWeight: "600",
                                                    fontSize: "14px",

                                                }}
                                            >
                                                <div style={{
                                                    display: "flex",
                                                    flexDirection: "column",
                                                    justifyContent: "center",
                                                    alignItems: "flex-start",
                                                }}>
                                                    <div>{invoice.number}</div>
                                                    <div>
                                                        {invoice.currency} ${invoice.total_amount_cents / 100}
                                                    </div>
                                                    <div>{DateTimeHelper.convertDateWMonth(invoice.payment_due_date)}</div>
                                                </div>
                                            </td>

                                            {/* Cột B */}
                                            <td
                                                style={{
                                                    padding: "12px",
                                                    fontWeight: "600",
                                                    fontSize: "14px",
                                                    textAlign: "left",
                                                }}
                                            >
                                                <div
                                                    style={{
                                                        display: "flex",
                                                        flexDirection: "column",
                                                        justifyContent: "center",
                                                        alignItems: "flex-start",
                                                    }}
                                                > <span
                                                    style={{
                                                        padding: "8px 12px",
                                                        borderRadius: "16px",
                                                        display: "inline-block",
                                                        backgroundColor:
                                                            invoice.payment_status === "succeeded"
                                                                ? "#CEF8E0"
                                                                : "#FFBD0042",
                                                        color:
                                                            invoice.payment_status === "succeeded" ? "#007A4D" : "#DA8600",
                                                        fontWeight: "600",
                                                        fontSize: "14px",
                                                    }}
                                                >
                                                        {invoice.payment_status === "succeeded" ? "Paid" : "Pending"}
                                                    </span></div>

                                            </td>

                                            {/* Cột C */}
                                            <td
                                                style={{
                                                    padding: "12px",
                                                    textAlign: "center",
                                                }}
                                            >
                                                <div
                                                    style={{
                                                        display: "flex",
                                                        flexDirection: "column",
                                                        justifyContent: "center",
                                                        alignItems: "flex-start",
                                                    }}
                                                >  <button
                                                    style={{
                                                        margin: "0",
                                                        padding: "8px 16px",
                                                        borderRadius: "8px",
                                                        border: "1px solid #ccc",
                                                        backgroundColor: "white",
                                                        color: "#0B86E7",
                                                        fontWeight: "600",
                                                        cursor: "pointer",
                                                        height: "fit-content"
                                                    }}
                                                    onClick={() => handleViewInvoice(invoice.file_url)}
                                                >
                                                        View Invoice
                                                    </button></div>

                                            </td>
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                        </div>

                        : <div className="bg-white  rounded-xl shadow pb-3">
                            <table className="w-full justify-center">
                                <thead>
                                    <tr className="border-b " >

                                        <th className="py-4 pl-5 text-left font-bold" style={{ backgroundColor: "#F2F2F2" }}>Invoice</th>
                                        <th className="py-2 text-left font-bold" style={{ backgroundColor: "#F2F2F2" }}>Amount</th>
                                        <th className="py-2 text-left font-bold" style={{ backgroundColor: "#F2F2F2" }}>Billing date</th>
                                        <th className="py-2 text-left font-bold" style={{ backgroundColor: "#F2F2F2" }}>Status</th>
                                        <th className="py-2 text-center font-bold" style={{ backgroundColor: "#F2F2F2" }}>Action</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        invoices.map((invoice, index) => (
                                            <tr key={index} className="border-b justify-center">

                                                <td className="py-4 pl-5 text-left font-semibold text-base">{invoice.number}</td>
                                                <td className="py-2 text-left  font-semibold text-base">{invoice.currency} $ {invoice.total_amount_cents / 100}</td>
                                                <td className="py-2 text-left  font-semibold text-base">{DateTimeHelper.convertDateWMonth(invoice.payment_due_date)}</td>
                                                <td className="py-2 text-left">
                                                    <span
                                                        className={`px-3 py-1 rounded-3xl bg-opacity-25 `} style={{ fontWeight: 600, fontSize: '14px', backgroundColor: invoice.payment_status === 'succeeded' ? '#CEF8E0' : '#FFBD0042', color: invoice.payment_status === 'succeeded' ? '#007A4D' : '#DA8600' }}
                                                    >
                                                        {invoice.payment_status === 'succeeded' ? 'Paid' : 'Pending'}
                                                    </span>
                                                </td>
                                                <td className="py-2 text-center">
                                                    <button className="px-6 py-3 rounded-lg" onClick={() => handleViewInvoice(invoice.file_url)} style={{ border: '1px solid #ccc', color: '#0B86E7', fontWeight: '600' }}>View invoice</button>

                                                </td>
                                            </tr>

                                        ))
                                    }
                                </tbody>
                            </table>
                            {invoices.length === 0
                                ? <div className='flex flex-row justify-center'><h5 style={{ color: '#696969' }} className='font-semibold'>You don’t have any invoices yet</h5></div>
                                : <></>}


                            {/* <div className="flex flex-col px-4 md:flex-row justify-between items-center mt-4">
                        <div className="flex items-center mb-4 md:mb-0">
                            <span className="mr-2">Showing</span>
                            <select className="border rounded px-2 py-1">
                                <option>10</option>
                                <option>20</option>
                                <option>30</option>
                            </select>
                            <span className="ml-6">invoices per page</span>
                        </div>
                        <div className="flex space-x-2">
                            <button className="px-3 py-1 border rounded" style={{ color: "black" }}>1</button>
                            <button className="px-3 py-1 border rounded" style={{ color: "black" }}>2</button>
                            <button className="px-3 py-1 border rounded" style={{ color: "black" }}>3</button>
                            <button className="px-3 py-1 border rounded" style={{ color: "black" }}>4</button>
                        </div>
                    </div> */}
                        </div>
                    }

                </div>
            </div>
        );
    };

    return <>{loading ? <LoadingSuspense /> : setHome()}</>;
}

export default PlansAndBilling;
