import "./style.scss";
import Logo from "assets/logo.svg";
import Facebook from "assets/Footer/facebook.png";
import Twitter from "assets/Footer/twitter.png";
import Linkedin from "assets/Footer/linkedin.png";
import { Link, useParams } from "react-router-dom";
import { BsArrowUpShort } from "react-icons/bs";
import { LegacyRef, ReactNode, forwardRef } from "react";
import { IoArrowUpOutline } from "react-icons/io5";
import { Theme } from "pages/Home";

interface Props {
  pages: any;
  onChangePage: any;
  site: any;
  theme: Theme | String;
}
export type Ref = HTMLDivElement;
const Footer = forwardRef<Ref, Props>(
  ({ pages, site, onChangePage, theme }, ref) => {
    console.log("site data:", site); // 👉 Log site ở đây, khi Footer được render

    return (
      <footer className={`footer flex-vertical default ${theme} bg-- `}>
        <div ref={ref} className="navigation flex-horizontal container" style={{ display: 'flex', justifyContent: 'space-between' }}>
        <div className="text">
            {(site.is_temporary === true || site.is_temporary === 'true') && site?.business_name === null ? (
              <h1 className="pro_name text-uppercase">Preview.</h1>
            ) : (
              <h1 className="pro_name text-uppercase">{site?.name}.</h1>
            )}
            {(site.is_temporary === true || site.is_temporary === 'true') && site?.business_name === null ? (
              <div className="copy-right type--fine-print">
                Designed by Preview. | Powered by CastleBird
              </div>
            ) : (
              <div className="copy-right type--fine-print">
                Designed by {site?.name} | Powered by CastleBird
              </div>
            )}
          </div>
        </div>
      </footer>
    );
  }
);

export default Footer;
