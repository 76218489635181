import { useEffect, useRef, useState } from 'react';
import LifeBuoyIcon from "assets/icons/lifebuoy.png";
import MastercardIcon from "assets/icons/Card logo.png";
import CB_Icon from "assets/logo.png"
import ArrowDown from "assets/icons/CaretDown.png"
import AvatarTemp from "assets/PublicProfile/hero/avatar.png"
import SuccessIcon from "assets/icons/tick-circle.png"
import AddIcon from "assets/icons/add.png"
import ArrowLeft from "assets/icons/arrow-left1.png"
import SafeIcon from "assets/icons/shield.png"
import RefundLogo from "assets/icons/money-back-guarantee.png"
import '../style.scss';
import BillingService from 'api/services/BillingService';
import LoadingSuspense from 'components/Suspense';
import { useLocation, useNavigate } from 'react-router-dom';
import UtilityHelper from 'helpers/UtilityHelper';
import AuthService from 'api/services/authService';
import CustomerService from 'api/services/CustomerService';
import { loadStripe, Stripe } from '@stripe/stripe-js';
import { Elements, PaymentElement, useStripe, useElements } from '@stripe/react-stripe-js';
import NavBarBilling from '../components/navbar/navbar';
import ColorTemple from 'styles/ColorTemple';
import React, { forwardRef, useImperativeHandle } from 'react';
import NarBarWelcomeFlow from '../components/navbar/navbarSignIn';
import { background } from '@chakra-ui/react';
import LogoBrand from '../components/Logo_Brand';
import PaymentHelper from 'helpers/PaymentHelper';
import DateTimeHelper from 'helpers/DateTimeHelper';
import BillingHelper from 'helpers/BillingHelper';
import TrackerHelper from 'helpers/TrackerHelper';
const stripePromise = loadStripe('pk_test_qgwHJ9FIdwVHzkXxkhUwhHve');


interface PaymentMethod {
    id: string; // The ID of the existing payment method
    card: {
        brand: string;
        last4: string;
        exp_year: number;
        exp_month: number;
    };
}

interface Plan {
    code: string;
    name: string;
    interval: string;
    description: string;
    amount_cents: number;
    amount_currency: string;
    trial_period: number;
}

interface LocationState {
    existingPaymentMethod?: PaymentMethod;
}

type AddEditFormProps = {
    ref: any;
    plan: Plan;
};
const AddEditForm: React.FC<AddEditFormProps> = forwardRef(({ plan }, ref) => {
    const stripe = useStripe();
    const elements = useElements();
    const location = useLocation();
    const { statusLogin, planName, isAdding, featureSelected } = location.state || { statusLogin: true, planName: '', isAdding: false, featureSelected: '' };



    const handleSubmit = async (event: React.FormEvent) => {

        if (stripe && elements) {
            const result = await stripe.confirmSetup({
                elements,
                confirmParams: {
                    return_url: window.location.origin + `/stripe/intermediate?plan=${planName}&login=${statusLogin}&featureSelected=${featureSelected}`,
                }
            });
            if (result.error) {
                console.error('Error:', result.error.message);

                if (result.error.code === 'postal_code_check_fail') {
                    console.error('Postal code verification failed.');
                }
            } else {
                console.log('Setup successful:', result.error);
            }
        }
        console.log("END")
    };


    // Dùng useImperativeHandle để expose function ra bên ngoài
    useImperativeHandle(ref, () => ({
        handleSubmit
    }));

    return (
        <PaymentElement />
    );
});

// // 

const LoggedinForm: React.FC = () => {
    const location = useLocation(); // Get location without type argument
    const navigate = useNavigate();
    const [clientSecret, setClientSecret] = useState<string | undefined>(undefined);
    const { planName, featureSelected } = location.state || { planName: '', featureSelected: '' };
    const formRef = useRef<any>(null);
    const [paymentMethod, setPaymentMethod] = useState<PaymentMethod | null>(null);
    const [selectedMethod, setSelectedMethod] = useState('exist');

    const [isLoading, setIsLoading] = useState(false);
    const [plan, setPlan] = useState<Plan>({
        code: '',
        name: '',
        interval: '',
        description: '',
        amount_cents: 0,
        amount_currency: '',
        trial_period: 0,
    });


    const handleSubscribe = (event: React.FormEvent) => {
        if (selectedMethod === 'new') {
            event.preventDefault();
            if (formRef.current) {
                formRef.current.handleSubmit();
            }

        } else {
            BillingService.SignUpPlan(plan.code, '').then((e) => {
                if (UtilityHelper.isPresent(e)) {
                    navigate('/subscription/subscribe-successs/subscribeSuccess', { state: { planName: planName, statusLogin: true, featureSelected: featureSelected }, replace: true });
                }
            })

        }
    };
    const handleAddNew = async () => {

        if (selectedMethod === 'new') {
            setSelectedMethod('exist')
        } else {
            setSelectedMethod('new')

        }
    }


    useEffect(() => {
        setIsLoading(true)

        BillingService.GetPlans().then(
            (plans) => {
                const defaultPlan = BillingHelper.getFirstPlanNotFree(plans);
                if (defaultPlan) {
                    setPlan(defaultPlan);
                } else {
                    setPlan(plans[0])
                }
                plans.forEach((e: Plan) => {
                    if (e.name === planName) {
                        setPlan(e);
                        return;
                    }
                });
            }
        );
        fetchData();


    }, []);

    useEffect(() => {
        TrackerHelper.virtualPage("/subscription/form-payment-login-google", "Payment to upgrade plan")
    }, []);

    const fetchData = async () => {

        CustomerService.GetSetupIntent(null).then((data) => {
            if (data.response && data.response.status == 401) {
                setIsLoading(false);
                AuthService.SaveCurrentUser(null);
                AuthService.SaveCurrentAccount(null);
                AuthService.Logout();
                navigate('/signin', {
                    state: { from: location.pathname, previousState: location.state, }, replace: true
                });

            } else {
                setClientSecret(data.client_secret)
            }
        });
        const paymentMethodsData = await CustomerService.GetPaymentMethods();
        console.log(paymentMethodsData)
        if (paymentMethodsData.response && paymentMethodsData.response.status == 401) {
            setIsLoading(false);
            AuthService.SaveCurrentUser(null);
            AuthService.SaveCurrentAccount(null);
            AuthService.Logout();
            navigate('/signin', {
                state: { from: location.pathname, previousState: location.state, }, replace: true
            });
        }
        if (paymentMethodsData && paymentMethodsData.length === 0) {
            setSelectedMethod('new')
        } else

            if (paymentMethodsData[0]['type'] === 'card') {
                setPaymentMethod({
                    id: paymentMethodsData[0].id,
                    card: {
                        last4: paymentMethodsData[0]['card']['last4'],
                        exp_year: paymentMethodsData[0]['card']['exp_year'],
                        exp_month: paymentMethodsData[0]['card']['exp_month'],
                        brand: paymentMethodsData[0]['card']['display_brand']
                    }
                });
            } else if (paymentMethodsData[0]['type'] === 'sepa_debit') {
                setPaymentMethod({
                    id: paymentMethodsData[0].id,
                    card: {
                        last4: paymentMethodsData[0]['sepa_debit']['last4'],
                        exp_year: 0,
                        exp_month: 0,
                        brand: paymentMethodsData[0]['type']
                    }
                });
            } else {
                setPaymentMethod(null);
            }
        setIsLoading(false);
    };

    const buildPaymentNew = () => {
        return <Elements stripe={stripePromise} options={{ clientSecret }}>
            <div className='flex flex-row justify-start items-center' style={{ marginBottom: "16px" }}>
                <div onClick={handleAddNew} style={{ height: "24px", width: "24px", marginRight: "12px", cursor: "pointer", borderRadius: "6px", }} className='hover:bg-gray-200'> <img src={ArrowLeft}></img></div>
                <div style={{ fontWeight: "500", fontSize: "14px", color: "black", }}> Enter payment details
                </div>
            </div>

            <AddEditForm ref={formRef} plan={plan} />

        </Elements>
    };
    const buildPaymentExist = () => {

        return <div className='flex flex-col'>
            <label htmlFor="email" className="block" style={{ fontWeight: "500", fontSize: "14px", color: "black", marginBottom: '16px' }}>
                Choose your method
            </label>
            {paymentMethod !== null ?
                <div style={{ padding: "16px 20px", borderRadius: "8px", border: "#c4c4c4 1px solid", boxShadow: "0px 4px 8px 0px #00000014", }} className='flex flex-row justify-start items-center'>
                    <div style={{ width: "auto", height: "38px", }}>   <LogoBrand card_brand={paymentMethod?.card.brand ?? ""} /></div>
                    <div className='flex flex-col'>
                        <div style={{ fontWeight: "700", fontSize: "18", color: "#2c2c2c" }}>{PaymentHelper.getBrandName(paymentMethod!.card.brand)} ending in {paymentMethod!.card.last4}</div>
                        <div style={{ color: '#696969', fontSize: '14px', fontWeight: 500 }}>Expiry on {PaymentHelper.formatDateExpiry(paymentMethod!.card.exp_month)}/{paymentMethod!.card.exp_year}</div>

                    </div>
                </div>
                : <></>
            }

            <div onClick={handleAddNew} style={{ padding: "16px 20px", borderRadius: "8px", border: "#D8D8D8 1px dashed", boxShadow: "0px 4px 8px 0px #00000014", marginTop: "16px", userSelect: "none", cursor: "pointer" }} className='flex flex-row justify-center items-center'>
                <div style={{ width: "24px", height: "24px", }}>  <img src={AddIcon}></img></div>
                <div style={{ color: ColorTemple.gray61, fontSize: '15px', fontWeight: 500 }}>Add new</div>

            </div>
        </div>
    };

    if (!clientSecret || isLoading) {
        return <LoadingSuspense />;
    }

    return (
        <>
            <NarBarWelcomeFlow selected_feature={featureSelected} ></NarBarWelcomeFlow>

            <div className='container-main-payment' >
                <div className='title'>Finalize your order for {plan.name}</div>
                <div className='main-content'>
                    <div style={{ backgroundColor: "white", borderRadius: "16px", boxShadow: "0px 4px 50px 0px #0000001A", padding: "32px", width: "581px", height: 'fit-content' }}>

                        {selectedMethod === 'new' ? buildPaymentNew() : buildPaymentExist()
                        }


                    </div>
                    <div className='order-summary'>
                        <div className='order-title'>ORDER SUMMARY</div>
                        <div className='order-item' >
                            <div className='order-feature-title '>{plan.name.toUpperCase()} FEATURES:</div>
                            <div className='order-feature-item'><span style={{ fontSize: "20px" }}>• </span> Free Custom Domain</div>
                            <div className='order-feature-item'><span style={{ fontSize: "20px" }}>• </span> SEO Optimization Tools</div>
                            <div className='order-feature-item'><span style={{ fontSize: "20px" }}>• </span> Advanced Website Metrics</div>
                            <div className='order-feature-item'><span style={{ fontSize: "20px" }}>• </span> Early access to all upcoming products</div>
                        </div>
                        <div className='order-price-summary'>
                            <div>  <div style={{ fontWeight: "700", fontSize: "18px", lineHeight: "21.78px" }}>{plan.name}</div><div>${plan.amount_cents / 100} / every month</div></div>
                            <div>${plan.amount_cents / 100}</div>
                        </div>
                        <hr style={{ color: ColorTemple.grayE5, margin: "8px 0" }}></hr>

                        <div className='order-total'>
                            <div>Total due today</div>
                            <div style={{ fontWeight: "700" }}>${plan.amount_cents / 100}</div>
                        </div>
                        <div onClick={handleSubscribe} className='payment-button' style={{ color: 'white', backgroundColor: ColorTemple.foreBlue }}>Confirm payment</div>

                        <div className='policy-text'>By purchasing, you agree to our  <a href="https://castlebird.com/terms-of-service" className="text-blue-500 underline">
                            Terms of Service
                        </a>{" "}
                            and{" "}
                            <a href="https://castlebird.com/privacy-policy" className="text-blue-500 underline">
                                Privacy Policy
                            </a>.</div>
                        <div className='policy-footer'>
                            You also agree to the auto-renewal of ${plan.amount_cents / 100} per month starting on {DateTimeHelper.getToday()}.<br></br> You can cancel anytime before then.
                        </div>
                        <div className='security-section'>
                            <div className='security-item'>
                                <div className='security-icon'><img src={SafeIcon} /></div>
                                <div className='security-text'>SAFE & SECURE<br></br>PAYMENT</div>
                            </div>
                            <div className='flex flex-row '>
                                <div className='security-icon' ><img src={RefundLogo} /></div>
                                <div className='security-text'>30 MONEY-BACK<br></br>GUARANTEE</div>
                            </div>
                        </div>
                    </div>
                </div>

            </div>

        </>
    );
};



export default LoggedinForm;

