import UtilityHelper from "./UtilityHelper";

class SectionHelper {
  static getColorClass(section: any) {
    if (!UtilityHelper.isPresent(section))
      return "";
    if (!UtilityHelper.isPresent(section.color))
      return ""

    let color = section.color;
    if (color == "medium") return "bg--secondary";
    if (color == "dark") return "bg--dark";

    return "";
  }

  static printImageBgClass(section: any) {
    if (this.hasImage(section)) {
      return "imagebg"
    }

    return "";
  }

  static printOverlay(section: any) {
    if (this.hasImage(section)) {
      return "4"
    }

    return "0";
  }

  static hasImage(section: any) {
    if (!!section.data[0]?.image_file_data && Object.keys(section.data[0].image_file_data).length !== 0) {
      if (!!section.data[0]?.image_file_data.image_s){
        return true
      }
    }

    return false;
  }
}

export default SectionHelper;
