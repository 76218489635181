import PagesService from 'api/services/PagesService';
import WebsiteService from 'api/services/SiteService';
import React, { useEffect, useState } from 'react';
import { Card } from 'primereact/card'
import { Button } from 'primereact/button';
import TrackerHelper from 'helpers/TrackerHelper';


interface Step2Props {
  blogTopic: string | null;
}

interface TypingEffectProps {
  text: string;
  speed: number;
}

const Step2: React.FC<Step2Props> = ({ blogTopic }) => {
  const [loading, setLoading] = useState<boolean>(true);
  const [blogPostUrl,setBlogPostUrl] = useState<string>('')

  useEffect(() => {
    const createBlogPost = async () => {
      const currentWebsite = await WebsiteService.CreateWebsite({name: blogTopic});
      await WebsiteService.getAiContent({ ai_action: 'generate_blog_page', domain: currentWebsite.domain });
      console.log(currentWebsite);
    
      const newPage = await PagesService.CreatePage({
        name: blogTopic,
        page_type: 'blog_post',
        website_id: currentWebsite.id,
        snap_blog: JSON.stringify({
          title: blogTopic,
          status: 'published',
          excerpt: '',
        }),
      });
      console.log(newPage);

      const blogPostUrl=  WebsiteService.getSiteDomain(currentWebsite) + '/blog/' + newPage['permaid']
      if (blogPostUrl) {
        setBlogPostUrl(blogPostUrl);
        setLoading(false);
      }
      console.log(blogPostUrl);
    };
    createBlogPost();

    TrackerHelper.virtualPage("/ai-blog-onboarding/step_final", "Blog-onboarding - Generating");
  }, [blogTopic]);

  const handleViewBlogPost = ()=> {
        window.location.href = `${blogPostUrl}`;
  }

  const TypingEffect: React.FC<TypingEffectProps> = ({ text, speed }) => {
    const [displayedText, setDisplayedText] = useState<string>('');

    useEffect(() => {
      let index = 0;
      const typingInterval = setInterval(() => {
        if (index < text.length) {
          setDisplayedText((prev) => prev + text[index]);
          index++;
        } else {
          clearInterval(typingInterval);
        }
      }, speed);
      return () => clearInterval(typingInterval);
    }, [text, speed]);

    return <p className="post-content" >{displayedText}</p>;
  };

  return (
    <>
      {loading? (
        <Card className="w-full max-w-4xl final-card-step "> 
            <div className="article_body">
              <div className="container">
                <div className="stack-container">
                  <article>
                    <div className="ghost-box">
                        <div className="article__title mb-2">
                          <h1 className="post-title h3  text-md" style={{wordWrap: 'break-word', lineHeight:"140%"}} >{blogTopic}</h1>
                        </div>
                        <div className="date-posted" style={{ filter: 'blur(4px)' }}>
                          <TypingEffect text="October 14th 2024" speed={50} />
                        </div>
                        <div className="article__body mt-4" style={{ filter: 'blur(4px)' }}>
                          <TypingEffect
                            text="Saigon, or Ho Chi Minh City, is a vibrant metropolis that blends the old with the new in an energetic symphony of culture, history, and modernity. Whether you're a local resident or a curious traveler, this guide will take you through some of the best local and global hangouts that capture the essence of Saigon's dynamic spirit. Begin your journey in the bustling streets of District 1, where hidden alleys reveal quaint coffee shops and traditional Vietnamese pho bistros. These narrow lanes offer a glimpse into the daily life of Saigonese, away from the typical tourist paths."
                            speed={50}
                          />
                          <div className="image-placeholder" style={{ filter: 'blur(2px)',height:"240px" }}> </div>
                        </div> 
                    </div>
                  </article>
                </div>
              </div>
            </div>
      </Card>
      ) : (
        <Card className="flex p-8 justify-center post-ready-card onboarding-card">
          <h3 className='title'> Your blog post is ready! </h3>
            <Button className="p-button-primary "
                    style={{ backgroundColor: '#2318DD', 
                             fontWeight: '600', 
                             color: '#fafafa', 
                             padding: '12px 40px' ,
                             margin: '0',
                             borderRadius:'4px'
                            }}
                    label='View my blog post'
                    onClick={handleViewBlogPost}
             >
          </Button>
        </Card>
      )
    }
    </>
  );
};

export default Step2;