import CB_Icon from "assets/logo.png"
import ArrowDown from "assets/icons/CaretDown.png"
import DownloadIcon from "assets/icons/download.png"
import './style.scss';
import AvatarTemp from "assets/PublicProfile/hero/avatar.png"
import { useEffect, useRef, useState } from 'react';
import BillingService from "api/services/BillingService";
import AuthService from "api/services/authService";
import ColorTemple from "styles/ColorTemple";
import CardIcon from "assets/icons/cards.png";
import KeyBoardOpen from "assets/icons/keyboard-open.png";
import { useNavigate } from "react-router-dom";
import AccountService from "api/services/AccountService";

function NarBarWelcomeFlow({
    selected_feature = '',
    plan_code = '',
}: {
    selected_feature?: string;
    plan_code?: string;
}) {
    const [isOpenMenu, setOpenMenu] = useState(false);
    const [loading, setLoading] = useState(false);
    const [isPresentWeb, setPresentWeb] = useState(false);
    const navigate = useNavigate();

    const menuRef = useRef<HTMLDivElement>(null);
    const userInfo = AuthService.GetCurrentUser();
    const handleClickOutside = (event: any) => {
        if (menuRef.current && !menuRef.current.contains(event.target as Node)) {
            setOpenMenu(false);
        }
    };

    const handleScroll = () => {
        setOpenMenu(false);
    };
    const handleOpenTab = () => {
        if (isOpenMenu) {
            setOpenMenu(false);

        } else {
            setOpenMenu(true);
        }
    }
    const handleLogout = () => {
        AuthService.SaveCurrentUser(null);
        AuthService.SaveCurrentAccount(null);
        navigate('/signin', { replace: true })

    }

    const handleSignin = () => {
        if (selected_feature) {
            navigate('/login', { state: { plan_code: plan_code, feature_selected: selected_feature } });

        } else {
            navigate('/signin',)
        }


    }

    const toggleMenu = () => setOpenMenu(!isOpenMenu)

    useEffect(() => {
        setLoading(true)
        // BillingService.GetSubscription().then(
        //     (sub) => {
        //         setSubscription(sub['data']);
        //         setLoading(false)
        //     }
        // );
        document.addEventListener("mousedown", handleClickOutside);
        window.addEventListener("scroll", handleScroll);
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
            window.removeEventListener("scroll", handleScroll);
        };
    }, []);

    return (
        <div className='navbar-login'>
            <div ><img className="navbar-login-logo" src={CB_Icon} /></div>

            {userInfo ?
                <div onClick={handleOpenTab} className="flex flex-row items-center hover:bg-gray-200 px-2 py-1 rounded-md cursor-pointer"> <div><img src={userInfo.avatar_url ?? AvatarTemp} style={{ height: "32px", width: "32px", borderRadius: "50%", }} /></div>
                    <div style={{ marginLeft: "8px", fontWeight: "500", fontSize: "14px" }} >
                        {userInfo.first_name ?? ""} {userInfo.last_name ?? ""}
                    </div>
                    {isOpenMenu && (
                        <div ref={menuRef} className="dropdown-menu">
                            <div className="dropdown-header">
                                <div className="dropdown-header-info">
                                    <h4 className="text-base font-bold ">{userInfo.first_name ?? ""} {userInfo.last_name ?? ""}</h4>
                                    <p className="text-sm font-medium" style={{ color: ColorTemple.gray61 }}>{userInfo.email ?? ""}</p>
                                </div>
                                <div> <img src={userInfo.avatar_url ?? AvatarTemp} className="dropdown-header-avatar" /></div>

                            </div>
                            <hr className="m-0"></hr>
                            <div className="p-2 text-left">
                                <div
                                    className="block hover:bg-gray-100 rounded-lg"
                                    style={{ padding: "10px 20px " }}
                                    onClick={handleLogout}
                                >
                                    Logout
                                </div>
                            </div>
                        </div>
                    )}
                </div>


                : <div onClick={handleSignin} style={{ cursor: "pointer", backgroundColor: ColorTemple.foreBlue, padding: "10px 20px", color: 'white', borderRadius: "8px", fontSize: "16px", fontWeight: "700", userSelect: "none" }}>Sign in</div>
            }

        </div >
    );
}



export default NarBarWelcomeFlow;
