import React, { useEffect, useState } from 'react';
import Logo from '../../assets/logo.webp';
import { Button } from 'primereact/button';
import { useNavigate, useParams } from 'react-router-dom';
import NotifyPreferencesService from 'api/services/NotiReferencesService';

const Successful = () => {
  const navigate = useNavigate();
  const { user_key } = useParams();
  const [isUnsubscribed, setIsUnsubscribed] = useState(null);
  const [loading, setLoading] = useState(true); // Trạng thái loading

  const lastCall = () => {
    navigate(-1);
  };

  const fetchPreferences = async () => {
    try {
      const result = await NotifyPreferencesService.Get(user_key);
      const checkUnsubscribed = result.every(item => item.enabled === false);
      setIsUnsubscribed(checkUnsubscribed);
    } catch (error) {
      console.log('Error fetching data:', error);
    } finally {
      setLoading(false); // Dừng trạng thái loading sau khi hoàn tất
    }
  };

  useEffect(() => {
    fetchPreferences();
  }, []);

  // Không render gì cho đến khi tải dữ liệu xong
  if (loading) {
    return <div>Loading...</div>;
  }

  return (
    <>
      {isUnsubscribed && (
        <div className="flex items-center justify-center min-h-screen" style={{ background: 'rgba(34, 117, 215, 0.05)' }}>
          <div className="logo md:m-6 ">
            <a href="/">
              <img src={Logo} alt="CastleBird Logo" className="mx-auto mb-4" />
            </a>
          </div>
          <div className="bg-white rounded-lg p-8 max-w-2xl text-center" style={{ padding: '64px 80px' }}>
            <div className="bg-green-100 rounded-full p-4 mb-4 inline-block">
              <svg xmlns="http://www.w3.org/2000/svg" className="h-16 w-16" fill="none" viewBox="0 0 24 24" stroke="green">
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M5 13l4 4L19 7" />
              </svg>
            </div>

            <h1 className="text-3xl font-bold mb-2 text-gray-800">Unsubscription successful!</h1>

            <p className="text-gray-600 mb-6 text-lg">You will no longer receive any emails from Castlebird.</p>
            <hr />
            <div className="cta gap-3 flex flex-col" style={{  }}>
              <h2 className="text-2xl font-bold mb-2 text-gray-800">Change your mind?</h2>
              <Button
                label="Click here"
                className="p-button-primary mt-0 "
                onClick={lastCall}
                style={{ padding: '12px 24px', backgroundColor: '#2275D7', borderRadius: '4px', fontWeight: 'bold' }}
              />
            </div>
          </div>
        </div>
      )}

      {!isUnsubscribed && (
        <div className="flex items-center justify-center min-h-screen" style={{ background: 'rgba(34, 117, 215, 0.05)' }}>
          <div className="logo md:m-6 ">
            <a href="/">
              <img src={Logo} alt="CastleBird Logo" className="mx-auto mb-4" />
            </a>
          </div>
          <div className="bg-white rounded-lg p-8 max-w-2xl text-center" style={{ padding: '64px 80px' }}>
            <div className="bg-green-100 rounded-full p-4 mb-4 inline-block">
              <svg xmlns="http://www.w3.org/2000/svg" className="h-16 w-16" fill="none" viewBox="0 0 24 24" stroke="green">
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M5 13l4 4L19 7" />
              </svg>
            </div>

            <h1 className="text-3xl font-bold mb-2 text-gray-800">Preferences Updated!</h1>

            <p className="text-gray-600 mb-6 text-lg">
              Thank you for updating your preferences. You will continue to receive notifications based on the choices you've made.
            </p>
          </div>
        </div>
      )}
    </>
  );
};

export default Successful;
