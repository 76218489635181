import React, { useEffect, useRef, useState } from 'react';
import { InputText } from 'primereact/inputtext';
import { Button } from 'primereact/button';
import { ProgressBar } from 'primereact/progressbar';
import { IconField } from 'primereact/iconfield';
import { InputIcon } from 'primereact/inputicon';
import WebsiteService from 'api/services/SiteService';
import TrackerHelper from 'helpers/TrackerHelper';
import PagesService from 'api/services/PagesService';
import AuthService from 'api/services/authService';

interface Step1Props {
  selectedBusinessTag: string | null;
  setSelectedBusinessTag: (tag: string | null) => void;
}

const Step1: React.FC<Step1Props> = ({ selectedBusinessTag, setSelectedBusinessTag}) => {
  const [tags, setTags] = useState<string[]>([]);
  const [search, setSearch] = useState<string | null>(selectedBusinessTag || null);
  const [showTagContainer, setShowTagContainer] = useState<boolean>(false);
  const tagContainerRef = useRef<HTMLDivElement>(null);
  const [blurOutside, setBlurOutside] = useState<boolean>(false)

  useEffect(() => {
    const fetchTags = async () => {
      const result = await WebsiteService.getAiContent({
        ai_action: 'business_tags',
        filter: '' || search,
      });
      setTags(result.tags);
    };

    fetchTags();

    TrackerHelper.virtualPage("/onboarding/step_business_type", "Onboarding - Business Type");
  }, []);

  const handleSearchChange = async (event: React.ChangeEvent<HTMLInputElement>) => {
    setShowTagContainer(true);
    const userKeyword = event.target.value;
    setSearch(userKeyword);
    if (userKeyword.trim()) {
      setSelectedBusinessTag(userKeyword);
    } else {
      setSelectedBusinessTag(null);
    }

    if (userKeyword.trim()) {
      const result = await WebsiteService.getAiContent({ ai_action: 'business_tags', filter: userKeyword });
      console.log(result.tags);
      const totalResults = [userKeyword, ...result.tags]
      setTags(totalResults);
    }
  };

  const handleTagClick = (tag: string) => {
    setSelectedBusinessTag(tag);
    setSearch(tag);
    setShowTagContainer(false);
    console.log(search)
  };

  const handleClickOutside = (event: MouseEvent) => {
    if (
      tagContainerRef.current &&
      !tagContainerRef.current.contains(event.target as Node) &&
      !document.activeElement?.matches('.input-text-full') // Check if click is within input field
    ) {
      setShowTagContainer(false);
      document.removeEventListener('click', handleClickOutside); // Clean up event listener
    }
  };

  useEffect(() => {
    // Add event listener on component mount
    document.addEventListener('click', handleClickOutside);

    // Remove event listener on component unmount to prevent memory leaks
    return () => document.removeEventListener('click', handleClickOutside);
  }, [blurOutside]);

  console.log(tags)
  console.log(selectedBusinessTag);


  return (
    <div className="industry">
      {/* <ProgressBar value={40} style={{ height: '6px' }} className="mb-4" /> */}
      <div className="header">
        <h3 className='title' style={{marginBottom:'18px'}}>Tell us about your business</h3>
        <h4 style={{marginBottom:'8px',fontWeight:'500'}}>My business website is about</h4>
      </div>

      <IconField iconPosition="left" className="full-width-input mb-3 ">
        {/* <InputIcon className="pi pi-search pl-3" /> */}
        <InputText
          placeholder="e.g Real estate, dental clinic, healthcare,..."
          value={search}
          onChange={handleSearchChange}
          onFocus={() => setShowTagContainer(true)}
          onBlur={() => setBlurOutside(!blurOutside)}
          className="input-text-full"
          style={{ color: 'black', fontWeight: '600' }}
        />
      </IconField>

      {showTagContainer && ( // Kiểm tra điều kiện để hiển thị tag-container
        <div className="tag-container" ref={tagContainerRef} >
          {tags.map((tag, index) => (
            <Button
              key={index}
              label={tag}
              className='p-button-text'
              onClick={() => handleTagClick(tag)}
            />
          ))}
        </div>
      )}

    </div>
  );
};

export default Step1;