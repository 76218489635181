import { useContext, useEffect, useRef, useState } from "react";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import { Swiper as SwiperType } from "swiper";
import AuthService from "api/services/authService";
import LoadingSuspense from "components/Suspense";
import PagesService from "api/services/PagesService";
import SectionsService from "api/services/SectionsService";
import { StateContext } from "store/Provider";
import UtilityHelper from "helpers/UtilityHelper";
import DateTimeHelper from "helpers/DateTimeHelper";
import SectionHelper from "helpers/SectionHelper";
import ApiConfig from "api/apiConfig";
import "./styles/style.scss";
import TrackerHelper from "helpers/TrackerHelper";

function BlogPost() {
  const { state } = useContext(StateContext);
  const params = useParams();
  const navigate = useNavigate();

  const swiperRef = useRef<SwiperType>(null);
  const footerRef = useRef<HTMLDivElement>(null);
  const contactRef = useRef<HTMLDivElement>(null);
  const [site, setSite] = useState<Record<string, any> | null>(null);
  const [loading, setLoading] = useState(false);
  const [pages, setPages] = useState<Array<any>>([]);
  const [currentPage, setCurrentPage] = useState<any>({
    name: "",
    updated_at: null,
  });
  const [sections, setSections] = useState<Array<any>>([]);
  const [isHovered, setIsHovered] = useState(""); // Hover state
  const [shouldShowOverlay, setShouldShowOverlay] = useState<boolean>(false);


  interface BreadcrumbProps {
    paths: { name: string; url: string }[];
  }

  const Breadcrumb: React.FC<BreadcrumbProps> = ({ paths }) => {
    return (
      <div className='container'>
        <nav className="breadcrumb">
          {paths.map((path, index) => (
            <span key={index} className="breadcrumb__item">
              {index < paths.length - 1 ? (
                <Link to={path.url}>{path.name}</Link>
              ) : (
                <span>{path.name}</span>
              )}
            </span>
          ))}
        </nav>
      </div>
    );
  };

  const breadcrumbPaths = [
    { name: "Blog", url: ApiConfig.BLOG_URL },
    { name: currentPage.name || "Loading...", url: "#" },
  ];

  const handleEditButtonClick = () => {
    const currentDomain = window.location.hostname;
    const redirectUrl = `https://${ApiConfig.MAIN_DOMAIN}/signin?type=blog?domain=${currentDomain}#login_web`;
    window.location.href = redirectUrl;
  };

  const getSections = async (page_id: string, permaid: string) => {
    setLoading(true);
    const data = await SectionsService.GetSections({
      page_id: page_id,
      permaid: permaid,
    });
    setSections(data);
    setLoading(false);
  };

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: "auto" });
    setLoading(true);
    UtilityHelper.initApp(state).then((val) => {
      setSite(val.website)

      if (params.slug && params.slug !== "null") {
        PagesService.GetPages({ website_id: val.website.id, permaid: params.slug }).then((val) => {
          console.log(val)
          if (!val.length || val[0]?.snap_blog_status === "draft") {
            navigate(ApiConfig.BLOG_URL);
          } else {
            setCurrentPage(val[0]);
            getSections(val[0].id, val[0].permaid);
          }
          setLoading(false);
        });
      } else {
        navigate("404");
      }
    });
    
    
  }, []);

  useEffect(() => {
    if (site?.is_temporary === true || site?.is_temporary === 'true') {
      TrackerHelper.virtualPage("/ai-blog-onboarding/preview_blog_post", "Blog-onboarding - Preview Blog Post");
    } else {
      TrackerHelper.virtualPage("/preview_blog_post", "User on app - Preview Blog Post");
    }
  },[site]);

  return (
    <>
      {(currentPage?.snap_blog?.status === "published" || (site?.is_temporary === true || site?.is_temporary === 'true')) && <Breadcrumb paths={breadcrumbPaths} />}
      <div className={`article__body`}>
        {loading ? (
          <LoadingSuspense style={{ flexGrow: 1 }} />
        ) : (
          <div className="container">
            {UtilityHelper.isPresent(currentPage.snap_blog?.feature_image) ? (
              <div className="">
                <div className="image-container mb-3">
                  <img
                    alt="background"
                    style={{ maxHeight: "500px", marginBottom: "0px", borderRadius:'4px' }}
                    src={currentPage.snap_blog?.feature_image}
                  />

                </div>
                <article>
                  <div className="ghost-box">
                    <div className="article__title text-left">
                      <h1 className="h1 mb-4">{currentPage.name}</h1>
                    </div>
                    <div className="date-posted">
                      <span>
                        {currentPage.updated_at ? DateTimeHelper.formatDateString(currentPage.updated_at) : "Updating..."}
                      </span>
                    </div>
                    <div className="article__body">
                      {sections.map((section, index) => (
                        <div key={index} className="section-container">
                          {!!section.data[0].title && (
                            <h2>{section.data[0].title}</h2>
                          )}
                          {!!section.data[0].description && (
                            <p dangerouslySetInnerHTML={{ 
                              __html: section.data[0].description.replace(/\n/g, "<span>") 
                            }} />
                          )}
                          {SectionHelper.hasImage(section) && (
                            <img
                              // style={{ marginBottom: "24px" }}
                              alt="image"
                              src={section.data[0]?.image_file_data?.image_s}
                            />
                          )}
                        </div>
                      ))}
                    </div>
                  </div>
                </article>

              </div>
            ) : (
              <div className="ghost-box">
                <article>
                  <div className="article__title text-left mt-3">
                    <h1 className="h1 mb-4">{currentPage.name}</h1>
                  </div>
                  <div className="date-posted">
                    <span>
                      {currentPage.updated_at ? DateTimeHelper.formatDateString(currentPage.updated_at) : "Updating..."}
                    </span>
                  </div>
                  <div className="article__body">
                    {sections.map((section, index) => (
                      <div key={index} className="section-container">
                        {!!section.data[0].title && <h2>{section.data[0].title}</h2>}
                        {!!section.data[0].description && (
                            <p dangerouslySetInnerHTML={{ 
                              __html: section.data[0].description.replace(/\n/g, "<span>") 
                            }} />
                        )}
                        {SectionHelper.hasImage(section) && (
                          <img alt="image" src={section.data[0]?.image_file_data?.image_s} />
                        )}
                      </div>
                    ))}
                  </div>
                </article>
              </div>
            )}
          </div>
        )}
      </div>
    </>
  );
}

export default BlogPost;
