import React, { useEffect } from 'react';
import './styles/download.css';
import { googleLogout } from '@react-oauth/google';
import AuthService from 'api/services/authService';
import { useLocation, useNavigate } from 'react-router-dom';
import NavbarWelcomeFlow from '../subscription/components/navbar/navbarSignIn';
import TrackerHelper from 'helpers/TrackerHelper';

const Download = () => {
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const typePage = queryParams.get('type');
  const { from = "" } = location.state || {}; 

  console.log(from);

  useEffect(() => {
    if (typePage === 'website') {
      TrackerHelper.virtualPage("/download/type_website", "Download - Website Onboading");
    }

    if (typePage === 'blog') {
      TrackerHelper.virtualPage("/download/type_blog", "Download - Blog Onboarding");
    }
    
    if (from === 'subscription') {
      TrackerHelper.virtualPage("/download/type_upgrade_plan", "Download - After Upgrade Plan");
    }
    
  },[typePage, from])

  return (
    <>
      {from === 'subscription' && <NavbarWelcomeFlow />}   

      <div className="min-h-screen flex flex-col md:flex-row">
        <div className="flex-1 bg-white flex items-center justify-center p-6">
          <div className="max-w-lg flex flex-col items-center">
            {from !== 'subscription' && (
              <a href="https://castlebird.com">
                <img 
                  src={require('assets/logo.webp')} 
                  className="pt-2 ml-3 logo-img" 
                  alt="CastleBird Logo" 
                />
              </a>
            )}

            {typePage === "website" && (
              <>
                <h1 className="text-4xl font-semibold mb-3 text-black text-center">
                  Download Castlebird app to customize your website
                </h1>
                <p className="subtitle text-xl text-center mt-0 pb-4">
                  Make sure to login to our app using <b>{AuthService.GetCurrentUser()?.email || "your account"}</b> to edit, preview, and personalize the newly generated website to suit your needs and preferences.
                </p>
              </>
            )}

            {typePage === "blog" && (
              <>
                <h1 className="text-4xl font-semibold mb-3 text-black text-center">
                  Download Castlebird app to edit & publish your blog post
                </h1>
                <p className="subtitle text-xl text-center mt-0 pb-4">
                  Make sure to login to our app using <b>{AuthService.GetCurrentUser()?.email || "your account"}</b> to edit, preview, and publish the newly generated blog post to suit your needs and preferences.
                </p>
              </>
            )}

            {from === "subscription" && (
              <>
                <h1 className="text-4xl font-semibold mb-3 text-black text-center">
                  Take Your Business Anywhere – Download the CastleBird App!
                </h1>
                <p className="subtitle text-xl text-center mt-0 pb-4">
                  Now that you're a CastleBird starter member, download our app to access premium features right from your mobile device. It's the perfect way to stay connected to your business, no matter where you are.
                </p>
              </>
            )}

            <div className="download-btn flex gap-4" style={{ padding: '10px 30px !important' }}>
              <a href="https://play.google.com/store/apps/details?id=com.castlebird.castleapp" className="googleplay-btn">
                <img src={require('assets/googleplay 1.png')} alt="Download on Google Play" />
              </a>
              <a href="https://apps.apple.com/us/app/castlebird/id6449547159" className="appstore-btn">
                <img src={require('assets/appstore 1.png')} alt="Download on App Store" />
              </a>
            </div>
          </div>
        </div>

        <div className="flex-1 flex items-center justify-center p-6" style={{ background: 'linear-gradient(30.47deg, rgba(0, 117, 211, 0.5) 18.52%, #0075D3 81.03%)'}}>
          {typePage === 'website' && (
            <img
              src={require('assets/Sign Up graphic.png')}
              alt="Website Preview"
              className="max-w-full h-auto"
            />
          )}

          {typePage === 'blog' && (
            <img
              src={require('assets/Blog sign up graphic.png')}
              alt="Blog Preview"
              className="max-w-full h-auto"
            />
          )}

          {from === 'subscription' && (
            <img
              src={require('assets/Castlebird premium.png')} // Fix hiển thị hình ảnh đúng
              alt="Castlebird App Preview"
              className=" h-auto"
              style={{width:'280px'}}
            />
          )}
        </div>
      </div>
    </>
  );
};

export default Download;
