import FullScreenImage from "components/FullScreenImage";
import { useState } from "react";
import { randomHSLA } from "../../../FAQ";
import { timeSince } from "pages/Chat/components/MessContact";
import { Link, useLocation } from "react-router-dom";

function UpdateCardDefault({
  post,
  site,
}: {
  post: any;
  site: any;
}) {
  const [seeMore, setSeeMore] = useState<boolean>(false);
  const location = useLocation();

  if (!!post?.content || !!post?.picture_data) {
    return (
      <article
        className="update-card flex-vertical"
        style={{ alignItems: "flex-start" }}
      >
        <Link
          to={`/post/${post.id}`}
          state={{ prevRoute: location.pathname }}
          style={{ width: "100%" }}
        >
          <div className="update-image">
            {Array.isArray(post?.picture_data) ? (
              <img src={post?.picture_data[0]?.image_s} />
            ) : (
              <div
                style={{
                  backgroundColor: "#C4C4C4",
                  width: "100%",
                  height: "100%",
                }}
              ></div>
            )}
          </div>
          <div
            className="flex-horizontal"
            style={{
              gap: 12,
              alignItems: "center",
              marginBottom: 12,
            }}
          >
            <div className="divider"></div>
            <p className="time">{timeSince(new Date(post.created_at))}</p>
          </div>
          {typeof post?.title === "string" ? (
            <div
              onClick={() => setSeeMore(!seeMore)}
              className={`update-title`}
            >
              {post.title}
            </div>
          ) : null}
          {typeof post?.content === "string" ? (
            <div
              onClick={() => setSeeMore(!seeMore)}
              className={`update-description ${typeof post?.content === "string" &&
                post?.content.length > 180 &&
                "des-overflow"
                } ${seeMore ? "des-all" : ""}`}
            >
              {post.content}
            </div>
          ) : null}
          {typeof post?.content === "string" && post?.content.length > 180 ? (
            <button onClick={() => setSeeMore(!seeMore)}>
              {seeMore ? "See less" : "See more"}
            </button>
          ) : null}
        </Link>
      </article>
    );
  } else {
    return <></>;
  }
}

export default UpdateCardDefault;
